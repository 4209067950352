* {
  box-sizing: border-box;
}

.Homescreenwrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100vh;
  width: 60%;
  margin-top: 160px;
  padding-bottom:  30px;
  padding-left: 30px;
  padding-right: 30px;
}

.Homescreenactivelist {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  min-height: 130px;
  font-size: 1.4rem;
  background: rgba(0, 0, 0, 0) linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114)) repeat scroll 0% 0%;

  color:#ffff;
  padding: 34px;

  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.02),
  0 18px 36px -18px rgba(0, 0, 0, 0.3),
  0 -12px 36px -8px rgba(0, 0, 0, 0.025);
}

.Homescreenactivelist:hover .Homescreenactivelist-title {
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: 6px;
}

.Homescreenactivelist-title {
  display: flex;
  justify-content:space-between;
  align-items: center;
  width: 100%;
  word-break: break-all;
  line-height:32px;
}


.Homescreenactivelist-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}

.Homescreenactivelist-text1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}


.Homescreenactivelist-time {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  color:#404040;
  font-size: 1rem;
}

.Homescreenwrapper-title {
  padding: 10px;
  color: #484848;
}

.Homescreenactivelist-epool {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Homescreenactivelist-epool-img {
  width: 100%;
}

.Homescreenwrapper-titlewrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Homescreenwrapper-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.Homescreenwrapper-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  cursor: pointer;
  color:#404040;
}

.Homescreenwrapper-search {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  cursor: pointer;
  color:#909090;
}

.hometopicsearch-inp {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
  font-size: 27px;
}

.Homescreenwrapper-inptarrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.hometopicsearch-inp:focus {
  border: none;
  outline: none;
}


@media (max-width: 780px) {

  .Homescreenwrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    padding-bottom:  30px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top:120px;
  }

  .Homescreenactivelist {
    font-size: 1rem;
  }

  .Homescreenactivelist-text1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width:100%;
  }

 .Homescreenactivelist-title {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    align-items: center;
    width: 100%;
    word-break: break-all;
    line-height:32px;
    font-size:1.2rem;
  }


}
