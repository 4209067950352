.Accountpage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Accountpage-poolcontainer {

  display: flex;
  justify-content: center;
  width: 100%;
  top:80px;
  background-color: #fff;
  z-index: -10;
}
