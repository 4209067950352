.wrapper-loading {
  position: fixed;
  top:0px;
  display: flex;
  justify-content: center;
  align-items:center;
  height: 100vh;
  width: 100vw;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}

.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 1.8em;
  height: 1.8em;
  margin: .19em;
  background: #007DB6;
  border-radius: .2em;
  animation: loading 1s infinite alternate;
}

/*
 * Dots Colors
 * Smarter targeting vs nth-of-type?
 */
.loading span:nth-of-type(2) {
  background: #008FB2;
  animation-delay: 0.2s;
}
.loading span:nth-of-type(3) {
  background: #009B9E;
  animation-delay: 0.4s;
}
.loading span:nth-of-type(4) {
  background: #00A77D;
  animation-delay: 0.6s;
}
.loading span:nth-of-type(5) {
  background: #00B247;
  animation-delay: 0.8s;
}
.loading span:nth-of-type(6) {
  background: #5AB027;
  animation-delay: 1.0s;
}
.loading span:nth-of-type(7) {
  background: #A0B61E;
  animation-delay: 1.2s;
}

/*
 * Animation keyframes
 * Use transition opacity instead of keyframes?
 */
@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
