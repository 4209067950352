.changepasswordwrapper {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #fff;
}

.changepassword-container {
  width: 100%;
}

.changepassword-container {
  width: 100%;
}

.email-currentemail-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.email-currentemail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.secruitycode {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  word-break:break-all;
  padding: 10px;
  border-radius: 10px;
  background-color: #d1d1d1;
}



.changepassword-error {
  width: 100%;
  padding: 10px;
  height:35px;
  color:red;
}

.changepassword-spinner {
  position: absolute;
  display: flex;
  justify-content:center;
  align-items: center;
  color:black;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
}
