

.requestbox-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 10px;
  background-color: #fff;
}

.requestbox-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 24px;
  padding: 10px;
  width: 400px;
  border: 1px solid lightgray;
  cursor: pointer;
}

.requestbox-container:focus {
  border: 1px solid #BFBFFF;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.requestbox-profilepanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.requestbox-buttonpanel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-top: 1px solid lightgray;
}

.requestbox-profileimg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  padding: 4px;
  color:darkgrey;
  font-size: 1.2rem;
  font-weight:bold;
}

.requestbox-name {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  padding: 8px;
  word-break: break-all;
}

.requestbox-topictitle {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#6276ff;
  font-size: 1rem;
  padding: 4px;
  cursor: pointer;
  text-decoration-color:#fff;
}

.requestbox-topictitle:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-color:#404040;
  text-decoration-style:dashed;
}

.requestboxdelbtn {
  color:#fe4645;
  background-color: #fff;
  border-radius: 12px;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left:18px;
  padding-right:18px;
  border: 1px solid lightgray;
}

.requestboxdelbtn:hover {
  background-color:#fafafa;
}

.requestboxconbtn {
 color:#6276ff;
 background-color: #fff;
 border-radius: 12px;
 border: none;
 font-size: 0.9rem;
 cursor: pointer;
 padding-top: 8px;
 padding-bottom: 8px;
 padding-left:18px;
 padding-right:18px;
 border: 1px solid lightgray;
}

.requestboxconbtn:hover {
  background-color:#fafafa;
}

.requestnotifymsg {
   display: flex;
   justify-content: center;
   align-items: center;
}
