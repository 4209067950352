* {
  box-sizing: border-box;
}


.acimg {
  max-width: 100%;
  max-height: 100%;
}

.Homepagemaincontainer-wrapper-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Homepagemaincontainer-header-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 14px;
  border-bottom: 1px solid #f2f2f2;
}

.Homepagemaincontainer-header-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Homepagemaincontainer-header-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top:0px;
}

.Homepagemaincontainer-team {
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.circlewrapper {
  display: flex;
}


.Homepagemaincontainer-inp {
  width: 100%;
  border: none;
  padding: 6px;
}

.Homepagemaincontainer-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
  width: 100%;
  padding: 10px;
  height: 40px;
  background-color:#f2f2f2;
  background-color: #fff;
  color:#404040;
  border-bottom: 1px solid #f2f2f2;
  z-index: 99;
}

.Homepagemaincontainer-headerdd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  background-color:#f2f2f2;
  background-color: #fff;
  color:#909090;
  font-size: 1.6rem;
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;
  z-index: 99;
}

.Homepagemaincontainer-status-online {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  position: absolute;
  top:74px;
  margin-left:-10px;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  color:#fff;
  background-color: greenyellow;
  border: 2px solid #1fd655;
  color:blue;
}

.Homepagemaincontainer-status-offline {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  position: absolute;
  top:74px;
  margin-left:-10px;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  color:#fff;
  background-color: whitesmoke;
  border: 2px solid #a1a3a6;
}


.Active-header-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left:20px;
  color:black;
  width:auto;
  font-size: 1.6rem;
  text-transform: capitalize;
  width: 100%;
}

.Homepagemaincontainer-header-topic {
  font-size: 1.2rem;

  font-family: Arial, Helvetica, sans-serif;
            background: linear-gradient(to right, #f32170,
                    #ff6b08, #cf23cf);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
}

.Homepagemaincontainer-header-hash {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.6rem;
  color:#808080;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
  margin-left: 12px;
}

.Homepagemaincontainer-header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.8rem;
  width: 1.8rem;
  font-size: 0.9rem;
  border-radius: 50%;
  color:#fff;
  padding: 16px;
  background-color: #fa523e;
}

.acm {
  display: flex;
}

.Postbox-wrapper {
  display: flex;
  position: fixed;
  top:70px;
  left: 20vw;
  width: 50vw;
  height:100px;
  background-color: #fff;
}

.Homepagemaincontainer-postbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  border: 2px solid #f2f4f7;
  border-radius: 0.75rem;
  background-color: #ffffff;
}



.Homepagemaincontainer-postform {
  display: block;
  padding: .40rem;
  word-wrap: break-word;
  cursor: pointer;
  max-width: 39vw;
  max-height: 180px;
  border: none;
  outline: none;
  overflow: auto;
  min-height: 40px;
}

.Homepagemaincontainer-postform-drag {
  display: block;
  padding: .40rem;
  word-wrap: break-word;
  cursor: pointer;
  max-width: 39vw;
  max-height: 180px;
  border: none;
  outline: none;
  overflow: auto;
  min-height: 40px;
  border-radius: 10px;
  border: 1px solid darkgray;
  border-style: dashed;
}

.Homepagemaincontainer-postform  img {
    max-width: 20%;
    max-height: 20%;
}

.Homepagemaincontainer-postform:empty:before {
  content: attr(data-placeholder);
  color:darkgray;
}



.Homepagemaincontainer-button-panel {
  display:flex;
  justify-content:space-between;
  align-items: center;
  width:100%;
  padding: 2px;
}

.Homepagemaincontainer-emoji-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
}

.Homepagemaincontainer-emoji-button:hover {
  color:cornflowerblue;
}

.Homepagemaincontainer-emojibtn {
  border: none;
  cursor: pointer;
  color:darkgrey;
  background-color: #ffffff;
  font-size: 1.4rem;
}



.Homepagemaincontainer-imgbtn {
  border: none;
  cursor: pointer;
  color:darkgrey;
  background-color: #ffffff;
  font-size: 1.4rem;
}

.Homepagemaincontainer-post-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Homepagemaincontainer-btnpost {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  color:#60acfd;
}

.Homepagemaincontainer-postlist {
  display: flex;
  margin-top: 30px;
  padding: 20px;
  justify-content:flex-start;
}

.Homepagemaincontainer-post {
  display: inline-flex;
  background-color: #f4f7fa;
  border: 1px solid #efeff1;
  width: auto;
  border-radius: 10px;
  padding: 14px;
}

.Homepagemaincontainer-accprofile {
  height: 2rem;
  width: 2rem;
  background-color: #f2f4f7;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0 0 6px #333;
}

.Homepagemaincontainer-circle {
  display: flex;
  border-radius: 50px;
  width: 34px;
  height: 34px;
}

@media (max-width: 780px) {

  .Homepagemaincontainer-headerdd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    background-color:#f2f2f2;
    background-color: #fff;
    color:#909090;
    font-size: 1.6rem;
    border-bottom: 1px solid #f2f2f2;
    cursor: pointer;
  }

  .Homepagemaincontainer-header-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 90vw;
    padding: 0px;
  }

  .Postbox-wrapper {
    display: block;
    position: fixed;
    top:98px;
    left: 20px;
    width: 600px;
    height:122px;
    background-color: #fff;
    z-index: 2;
  }

  .Homepagemaincontainer-postbox {
    display: flex;
    width: 80%;
    height: auto;

   -ms-transform: translate(-50%, -50%);
   -moz-transform: translate(-50%, -50%);
   -webkit-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);


    border: 2px solid #f2f4f7;
    border-radius: 0.75rem;
    background-color: #ffffff;
  }

  .Homepagemaincontainer-postform {
    display: block;
    padding: .40rem;
    word-wrap: break-word;
    cursor: pointer;
    max-width: 63vw;
    max-height: 180px;
    border: none;
    outline: none;
    overflow: auto;
  }

  .Homepagemaincontainer-postlist {
    margin-top:100px;
  }

  .Homepagemaincontainer-wrapper-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .Homepagemaincontainer-header-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.7rem;
    width: 0.7rem;
    font-size: 0.9rem;
    border-radius: 50%;
    color:#fff;
    padding: 10px;
    background-color: #fa523e;
  }

  .Homepagemaincontainer-team {
    display: flex;
    justify-content:center;
    align-items: center;
    width: 90vw;
    padding:0px;
  }

  .Homepagemaincontainer-status-online {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    position: absolute;
    margin-left:-10px;
    height: 12px;
    width: 12px;
    border-radius: 50px;
    color:#fff;
    background-color: greenyellow;
    border: 2px solid #1fd655;
  }

  .Homepagemaincontainer-status-offline {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    position: absolute;
    margin-left:-10px;
    height: 12px;
    width: 12px;
    border-radius: 50px;
    color:#fff;
    background-color: whitesmoke;
    border: 2px solid #a1a3a6;
  }

  .acimgicon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1rem;
  }

  .acimgcenter {
    max-height:100%;
  }




}

@media (max-width: 430px) {

  .acimgicon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 0.9rem;
  }

}

@media (max-width: 430px) {

  .acimgicon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 0.9rem;
  }

  .acimgcenter {
    max-height:80%;
  }

}

@media (max-width: 374px) {

  .acimgicon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 0.8rem;
  }

  .acimgcenter {
    max-height:70%;
  }

}

@media (max-width: 332px) {

  .acimgicon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 0.7rem;
  }

  .acimgcenter {
    max-height:60%;
  }

}
