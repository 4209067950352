* {
  box-sizing: border-box;
}

.arrow {
  max-width: 35px;
}

.Howitwork-wrapper {
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Howitwork-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
}

.Howitwork-wrapper-text {
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-size: 20px;
  padding: 8px;
}

.Howitwork-navbar-logo {
  display: flex;
  align-items: center;
  justify-content:center;
  cursor: pointer;
  padding: 4px;
  font-size: 1.6rem;
  color:#495057;
  text-decoration: none;
}

.Howitwork-navbar-wrapper {
  display: flex;
  justify-content:space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.Howitwork-logo-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  letter-spacing: 2px;
}

.Howitwork-logoline {
  display: flex;
  justify-content: center;
  align-items: center;
}


.Howitwork-wrapper-b1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #99B8FF;
  background-color: #d90429;
  color:#ffff;
  padding:6px;
  width:200px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.Howitwork-wrapper-b2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #A2BEFF;
  background-color: #ef233c;
  color:#ffff;
  padding:6px;
  width:200px;
}

.Howitwork-wrapper-b3 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #AAC4FF;
  background-color: #ee6c4d;
  color:#ffff;
  padding:6px;
  width:200px;
}

.Howitwork-wrapper-b4 {
  display: flex;
  justify-content: center;
  align-items: center;;
  background-color: #B2C9FF;
  background-color: #f9844a;
  color:#ffff;
  padding:6px;
  width:200px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.Howitwork-wrapper-bb1 {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#404040;
  background-color:#76c893;
  padding:6px;
  width:200px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.Howitwork-wrapper-bb2 {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#404040;
  background-color:#99d98c;
  padding:6px;
  width:200px;
}

.Howitwork-wrapper-bb3 {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#404040;
  background-color:#b5e48c;
  padding:6px;
  width:200px;
}

.Howitwork-wrapper-bb4 {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#404040;
  background-color:#d9ed92;
  padding:6px;
  width:200px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}


.Howitwork-wrapper-bbb1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #99B8FF;
  background-color: #b185db;
  color:#404040;
  padding:6px;
  width:300px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.Howitwork-wrapper-bbb2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #A2BEFF;
  background-color: #c19ee0;
  color:#404040;
  padding:6px;
  width:210px;
}

.Howitwork-wrapper-bbb3 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #AAC4FF;
  background-color: #d2b7e5;
  color:#404040;
  padding:6px;
  width:200px;
}

.Howitwork-wrapper-bbb4 {
  display: flex;
  justify-content: center;
  align-items: center;;
  background-color: #B2C9FF;
  background-color: #f9844a;
  background-color: #dac3e8;
  color:#404040;
  padding:6px;
  width:300px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}



.Howitwork-wrapper-subtext {
   display: flex;
   justify-content: center;
   align-items: center;
}

.Howitwork-wrapper-texta {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  word-wrap: break-word;
  font-size: 20px;
  padding: 8px;
  border:2px solid lightgrey;
  border-style: dashed;
  border-radius: 10px;
}

.Howitwork-letter {
  display: flex;
  justify-content: center;
  color: blueviolet;
}

.Howitwork-downarrow {
  width: 50px;
}

.Howitwork-wrapper-one {
  display: flex;
  justify-content:center;
  align-items: center;
  width: 50%;
  height: 350px;
}

.Howitwork-wrapper-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  margin-top: 20px;
  width: 100%;
}

.Howitwork-wrapper-note {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  margin-top: 20px;
  width: 100%;
}

.Howitwork-wrapper-sq-note {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  margin-top: 20px;
  width: 100%;
}


.Howitwork-wrapper-center {
  display: flex;
  justify-content:center;
  align-items: flex-start;
  flex-direction: column;
  width: 600px;
  height: 400px;
  padding: 10px;
  border: 1px solid lightgrey;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 30px;
}

.Howitwork-wrapper-squadtwo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Howitwork-wrapper-imgdesc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.imgsquad {
  width:60%;
}

.Howitwork-wrapper-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Howitwork-wrapper-bottom {
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  width: 600px;
  height: 340px;
  padding: 10px;
}

.Howitwork-wrapper-imgone {
  max-width: 100%;
  max-height: 100%;
}

.Howitwork-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-items: center;
  font-size: 1.6rem;
  padding: 10px;
  background-color: #fff;
  color:#808080;
}

.Howitwork-wrapper-squad {
  display: flex;
  justify-content:center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}


.Howitwork-wrapper-img {
  max-width: 100%;
  max-height: 100%;
}

.Howitwork-wrapper-text-title {
  padding: 4px;
}


.Howitwork-wrapper-t1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d7d7d7;
  color:#404040;
  padding:6px;
  width:200px;
  border-top-left-radius: 10px;
  border-bottom-left-radius:10px;;
}

.Howitwork-wrapper-t2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e4e4e4;
  color:#404040;
  padding:6px;
  width:200px;
}

.Howitwork-wrapper-t3 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#f2f2f2;
  color:#404040;
  padding:6px;
  width:200px;
  border-top-right-radius:10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius:0px;
}

@media (max-width: 780px) {

  .Howitwork-wrapper {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
  }

  .Howitwork-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    color:#808080;
    font-size: 4.2vw;
    padding:10px;
    background-color: #fff;
  }

  .Howitwork-wrapper-center {
    display: flex;
    justify-content:center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
    border: 1px solid lightgrey;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 30px;
  }

  .Howitwork-wrapper-sq-note {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    margin-top: 20px;
    width: 100%;
  }

  .Howitwork-wrapper-squad {
    display: flex;
    justify-content:center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px;
  }

  .Howitwork-wrapper-one {
    display: flex;
    justify-content:center;
    align-items: center;
    width: 50%;
    height: 300px;
  }

  .Howitwork-wrapper-subtext {
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     width: 100%;
  }

  .Howitwork-wrapper-b1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #99B8FF;
    background-color: #d90429;
    color:#ffff;
    padding:6px;
    width:100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius:0px;
    border-top-right-radius:10px;
  }

  .Howitwork-wrapper-b2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #A2BEFF;
    background-color: #ef233c;
    color:#ffff;
    padding:6px;
    width:100%;
  }

  .Howitwork-wrapper-b3 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #AAC4FF;
    background-color: #ee6c4d;
    color:#ffff;
    padding:6px;
    width:100%;
  }


  .Howitwork-wrapper-b4 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #B2C9FF;
    background-color: #f9844a;
    color:#ffff;
    padding:6px;
    width:100%;
    border-top-right-radius:0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .Howitwork-wrapper-bb1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color:#404040;
    background-color:#76c893;
    padding:6px;
    width:100%;
    border-bottom-left-radius:0px;
    border-top-right-radius:10px;
  }

  .Howitwork-wrapper-bb2 {
    display: flex;
    justify-content: center;
    align-items: center;
    color:#404040;
    background-color:#99d98c;
    padding:6px;
    width:100%;
  }

  .Howitwork-wrapper-bb3 {
    display: flex;
    justify-content: center;
    align-items: center;
    color:#404040;
    background-color:#b5e48c;
    padding:6px;
    width:100%;
  }

  .Howitwork-wrapper-bb4 {
    display: flex;
    justify-content: center;
    align-items: center;
    color:#404040;
    background-color:#d9ed92;
    padding:6px;
    width:100%;
    border-top-right-radius:0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }


  .Howitwork-wrapper-bbb1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #99B8FF;
    background-color: #d90429;
    background-color: #b185db;
    color:#404040;
    padding:6px;
    width:100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius:0px;
    border-top-right-radius:10px;
  }

  .Howitwork-wrapper-bbb2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #A2BEFF;
    background-color: #ef233c;
    background-color: #c19ee0;
    color:#404040;
    padding:6px;
    width:100%;
  }

  .Howitwork-wrapper-bbb3 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #AAC4FF;
    background-color: #ee6c4d;
    background-color: #d2b7e5;
    color:#404040;
    padding:6px;
    width:100%;
  }


  .Howitwork-wrapper-bbb4 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #B2C9FF;
    background-color: #f9844a;
    background-color: #dac3e8;
    color:#404040;
    padding:6px;
    width:100%;
    border-top-right-radius:0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .Howitwork-wrapper-squadtwo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }


  .Howitwork-wrapper-note {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin-top: 10px;
    font-weight: bold;
    width: 100%;
  }

  .Howitwork-wrapper-t1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d7d7d7;
    color:#404040;
    padding:6px;
    width:100%;
    border-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius:10px;
    border-bottom-left-radius:0px;
    border-bottom-right-radius:0px;
  }

  .Howitwork-wrapper-t2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e4e4e4;
    color:#404040;
    padding:6px;
    width:100%;
  }

  .Howitwork-wrapper-t3 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#f2f2f2;
    color:#404040;
    padding:6px;
    width:100%;
    border-top-right-radius:0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius:10px;
  }

}
