* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #F2F2F2;
}

::-webkit-scrollbar-thumb {
   -webkit-border-radius: 4ex;
  background: #BDBDBD;
}

::-webkit-scrollbar-thumb:hover {
  background: #909090;
}

.profiledetails-spinnerwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profiledetails-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  width: 100%;
  height: 100%;
  padding:20px;
  border-radius: 20px;
  border: 1px solid lightgray;
}

.profileusername-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.profiledetails-username {
  display: block;
  padding: 4px;
  width: 100%;
}

.profiledetails-gender {
  display: block;
  padding: 4px;
  width: 200px;
}

.profiledetails-firstname {
  display: block;
  padding: 4px;
}

.profiledetails-lastname {
  display: block;
  padding: 4px;
}

.profileinpedit {
  /* Remove First */
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;

  display: flex;
  width: 100%;
  padding: 0.6rem;
  background-color: #fff;
  font-size: 1rem;
  border-radius: 0px;
  border: 1px solid #fff;
  border-bottom:1px solid lightgrey;
  outline: none;
  cursor: default;
  box-shadow:none;
  color:#464646;
  }

.profileinpedit:focus {
   outline: none;
   border: none;
   border: 1px solid #fff;
   border-bottom:1px solid lightgrey;
 }

.profileinpsave {
  /* Remove First */
 -webkit-appearance: none;
 -moz-appearance: none;
  appearance: none;

  display: flex;
  width: 100%;
  padding: 0.6rem;
  background-color: #fff;
  font-size: 1rem;
  border-radius: 8px;
  border:1px dotted black;
  outline: none;
}

.profileinpsave:focus {
  display: flex;
  width: 100%;
  padding: 0.6rem;
  background-color: #fff;
  font-size: 1rem;
  border-radius: 8px;
  border:1px solid cornflowerblue;
  outline: none;
}

.profilelocation {

  /* Remove First */
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;

  display: flex;
  width: 100%;
  padding: 0.8rem;
  background-color: #fff;
  font-size: 1rem;
  border-radius: 0px;
  border: 1px solid #fff;
  border-bottom:1px solid lightgrey;
  outline: none;
  cursor: default;
  box-shadow:none;
  color:#464646;
  color:#454545;
}

.profilelocation:focus {
   outline: none;
   border: none;
   border: 1px solid #fff;
   border-bottom:1px solid lightgrey;
 }

.profilelocationedit {

  /* Remove First */
 -webkit-appearance: none;
 -moz-appearance: none;
  appearance: none;

  display: flex;
  width: 100%;
  padding: 0.8rem;
  background-color: #fff;
  font-size: 1rem;
  border-radius: 8px;
  border:1px dotted black;
  outline: none;
}

.profilelocationedit:focus {
  display: flex;
  width: 100%;
  padding: 0.8rem;
  background-color: #fff;
  font-size: 1rem;
  border-radius: 8px;
  border:1px solid cornflowerblue;
  outline: none;
}

.profileinpsave::placeholder {
  font-size: 0.8rem;
}

.profilename-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.profilebio-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding:0px;
}

.profile-bio {
  display: block;
  padding: 4px;
  width: 100%;
}

.profilebioeditoredit {

  /* Remove First */
 -webkit-appearance: none;
 -moz-appearance: none;
  appearance: none;
  width: 603px;

  min-height: 17.5vh;
  padding: 0.7125rem 1rem;
  background-color: #fff;
  font-size: 1rem;
  outline: none;
  resize: none;
  border: none;
  overflow: auto;
  cursor: default;
  border-radius: 0px;
  border: 1px solid #fff;
  border-bottom: 1px solid lightgrey;
  color:#464646;
  word-break: break-all;
}

.profiledetails-bottomspace {
  height:72px;
}

.profilebioeditorsave {
  /* Remove First */
 -webkit-appearance: none;
 -moz-appearance: none;
  appearance: none;

  width: 603px;
  min-height: 17.5vh;
  padding: 0.7125rem 1rem;
  background-color: #fff;
  font-size: 1rem;
  outline: none;
  resize: none;
  overflow: auto;
  border-radius: 8px;
  border: 1px dotted black;
  word-break: break-all;
  max-height: 200px;
}

.profilebioeditorsave:focus {
  width: 603px;
  min-height: 17.5vh;
  padding: 0.7125rem 1rem;
  background-color: #fff;
  font-size: 1rem;
  outline: none;
  resize: none;
  border: none;
  overflow: hidden;
  border-radius: 8px;
  border:1px solid cornflowerblue;
}

.profilegender {
  /* Remove First */
 -webkit-appearance: none;
 -moz-appearance: none;
  appearance: none;

  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  font-size: 1rem;
  border-radius: 0px;
  border: 1px solid #fff;
  border-bottom: 1px solid lightgrey;
  background-color: #fff;
  color:black;
}

.profilegenderedit {
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px dotted black;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
}

.profilegenderedit:focus {
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid cornflowerblue;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
}

.lbl-space {
  height: 4px;
}

.profiledetails-lbl {
  padding:10px;
  color:dimgrey;
}

.profile-btnwrapper {
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100%;
}

.profilebtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
  width: 100%;
}

.profilebtn-edit {
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
  font-size: 1rem;
  color:cornflowerblue;
}

.profilebtn-save {
  border:  1px solid #dddddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
  font-size: 1rem;
  color:cornflowerblue;
}

.profiledetails-space {
  height: 30px;
}

.profilebioeditoredit:empty:before {
    content: attr(placeholder);
    color:#757575;
}

.profilebioeditorsave:empty:before {
    content: attr(placeholder);
    color:#757575;
}

.profileinperr {
    opacity: 0.9;
    color:red;
    margin-left: 12px;
    padding-top: 4px;
    width: 300px;
}

.profiledetails-timezone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.8rem;
  font-size: 1rem;
  width: 100%;
  color:#464646;
  border-bottom: 1px solid lightgrey;
}

.profiledetails-timezone-edit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.8rem;
  font-size: 1rem;
  width: 100%;
  border: 1px dotted black;
  border-radius: 8px;
  background-color: #ffff;
  cursor: pointer;
}


.profiledetails-timezone-detail  {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profiledetails-sterotype {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.profiledetails-sterotype-edit {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dotted black;
  border-radius: 8px;
  background-color: #ffff;
  cursor: pointer;
}

.profiledetails-sterotype-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  top:0;
  left:0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}


@media (max-width: 780px) {

  .profiledetails-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 280px;
    width: 80vw;
    height: 100%;
    margin-top: 20px;
    border: 1px solid lightgray;
    border-radius: 20px;
  }

  .profilename-wrapper {
    display: flex;
    flex-direction: column;
  }

  .profilebioeditoredit {
    max-width:  100%;
    min-height: 150px;
    padding: 0.7125rem 1rem;
    background-color: #fff;
    font-size: 1rem;
    outline: none;
    resize: none;
    border: none;
    overflow: auto;
    cursor: default;
    border: 1px solid #fff;
    border-bottom: 1px solid lightgrey;
  }

  .profilebioeditorsave {
    max-width: 100%;
    min-height: 150px;
    padding: 0.7125rem 1rem;
    background-color: #fff;
    font-size: 1rem;
    outline: none;
    resize: none;
    overflow: hidden;
    border-radius: 8px;
    border: 1px dotted black;
  }

  .profilebioeditorsave:focus {
    width: 100%;
    min-height:150px;
    padding: 0.7125rem 1rem;
    background-color: #fff;
    font-size: 1rem;
    outline: none;
    resize: none;
    overflow: hidden;
    border-radius: 8px;
    border:1px solid cornflowerblue;
  }

}
