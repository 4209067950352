* {
  box-sizing: border-box;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    color:transparent;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
    height: auto;
    border: 1px solid red;
}

input[type="date"]::-webkit-inner-spin-button {
  background: transparent;
  color:transparent;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
  border: 1px solid red;
}

.prequest-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 60%;
}

.prequest-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2px;
  width: 65%;
}

.prequest-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #efefef;
  border-radius: 10px;
  cursor: pointer;
  width: 74%;
  height: 150px;
}

.prequest-uploadimg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #efefef;
  border-radius: 10px;
  width: 74%;
  height: 150px;
}

.prequest-uploadlbl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px dashed darkgrey;
  width: 100%;
  height: 100%;
}

.prequest-err {
  color:#e60023;
}

.prequest-errmsg {
  display: flex;
  justify-content: flex-start;
  color:#e60023;
  width: 100%;
  padding: 2px;
}

.prequest-errdate {
  display: flex;
  justify-content:center;
  color:#e60023;
  width: 100%;
  padding: 2px;
}

.prequest-uploadlblimg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  border: 1px dashed darkgrey;
  width: 100%;
  height: 100%;
}

.prequest-circle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prequest-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.prequest-upbtn {
  display: none;
  cursor: pointer;
}

.prequest-space {
  height: 10px;
}

.prequest-uploadimg {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.prequest-formcontrol {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  display: flex;
  width: 100%;
  padding: 1rem;
  border: none;
  border-bottom: 1px solid lightgray;
  background-color: #fff;
  font-size: 1rem;
  outline: none;
  resize: none;
}

.prequest-formcontrol:focus {
  border: none;
  border-bottom: 1px solid #ce1562;
  background-color: #ffffff;
}

.prequest-textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  display: flex;
  width: 100%;
  padding: 0.7125rem 1rem;
  border: none;
  border-bottom: 1px solid lightgray;
  background-color: #fff;
  height: 120px;
  font-size: 1rem;
  outline: none;
  resize: none;
}

.prequest-textarea:focus {
  border: none;
  border-bottom:1px solid #ce1562;
  background-color: #ffffff;
}


.prequest-patner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color:#484848;
  border: none;
}

.prequest-patnerlbl {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 10px;
  font-weight: 400;
  font-size: 1rem;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid lightgray;
}

.prequest-patnerlbl:hover {
  background-color: #f9f9f9;
}

.prequest-patnercnt {
  display: flex;
  align-items: center;
  padding: 4px;
}


.pcnt-select {
  background-color: #fff;
  border:1px solid lightgray;
  color: #555555;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
}

.pcnt-select:focus {
  border:1px solid #bfdeff;
  outline: none;
}


.prequest-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color:#484848;
  border: none;
}

.prequest-timetitle-zone {
  display: flex;
  align-items: center;
  padding: 4px;
  border: 1px solid lightgray;
  border-radius: 20px;
  cursor: pointer;
}

.prequest-timetitle-zone:hover {
  background-color:#f9f9f9;
}

.prequest-timeinp {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.prequest-nodays {
  display: flex;
  flex-direction: column;
  align-items:center;
  padding: 10px;
  color:#484848;
  border: none;
}

.prequest-startlbl {
  font-weight: 600;
  padding: 4px;
  color:#404040;
}

.prequest-dayslbl {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-weight: 700;
}

.prequest-timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  cursor: pointer;
  padding: 4px;
  color: #ff385c;
  border-bottom: 2px dashed #484848;
}

.prequest-img {
  max-width: 100%;
  max-height: 100%;
}

.prequest-cancelicon {
  font-size: 34px;
  cursor: pointer;
}

.prequest-title {
  display: flex;
  justify-content:center;
  align-items: center;
  padding: 10px;
  color: #484848;
  width: 100%;
  font-size: 2rem;

  background-image: linear-gradient(12deg, #72b3f0,#cf23cf);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

}

.prequest-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

@media (max-width: 780px) {

  .prequest-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2px;
    width:85%;
  }

  .prequest-wrapper {
    position: absolute;
    top:130px;
    left: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;
  }

  .prequest-title {
    display: flex;
    justify-content: center;
    width: 100%;
    color: #484848;
    align-items: center;
    font-size: 1.4rem;
    padding: 6px;
  }

  .prequest-uploadimg {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
  }

  .prequest-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #efefef;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    height: 150px;
  }

  .prequest-patnerlbl {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    font-weight: 400;
    font-size: 1rem;
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid lightgray;
  }

  .prequest-startlbl {
    font-weight: 700;
  }

  .prequest-patner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color:#484848;
    border: none;
    width: 100%;
  }

  .prequest-time {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    color:#484848;
    border: none;
    width: 100%;
  }


}
