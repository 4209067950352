* {
  box-sizing: border-box ;
  padding: 0 ;
  margin: 0 ;
}

.Requestcallwrapper {
 position: absolute;
 top: 50%;
 left: 50%;
 margin: -45px 0 0 -200px;
}

.Requestcontainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 24rem;
  height: 10rem;
  z-index:99;
  border-radius: 10px;
  font-size: 0.9rem;
  color:#fff;

  -webkit-backdrop-filter: blur(10px);
   backdrop-filter: blur(10px);
   background-color: rgba(0, 0, 0, 0.7);
}

.Requestcallheader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
  line-height: 1.6;
  color:whitesmoke;
}

.Requestcalltext {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Requestcalldialer {
  display: flex;
  justify-content:flex-end;
  align-items: center;
  width: 100%;
}

.Requestcallouter {
  margin-top: 180px;
}

.Reqemptyspace {
  width:20px;
}

.Requestcallbtn {
  width: 14%;
  cursor: pointer;
}


@media (max-width: 780px) {

  .Requestcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 18rem;
    height: 10rem;
    z-index:99;
    border-radius: 10px;
    font-size: 0.9rem;
    color:#fff;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .Requestcallwrapper {
   position: absolute;
   top: 50%;
   left: 50%;
   margin: -45px 0 0 -135px;
  }

  .Requestcallwrapperaa {
   display: flex;
   flex-direction: column;
   justify-content:flex-start;
   align-items: center;
   position: fixed;
   top:-40px;
   left:0;
   width: 100vw;
   height: 100%;
  }

  .Requestcallbtn {
    width: 14%;
    cursor: pointer;
  }

}
