.accountwrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.accountheader {
  display: flex;
  width: 100%;
  height: 40px;
}

.account-profile {
  height: 8rem;
  width: 8rem;
  background-color: #f2f4f7;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.account-defaultname {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  word-break: break-all;
}

.account-name {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.account-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
}

.account-btnwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  padding: 10px;
  border-radius: 50px;
  outline: none;
  border:none;
  background-color: tomato;
  color:#fff;
  cursor: pointer;
}

.account-follow {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  padding: 10px;
  border:none;
  border-radius: 50px;
  outline: none;
  background-color: tomato;
  color:#fff;
  cursor: pointer;
}

.account-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  padding: 10px;
  border:none;
  border-radius: 50px;
  outline: none;
  background-color: #fff;
  color:black;
}


.account-msg:hover {
  background-color: tomato;
  color:black;
}

.account-follow:hover {
  background-color: tomato;
  color:black;
}

.account-bio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#808080;
  word-break: break-all;
  width: 50%;
  padding: 4px;
}

.account-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.account-showfollow {
  cursor: pointer;
}

.account-showfollow:hover {
  text-decoration: underline;
  text-decoration-style:dashed;
  text-underline-offset: 4px;
  text-decoration-color:#404040;
}

.account-location {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color:#656565;
}

.account-sterotype {
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color:#656565;
  padding: 4px;
}

.account-sterotype-imgwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.account-sterotype-img {
  max-width: 100%;
  max-height: 100%;
}
