.Topnavigation-header {
  display: flex;
  position: fixed;
  justify-content:space-between;
  align-items: center;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  width: 86vw;
  margin-left: calc(100vw/15);
}

.Topnavigation-navbar {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content:space-between;
  color: #4d4d4d;
  width: 100%;
}


.Topnavigation-navbar a,
a:hover,
a:focus,
a:active {
  text-decoration: none;

}

.Topnavigation-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  text-decoration: none;
  color:#000000;
  letter-spacing: 2px;
}

.Topnavigation-cmenu {
  flex-grow: 2;
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.Topnavigation-menu {
  display: flex;
  flex-grow: 0.6;
  justify-content:space-around;
  align-items: center;
}

.Topnavigation-gateway {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Topnavigation-menu :hover {
  background-color: #FAFAFA;
  border-radius: 20px;
}

.Topnavigation-btn {
  padding-left: calc(100vw / 54.6);
  padding-right: calc(100vw / 54.6);
  padding-top: calc(100vw / 136.5);
  padding-bottom: calc(100vw / 136.5);
  border-radius:4px;
  color:black;

  font-weight: 500;
  font-size:1rem;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.Topnavigation-sign {
  padding-left: calc(100vw / 54.6);
  padding-right: calc(100vw / 54.6);
  padding-top: calc(100vw / 120);
  padding-bottom: calc(100vw / 120);
  border-radius:50px;
  background-color:#FAFAFA;
  background-color: black;
  border: 1px solid #404040;
  color:#ffff;
  cursor: pointer;
  font-weight: 500;
  font-size:0.9rem;
}

.Topnavigation-joinnow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Topnavigation-signin {
  display: flex;
}

.Topnavigation-joinnow:hover {
  background-color: #FAFAFA;
  border-radius: 10px;
  color:black;
}

.Topnavigation-signin :hover {
  border: 1px solid #e0e0e0;
  background-color: #FCFCFC;
  color:black;
}

.Topnavigation-menupopu {
  display: none;
}

@media (max-width: 780px) {

  .Topnavigation-navbar {
    font-size: 16px;
  }

  .Topnavigation-btn {
    padding-left: calc(100vw / 54.6);
    padding-right: calc(100vw / 54.6);
    padding-top: calc(100vw / 120);
    padding-bottom: calc(100vw / 120);
    border-radius:4px;
    color:black;
    font-size: 1rem;
  }


  .Topnavigation-logo {
    font-size: 1.4rem;
  }

  .Topnavigation-menu {
    display: none;
  }

  .Topnavigation-sign {
    padding-left: calc(100vw / 54.6);
    padding-right: calc(100vw / 54.6);
    padding-top: calc(100vw / 70);
    padding-bottom: calc(100vw / 70);
    border-radius:50px;
    background-color:#FAFAFA;
    background-color: black;
    border: 1px solid #404040;
    color:#ffff;
    font-size: 1rem;
    cursor: pointer;
  }

  .Topnavigation-logoline-img {
    max-width: 26%;
  }

  .Topnavigation-menupopu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.2rem;
    padding: 4px;
    cursor: pointer;
  }

}

@media (max-width: 400px) {

  .Topnavigation-navbar {
    font-size: 12px;
  }

  .Topnavigation-btn {
    padding-left: calc(100vw / 54.6);
    padding-right: calc(100vw / 54.6);
    padding-top: calc(100vw / 120);
    padding-bottom: calc(100vw / 120);
    border-radius:4px;
    color:black;
    font-size: 0.8rem;
  }


  .Topnavigation-logo {
    font-size: 1.2rem;
  }

  .Topnavigation-menu {
    display: none;
  }

  .Topnavigation-sign {
    padding-left: calc(100vw / 54.6);
    padding-right: calc(100vw / 54.6);
    padding-top: calc(100vw / 50);
    padding-bottom: calc(100vw / 50);
    border-radius:50px;
    background-color:#FAFAFA;
    background-color: black;
    border: 1px solid #404040;
    color:#ffff;
    font-size: 0.9rem;
    cursor: pointer;
  }

  .Topnavigation-logoline-img {
    max-width: 26%;
  }

  .Topnavigation-menupopu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.2rem;
    padding: 4px;
    cursor: pointer;
  }

}

@media (max-width: 350px) {

  .Topnavigation-navbar {
    font-size: 12px;
  }

  .Topnavigation-btn {
    padding-left: calc(100vw / 54.6);
    padding-right: calc(100vw / 54.6);
    padding-top: calc(100vw / 120);
    padding-bottom: calc(100vw / 120);
    border-radius:4px;
    color:black;
    font-size: 0.7rem;
  }


  .Topnavigation-logo {
    font-size: 1rem;
  }

  .Topnavigation-menu {
    display: none;
  }

  .Topnavigation-sign {
    padding-left: calc(100vw / 54.6);
    padding-right: calc(100vw / 54.6);
    padding-top: calc(100vw / 45);
    padding-bottom: calc(100vw / 45);
    border-radius:50px;
    background-color:#FAFAFA;
    background-color: black;
    border: 1px solid #404040;
    color:#ffff;
    font-size: 0.7rem;
    cursor: pointer;
  }

  .Topnavigation-logoline-img {
    max-width: 26%;
  }

  .Topnavigation-menupopu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.2rem;
    padding: 4px;
    cursor: pointer;
  }

}
