* {
  box-sizing: border-box;
}

.Imgpreview-exp {
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: center;
     position: fixed;
     left: 0;
     top: 0;
     width: 100%;
     height: 100%;
     overflow: auto;
     cursor: pointer;
     background-color: rgba(0,0,0,0.8);

}

.Imgpreview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:80%;
  border-radius: 10px;
}

.Imgpreview-footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 10%;
  color:#fff;
}

.Imgpreview-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 10%;
  color:#fff;
}

.Imgpreview-back {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left:4vw;
  padding: 4px;
  font-size: 1.4rem;
}

.Imgpreview-back:hover {
  color:cornflowerblue;
}

.Imgpreview-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Imgpreview-img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}
