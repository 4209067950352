* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

.Squad-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.25);
}

.Squad-modal_content {
  background-color: white;
  position: absolute;
  top: 10%;
  width: 30%;
  padding: 20px;
  border-radius: 5px;
}

.Squad-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding-bottom:10px;
}

.Squad-close {
  font-size: 1.4rem;
  cursor: pointer;
}

.Squad-line {
  border-top:1px solid #E6E6E3;
  padding-bottom: 10px;
}

.Squad-bottom {
  border-bottom: 1px solid #E6E6E3;
}

.Squad-wrapper {
  display: inline-block;
  font-size: 1.8rem;
  color:tomato;
}

.Squad-icon {
  display: flex;
  align-items: center;
  padding: 4px;
  cursor: pointer;
}

.Squad-text {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color:#404040;
  cursor: pointer;
}

.Squad-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Squad-btn {
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  font-size: 0.8rem;
  background-color: #3498db;
  color:#fff;
}

.Squad-btn-disable {
  border: none;
  cursor: default;
  padding: 10px;
  border-radius: 10px;
  font-size: 0.8rem;
  border-radius: 10px;
}

@media (max-width: 780px) {

  .Squad-modal {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
  }

  .Squad-modal_content {
    background-color: white;
    position: static;
    width: 90%;
    padding: 20px;
    border-radius: 5px;
  }

}
