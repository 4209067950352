.messagelist-wrapper-outer {
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  height: 100%;
  width: 100%;
}

.messagelist-wrapper {
  display: flex;
  flex-direction: column-reverse;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.messagelist-item {
  display: flex;
  justify-content: flex-end;
  margin-left: 80px;
  margin-right: 80px;
  padding: 10px;
  cursor: pointer;
}

.messagelist-item-str {
  display: flex;
  justify-content: flex-start;
  margin-left: 80px;
  margin-right: 80px;
  padding: 10px;
  cursor: pointer;
}

.messagelist-menu {
  display: none;
}

.messagelist-item:hover .messagelist-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  color:red;
}

.messagelist-item-str:hover .messagelist-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  cursor: pointer;
}

.messagelist-item-text {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  max-width: 80%;
  flex-wrap: wrap;
  word-break: break-all;
  padding: 14px;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.messagelist-item-text-from {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  max-width: 80%;
  flex-wrap: wrap;
  word-break: break-all;
  padding: 14px;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.messagelist-footer:focus {
  outline: none;
  border: none;
}

.messagelist-bottom {
  display: flex;
  justify-content: center;
  align-items:flex-start;
  height: 20%;
  width: 100%;
}

@media (max-width: 780px) {

  .messagelist-item {
    display: flex;
    justify-content: flex-end;
    margin-left:0px;
    margin-right:0px;
    width: 100%;
    padding: 10px;
    cursor: pointer;
  }

  .messagelist-item-str {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
    margin-left:0px;
    margin-right:0px;
    cursor: pointer;
  }

  .messagelist-wrapper-outer {
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    height: 100%;
    width: 100%;
    padding-bottom: 50px;
  }

}
