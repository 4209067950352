* {
  box-sizing: border-box;
}

.messagewrapper {
  position: fixed;
  display: flex;
  top:70px;
  left:0;
  width: 100%;
  height: 100vh;
  z-index: 12;
  background-color: #fff;
}

.messagewrapper-title {
  display: flex;
  font-size: 1.4rem;
  font-weight:400;
  padding: 4px;
  width: 100%;
}

.messagewrapper-people {
  display: flex;
  justify-content: flex-start;
  align-items:center;
  flex-direction: column;
  width: 40%;
  padding: 4px;
  overflow: auto;
  border-right:1px solid lightgray;
}

.messagewrapper-people-hide  {
  display: flex;
  justify-content: flex-start;
  align-items:center;
  flex-direction: column;
  width: 40%;
  padding: 4px;
  overflow: auto;
  border-right:1px solid lightgray;
}

.messagewrapper-chat {
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  width: 100%;
}

.messagewrapper-header {
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #E8EBEE;
  height: 60px;
}

.messagewrapper-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 84%;
  border-radius: 10px;
  border: 1px solid lightgray;
  padding: 10px;
  resize: none;
}

.messagewrapper-input:focus {
  border: 1px solid cornflowerblue;
  outline: 1px solid cornflowerblue;
}

.message-input {
  display: flex;
  border-radius: 10px;
  width: 100%;
  border: 1px solid lightgrey;
}

.messagewrapper-body-inp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 20%;
}

.message-prfwrapper {
   display: flex;
   justify-content: center;
   align-items: center;
}

.message-profileimg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50px;
  padding: 4px;
  color:darkgrey;
  font-size: 1.2rem;
  font-weight:bold;
}

.message-container-outer {
  width: 100%;
  padding: 2px;
}

.message-container {
  display: flex;
  justify-content:space-between;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  padding: 14px;
  border: 1px solid #E8EBEE;
  cursor: pointer;
  background-color: #fff;
}

.message-container:focus {
  background-color: #f9f9f9;
  border: 1px solid #E8EBEE;
  outline: none;
}

.message-container:hover {
  background-color: #f9f9f9;
}

.message-username {
   width: 120px;
   word-break: break-all;
}

.message-delete {
  display: none;
}

.message-container:hover .message-delete {
  display: block;
  background-color: #f9f9f9;
  font-size: 1.4rem;
  color:orangered;
}

.messagewrapper-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 70%;
}

.messagewrapper-body-text {
  display: flex;
  flex-direction: column;
  overflow-y:auto;
  width: 100%;
  height: 100%;
}

.message-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin-left: 60px;
}

.msg-pooldetails-titlearrow {
  display: none;
}

.mag-pooldetails-titlearrow:hover {
  background-color: #f5f1fe;
}


@media (max-width: 780px) {


  .msg-pooldetails-titlearrow {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    border-radius: 50px;
    cursor: pointer;
  }

  .msg-pooldetails-titlearrow:hover {
    background-color: #f5f1fe;
  }

  .messagewrapper {
    position: fixed;
    display: flex;
    top:58px;
    left:0;
    width: 100%;
    height: 100vh;
    z-index: 12;
    background-color: #fff;
  }

  .messagewrapper-chat-hide {
    display: none;
  }

  .messagewrapper-people {
    display: flex;
    justify-content: flex-start;
    align-items:center;
    flex-direction: column;
    width: 100%;
    padding: 4px;
    overflow: auto;
    border-right:1px solid lightgray;
  }

  .messagewrapper-people-hide {
    display: none;
  }

  .messagewrapper-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90vw
  }



}
