.Pooldetaildesc-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 450px;
  background-color: #fff;
}

.Pooldetaildesc-wrapper {
  display: flex;
  flex-direction: column;
  width: 50vw;
  background-color: #fff;
}

.Pooldetails-profile {
  display: flex;
}

.Pooldetails-desctable {
  display: flex;
  flex-direction: column;
}

.Pooldetails-profileimg {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.Pooldetails-profile-name {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Pooldetails-topicdetails {
  display: flex;
  border: 1px solid lightgray;
  width: 100%;
}

.Pooldetails-topic {
  display: flex;
  width: 90px;
  padding: 4px;
  background-color: #f3f3f3;
}

.Pooldetails-topicname {
  padding: 4px;
  width:400px;
  word-break: break-all;
}

.Pooldetails-empty {
  display: flex;
  height: 20px;
}

.Pooldetails-profile-empty {
  display: flex;
  width: 10px;
}

.Pooldetaildesc-desc {
  width: 40vw;
  word-break: break-all;
}

.Pooldetaildesc-share {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
}

.Pooldetaildesc-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom:4px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid lightgrey;
}

.Pooldetaildesc-btn:hover {
  background-color:#f9f9f9;
}


.Pooldetaildesc-comment {
  display: flex;
}

.Pooldetaildesc-pstbox {
 display: flex;
 flex-direction: column;
}

.Pooldetail-shareicon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
}

.Pooldetail-sharetxt {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  padding: 4px;
}


@media (max-width: 912px) {

  .Pooldetaildesc-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left:0px;
    background-color: #fff;
  }

  .Pooldetaildesc-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
  }

  .Pooldetaildesc-desc {
    width: 90vw;
    word-break: break-all;
  }

}
