.Otp-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 6px;
}

.Opt-backdrop1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.1);
}

.Otp-Spinner-wrapper {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: #ffff;
  border: 1px solid lightgray;
  background-color: rgba(17, 17, 26, 0.1);
  opacity: 0.2;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.Otp-container {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  flex-direction: column;
  width: 40%;
  padding: 10px;
  min-height: 500px;
  height: 80%;
  border-radius: 20px;
  background-color: #ffff;
  border: 1px solid lightgray;
  backdrop-filter: blur(2px);
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.Otp-header {
  font-size:20px;
  padding:10px;
}

.Otp-edit-btn {
  cursor: pointer;
  color:cornflowerblue;
  border-bottom-style: dashed;
  border-color: black;
  border-width:1px;
}



.Otp-email-text {
  display: flex;
  justify-content: center;
  width:100%;
  font-size:1rem;
  padding: 2px;
}

.Otp-email-text1 {
  display: flex;
  justify-content: center;
  width:100%;
  font-size:1rem;
  padding: 2px;
  font-weight: bold;
}

.Otp-email-idtext {
  display: flex;
  justify-content: center;
  width:100%;
  font-size:1rem;
  padding: 0px;
  outline: none;
  border: none;
}

.Otp-email-idtext:focus {
  outline: none;
  border: none;
}

.Otp-email-box {
  width:50%;
  height: 40px;
}

.Otp-email {
  display: flex;
  flex-direction: row;
  border-bottom-style: dashed;
  border-width: 1px;
  padding: 1.2px;
}

.Otp-email-cancel {
  cursor: pointer;
}

.Otp-err {
  display: flex;
  justify-content: center;
  align-items: center;
  height:30px;
  width:100%;
}

.Otp-spam-msg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.Otp-con-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  padding: 14px;
  cursor: pointer;
  color:#ffffff;
  border: none;
  border-radius: 6rem;

  background-color: cornflowerblue;
  font-size: 1.1rem;
  cursor: pointer;

}

.Otp-con-btn:hover {
  background-color: #4582ec;
}

.Otp-container-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Otp-container-getstarted {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
  padding: 10px;
  border-radius: 50px;
  color:#ffff;
  background-color: #ff4645;
}


.Otp-container-footer1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Otp-container-getstarted1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
  padding: 10px;
  border-radius: 50px;
  color:#ffff;
  background-color: #ffff;
}

@media (max-width: 994px) {

  .Otp-spam-msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


}

@media (max-width: 780px) {

  .Otp-container {
    display: flex;
    justify-content:flex-start;
    align-items: center;
    flex-direction: column;
    width: 90%;
    padding: 10px;
    min-height: 500px;
    height: 80%;
    border-radius: 20px;
    background-color: #ffff;
    border: 1px solid lightgray;
    backdrop-filter: blur(2px);
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  }


}
