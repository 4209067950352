.homapagewrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
}

.homapagelinkwrapper {
  position: fixed;
  top:60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 8px;
  width: 100%;
  z-index: 1;
}

.homapagecontainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  top:0px;
  width: 100%;
}

.homapage-homeimg {
  width: 2rem;
}

.homapage-publishimg {
  width: 2rem;
}

.homapage-freindsimg {
  max-width: 2rem;
}

.homapage-exploreimg {
  width: 2rem;
}

.homapage-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#495057;
}

.homapagebtn-space {
  width:12vw;
}

.homapagelinkbtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-bottom: 1px solid #fff;
  padding: 2px;
}

.homapagelinkbtnactive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-bottom: 1px solid grey;
  padding: 2px;
}

.homapage-btn:hover {
  color:cornflowerblue;
}

.homapage-btntxt {
  padding: 6px;
}



@media (max-width: 780px) {

  .homapagelinkwrapper {
    position: fixed;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 8px;
    width: 100%;
    z-index: 1;
  }

  .homapagecontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    position: absolute;
    top:6vw;
    width: 100%;
  }

  .homapage-homeimg {
    width: 1.4rem;
  }

  .homapage-publishimg {
    width: 1.4rem;
  }

  .homapage-freindsimg {
    max-width: 1.4rem;
  }

  .homapage-exploreimg {
    width: 1.4rem;
  }

  .homapage-btntxt {
    padding: 4px;
    font-size: 1rem;
  }


}


@media (max-width: 620px) {

  .homapagecontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    position: absolute;
    top:4vw;
    width: 100%;
  }

}


@media (max-width: 480px) {

  .homapagecontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    position: absolute;
    top:2vw;
    width: 100%;
  }

}
