* {
  box-sizing: border-box ;
  padding: 0 ;
  margin: 0 ;
}

.SearchbarWrapper {
   position: absolute;
   top:-40px;
   left: 16vw;
   z-index: 999;
   background-color: #fff;
   overflow-y: auto;
}

.SearchbarContainer {
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  width: 70vw;
  min-height: 100%;
  max-height: 500px;
  border-radius: 4px;
  border: 1px solid #f2f4f7;
  overflow:auto;
  background-color: #fff;

  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.02),
              0 18px 36px -18px rgba(0, 0, 0, 0.3),
              0 -12px 36px -8px rgba(0, 0, 0, 0.025);

}

.Searchbar {
  display: flex;
  width: 100%;
  height:60px;
}

.Searcharea {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
  font-size: 1.1rem;
}

.Searchareainput {
  width: 95%;
  height: 100%;
  outline: none;
  border: none;
  border-bottom: 1px dashed  #fff;
  font-size: 1.1rem;
  color:#808080;
}

.Searchareainput:focus {
  border: none;
  outline: none;
  border-bottom: 1px dashed  lightgrey;
}




.Searchicon {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 1.6rem;
  color: darkgrey;
}

.Searchiconeb {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 1.6rem;
  color: #678fff;
  cursor: pointer;
}

.Clearicon {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: darkgrey;
  border-right: 1px solid lightgray;
  cursor: pointer;
}

.Closebtn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  padding: 4px;
  color: darkgrey;
  cursor: pointer;
}

.Searchbtn {
  display: flex;
  flex-grow: 2;
  justify-content: center;
  align-items: center;
}

.SearchresultWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Searchresult {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.SearchresultTitle {
    padding: 8px;
    background-color: #f8f9fa;
    color: #6C757D;
  }
.SearchMessage {
   padding: 8px;
   background-color: #f8f9fa;
   color: #6C757D;
}


.Messages {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Wrapperresult {
  padding: 8px;
  border-radius: 10px;
}

.Result {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #E9ECEF;
  border: 2px solid #f2f4f7;
  color: #6C757D;
  cursor: pointer;
}

.Result-emptyspace {
  height: 10px;
}

.Loadmore {
  padding: 10px;
  cursor: pointer;
  color:blue;
  text-decoration: none;
}


@media (max-width: 780px) {

  .SearchbarContainer {
    display: flex;
    margin: 0px;
    flex-direction: column;
    justify-content:flex-start;
    width: 100vw;
    height: 100vh;
    border-radius: 4px;
    border: 1px solid #f2f4f7;
    overflow: scroll;
  }

  .SearchbarWrapper {
    position: fixed;
    top:0px;
    left: 0px;
    height: 100vh;
    background-color: #fff;
    overflow: scroll;
  }

}
