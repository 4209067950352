.deactivateaccount {
  position: fixed;
  overflow: auto;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
}

.deactivateaccount-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}



.depassword-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#404040;
  background-color: #fff;
  border-radius: 10px;
  padding: 12px;
  font-size: 1.2rem;
  border-color:lightgray;
  outline: none;
  border-style: solid;
}

.deactivate-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color:red;
  background-color: #fff;
  border-radius: 10px;
  padding: 12px;
  font-size: 1.2rem;
  border: none;
  background-color: #F2F2F2;
}

.deactivate-btn:hover {
  padding: 10px;
}

.deactiveaccn-spc {
  height:0px;
}


@media (max-width: 780px) {

  .deactiveaccn-spc {
    height:50px;
  }

}
