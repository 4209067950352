* {
  box-sizing: border-box;
}

.Profilecontainer {
  position: absolute;
  left: 0px;
  top: -80px;
  background-color: #fff;
  width: 100%;
  z-index: 1;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Subprofilecontainer {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Profiletitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px;
  padding-left: 80px;
}

.Profiletitlearrow {
  display: flex;
  align-items: center;
  font-size: 2rem;
  border-radius: 50px;
  cursor: pointer;
  color:#495057;
}

.Profiletitlearrow:hover {
  background-color: #f5f1fe;
}

.Profiletitletext {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  color:#495057;
}

.Profiletitlesubmenu {
  display: flex;
  padding-left: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #f2f4f7;
}

.Profilenavitem {
  display: flex;
  text-decoration: none;
  color:#495057;
  font-size: 1.2rem;
  border: 1px solid #ffffff;
}

.Profilenavitema {
  display: flex;
  text-decoration: none;
  color:#495057;
  font-size: 1.2rem;
  border: 1px solid #ffffff;
  border-bottom: 1px solid #495057;
}

.Profilenavitem:hover {
  border-bottom: 1px solid #495057;
}

.Profilemptyspace {
  width: 20px;
}

@media (max-width: 780px) {

  .Profilecontainera {
    position: fixed;
    left: 0px;
    top: 70px;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    overflow: auto;
  }

  .Profiletitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 10px;
    padding-left: 4px;
  }

  .Profiletitletext {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color:#495057;
  }


}
