* {
  box-sizing: border-box;
}

.Homepage-topnavbar-menu-navpanel-menu {
  position: fixed;
  overflow: auto;
  left:70vw;
  top:64px;
  color:#495057;
  background-color: #ffffff;
  border-radius: 10px;
  width:22vw;
  box-shadow: 0px 0px 2px 2px #E1E1E1;
}

.Homepage-topnavbar-menu-navpanel-menu-am {
  position: fixed;
  overflow: auto;
  left:70vw;
  top:66px;
  color:#495057;
  background-color: #ffffff;
  border-radius: 10px;
  width:22vw;
  box-shadow: 0px 0px 2px 2px #E1E1E1;
}


.Homepage-topnavbar-menu-close {
  display: none;
}

.Homepage-topnavbar-menu-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Homepage-topnavbar-menu-menuprofileicon {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  padding: 10px;
}

.Homepage-topnavbar-menu-menuprofile {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  padding: 10px;
}

.Homepage-topnavbar-menulist {
  display: flex;
  flex-direction: column;
}

.Homepage-topnavbar-menu-wrapper {
  display: flex;
  justify-content:flex-start;
  border-bottom: 1px solid #EDEDED;
  padding: 20px;
  font-size: 1.2rem;
  cursor: pointer;
}

.Homepage-topnavbar-menu-wrapper:hover {
   background-color:#f2f4f7;
}

.Homepage-topnavbar-menuheader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.Homepage-topnavbar-profilewrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Homepage-topnavbar-profile {
  height: 2.2rem;
  width: 2.2rem;
  background-color: #f2f4f7;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.Homepage-topnavbar-name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.2rem;
  padding: 4px;
}

.Homepage-topnavbar-menuheader-wrapper {
   display: flex;
   justify-content:flex-start;
   align-items: center;
   color:#495057;
   font-size:1.3rem;
   padding: 20px;
   border-bottom: 1px solid #E1E1E1;
   cursor: pointer;
}

.Homepage-topnavbar-menuheader-wrapper:hover {
   background-color:#f2f4f7;
}

.Homepage-topnavbar-defaultname {
  width: 100%;
  font-size: 1.2rem;
  padding: 4px;
  padding-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


@media (max-width: 940px) {


  .Homepage-topnavbar-menu-navpanel-menu {
    position: fixed;
    overflow: auto;
    left:70vw;
    top:64px;
    color:#495057;
    background-color: #ffffff;
    border-radius: 10px;
    width:25vw;
    box-shadow: 0px 0px 2px 2px #E1E1E1;
  }

{/*
  .Homepage-topnavbar-menu-navpanel-menu-am {
    position: fixed;
    overflow: auto;
    top:0px;
    left:0px;
    width:100%;
    height: 100vh;
    max-height: 100%;
    color:#495057;
    background-color: #ffffff;
    border-radius:0px;
    padding: 0px;
    transition:0.3s;
    z-index: 14;
  }
*/}

}


@media (max-width: 780px) {

 .Homepage-topnavbar-menu-navpanel-menu {
   position: fixed;
   top:0px;
   left:0px;
   width:0px;
   height: 100vh;
   color:#495057;
   background-color: #ffffff;
   border-radius:0px;
   padding: 0px;
   transition:0.3s;
   overflow-x: hidden;
 }

 .Homepage-topnavbar-menu-navpanel-menu-am {
   position: fixed;
   top:0px;
   left:0px;
   width:100%;
   height: 100vh;
   color:#495057;
   background-color: #ffffff;
   border-radius:0px;
   padding: 0px;
   transition:0.3s;
   z-index: 14;
 }

 .Homepage-topnavbar-menu-close {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 2px;
   font-size:2.4rem;
   color:#495057;
   cursor: pointer;
 }


}
