.Fpasswordnext-header-center {
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 1.2rem;
   padding: 4px;
   font-weight:500;
}


.Fpasswordnext-option {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Fpasswordnext-email {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 4px;
  cursor: pointer;
}

.Fpasswordnext-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 4px;
  cursor: pointer;
}

.Fpasswordnext-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.Fpasswordnext-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color:cornflowerblue;
}
