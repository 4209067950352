.Privacy-wrapper  {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 70px;
}

.Privacy-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-style: dashed;
  border-radius: 10px;
  padding: 40px;
}


 @media (max-width: 650px) {

  .Privacy-wrapper  {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
  }


}
