* {
  box-sizing:border-box;
}

.Delpost-wrapper {
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.Delpost-container {
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  width: 400px;
  height: 170px;
  background-color: #fff;
  border-radius: 10px;
}

.Delpost-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  width: 100%;
  padding:6px;
}

.Delpost-close-text {
  display: flex;
  justify-content: flex-start;
  padding-left:24px;
  align-items: center;
  width: 100%;
}

.Delpost-close {
  display: flex;
  justify-content:center;
  align-items:center;
  color:#404040;
  font-size: 1.4rem;
  cursor: pointer;
  border-radius: 50px;
  padding: 2px;
  margin-bottom: 30px;
}

.Delpost-close:hover {
  color:black;
  background-color: #f5f5f5;
}

.Delpost-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  padding-left:30px;
  width: 100%;
}

.Delpost-btn-wrapper {
  display: flex;
  justify-content:flex-end;
  align-items:center;
  font-size: 1.2rem;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.Delpost-inp {
  display: flex;
  width: 100%;
  padding: 0.7125rem 1rem;
  border: 2px solid #ffffff;
  border-radius: 6px;
  background-color: #f5f5f5;
  font-size: 1rem;
  appearance: none;
}

.Delpost-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #fa523e;
  color: #ffffff;
  font-size: 1rem;

}

.Delpost-btn-height {
  height: 10px;
}

.Delpost-btn:hover {
 background-color:#ff0000;
}
