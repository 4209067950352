.Postpriview-wrapper {
  position: absolute;
  top:250px;
  left:20.2vw;
  height: 100vh;
  width: 50%;
}

.Postpriview-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
  border-bottom:1px solid #f2f2f2;
}

.Postpriview-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 14px;
}

.Postpriview-titlearrow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
  border-radius: 50px;
  cursor: pointer;
}

.Postpriview-titlearrow:hover {
  background-color: #f5f1fe;
}

.Postpriview-titlearrow-tile {
  padding: 2px;
  font-size: 1.2rem;
  font-weight: 400;
  color:#404040;
}

.Postpriview-replybox {
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100%;
}

@media (max-width: 780px) {

  .Postpriview-wrapper {
    position: absolute;
    top:140px;
    left: 0px;
    width: 100%;
  }

}
