.postlist-container-wrapper {
  display: flex;
  justify-content:center;
  align-items:center;
  flex-direction: column;
  width: 70%;
}

.postlist-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 90%;
}

.postlist-container-user {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
}

.postlist-wrapper {
  display: flex;
  align-items:center;
  flex-direction: column;
  padding:10px;
  width: 100%;
  height: auto;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid lightgray;
}

.postlist-wrapper img {
  border-radius: 10px;
}

.postlist-wrapper:hover {
  cursor: pointer;
  background-color:#fcfcfc;
}

.postlist-space {
  width:10px;
}

.postlist-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
  width: 2.2rem;
  border: 1px solid darkgray;
  border-radius: 50px;
}

.postlist-reply-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.9rem;
  width: 1.9rem;
  border: 1px solid darkgray;
  border-radius: 50px;
}

.postlist-name {
  display:flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
  font-weight:500;
  width:100%;
  font-size: 0.8rem;
}

.postlist-outwrapper {
  display: flex;
  justify-content:space-around;
  align-items: center;
  width: 100%;
  padding: 4px;
}

.postlist-outprofile {
  display:inline-flex;
  justify-content:flex-start;
  align-items: center;
  width: 100%;
}

.postlist-time {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#909090;
}

.postlist-text {
   width: 100%;
   color: #404040;
   border-radius: 10px;
   padding: 10px;
   overflow: hidden;
   word-wrap: break-word;
   text-overflow: ellipsis;
}

.postlist-img {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap:12px;
  width: 100%;
}

.postlist-item-img {
  display:flex;
  justify-content: center;
  align-items: center;
}

.postlist-item-img img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80px;
    max-height: 80px;
    cursor: pointer;
    flex-wrap: wrap;
    gap:12px;
}

.postlist-empty {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 0px;
}

.postlist-tag {
  display: flex;
  justify-content:center;
  align-items: center;
  padding: 4px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight:900;
  color:#404040;
  background-color: #fff;
}

.postlist-tag:hover {
  background-color: #e4e5e5;
}


.postlist-menu {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-top:-10px;
  margin-right:-204px;
  height: 100px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background-color:#ffffff;


 -webkit-box-shadow: 0px 0px 2px 2px #E1E1E1;
 -moz-box-shadow: 0px 0px 2px 2px #E1E1E1;
  box-shadow: 0px 0px 2px 2px #E1E1E1;
}

.postlist-menu-btn {
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.postlist-menu-btn:hover {
  background-color: whitesmoke;
}

.postlist-menu-ht {
  height: 4px;
}

.postlist-img  img {
    max-width: 40%;
    max-height: 40%;
    cursor: pointer;
}

.postlist-text:hover > img {
   cursor: pointer;
}

.postlist-spinner {
  display:inline-flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.postlist-reply {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 4px;
}

.postlist-reply-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding:10px;
  border-radius: 20px;
  border: 1px solid #fff;
}

.postlist-replto {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 20px;
  margin-bottom:10px;
  color:#404040;
  font-size: 0.9rem;
}

.postlist-reply-btn-wrapper:hover {
  /* background-color: #f9f9f9;
  border: 1px solid lightgrey; */
  color:cornflowerblue;
}

.postlist-reply-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.postlist-reply-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.postlist-reply-icon {
  display:flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.postlist-reply-details {
  display: flex;
  align-items:flex-start;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  height:100px;
  max-height: 200px;
  border-radius: 10px;
  background-color: #f5f6f6;
  border: 1px solid lightgray;
}



.postlist-reply-details img {
 max-height: 10%;
 max-width: 10%
}

.postlist-item-wrapper-img {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  gap:12px;
  width: 100%;
  border-radius: 4px;
}



@media (max-width: 780px) {

  .postlist-container-wrapper {
    display: flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
    width: 100%;
    margin-left:-10px;
 }

 .postlist-text {
    width: 100%;
    color: #404040;
    border-radius: 10px;
    padding: 8px;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
 }

 .postlist-menu {
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: flex-start;
   flex-direction: column;
   margin-top:-10px;
   margin-right:0px;
   height: 100px;
   width: 200px;
   padding: 10px;
   border-radius: 10px;
   background-color:#ffffff;

  -webkit-box-shadow: 0px 0px 2px 2px #E1E1E1;
  -moz-box-shadow: 0px 0px 2px 2px #E1E1E1;
   box-shadow: 0px 0px 2px 2px #E1E1E1;
 }

}
