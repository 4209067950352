.Deleteresources-delete {
  display: flex;
  position: fixed;
  justify-content:center;
  align-items: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.Deleteresources_content-delete {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 30%;
  height: auto;
  padding: 1.25rem;
  border-radius: 5px;
  margin-bottom: 10%;
}

.Deleteresources-delete-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
}

.Deleteresources-delete-cancel {
  position: relative;
  top:-20px;
  left: 10px;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Deleteresources-delete-cancel:hover  {
  color:cornflowerblue;
}

.Deleteresources-delete-body {
  display: flex;
  flex-direction: column;
}

.Deleteresources-delete-footer {
  display: flex;
  justify-content:flex-end;
}

.Deleteresources-btn-delete {
  padding: 10px;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #f4212e;
  color: #ffffff;
  font-size: 1rem;
}

.Deleteresources-btn-delete:hover {
  background-color: #f4212e;
  border: none;
  outline: none;
  cursor: pointer;
}


@media (max-width: 780px) {

  .Deleteresources_content-delete {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 80%;
    height: auto;
    padding: 1.25rem;
    border-radius: 5px;
    margin-bottom: 10%;
  }

}
