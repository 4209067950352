.accountdetailswrapper {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #fff;
}

.account-profiletitle {
  border: 1px solid red;
}


.Profiletitlearrow {
  display: flex;
  align-items: center;
  font-size: 2rem;
  border-radius: 50px;
  cursor: pointer;
  color:#495057;
}

.Profiletitlearrow:hover {
  background-color: #f5f1fe;
}

.Profiletitletext {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  color:#495057;
}
