.Poolcommentlist-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  word-break: break-all;
  padding: 10px;
}

.Poolcommentlist-details {
  display: flex;
  width: 100%;
}

.Poolcommentlist-profile-wrapper {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.Poolcommentlist-cmt-act {
  cursor: pointer;
}

.Poolcommentlist-profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width:40px;
  height:40px;
  border: 1px solid #b8b8b9;
}

.Poolcommentlist-profile-ept {
  display: flex;
  width:40px;
}

.Poolcommentlist-cmt {
  display: flex;
  flex-direction: column;
  width: 86%;
}

.Poolcommentlist-cmt-name {
  display: flex;
  font-size: 0.9rem;
}

.Poolcommentlist-cmt-comment {
  word-break: break-all;
}

.Poolcommentlist-panel {
  display: flex;
  width: 100%;
}

.Poolpostbox-img {
  max-width: 100%;
  height: auto;
  border-radius: 50px;
}

.Poolcommentlist-emptyspace {
  display: flex;
  width: 14px;
}

.Poolcommentlist-panel-btn {
  display: flex;
  padding-top:10px;
  justify-content: center;
  align-items: center;
}

.Poolcommentlist-emptycml {
  display: flex;
  height: 6px;
}

.Poolcommentlist-edit {

  display: flex;
  justify-content: center;
  align-items: baseline;
  cursor: pointer;
}

.Pooldetails-up {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.2rem;
}

.Poolcommentlist-reply {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Poolcommentlist-emptysp {
  width: 54px;
}


.Poolcommentlist-emptyhg {
  height:10px;
}

.Poolcommentlist-reply-cmt {
  display: flex;
  flex-direction: column;
}

.Poolcommentlist-box {
  width: 58px;
}

.Poolcommentlist-wrap-box {
  display: flex;
}

.Pooldetails-tflag {
  color:#3390ec;
}

.Pooldetails-fflag {

}

.Poolcommentlist-cmttxtarea {
  overflow-wrap: break-word;
  width: 100%;
}

.Poolcommentlist-cmttxtarea-hdn {
  display: none;
  width: 100%;
}

.Poolcommentlist-del {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top:0px;
  left: 0px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
}

.Poolcommentlist-notify {
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  z-index: 999;
}

.Poolcommentlist-showreply {
  margin-left: 74px;
  color:#065fd4;
}

.Poolcommentlist-replybtn {
  display: inline-block;
  cursor: pointer;
}

.Poolcommentlist-cmt-time {
  color:#737373;
}

.Poolcommentlist-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 912px) {

  .Poolcommentlist-notify {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 999;
  }

}

@media (max-width: 780px) {

  .Poolcommentlist-showreply {
    margin-left: 30px;
    color:#065fd4;
  }

}
