.videopart-body {
  width: 290px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
}

.videochatcontainer {
  width: 100%;
  border-radius: 10px;
  padding: 14px;
  border: 1px solid #E8EBEE;
  background-color: #fff;
}


@media (max-width: 780px) {

  .videopart-body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
  }

}
