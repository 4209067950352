.ispinner {
  position: relative;
  width: 20px;
  height: 20px; }
  .ispinner .ispinner__blade {
    position: absolute;
    left: 44.5%;
    top: 37%;
    width: 15%;
    height: 45%;
    border-radius: 50%/20%;
    -webkit-animation: iSpinnerBlade 1s linear infinite;
            animation: iSpinnerBlade 1s linear infinite;
    -webkit-animation-play-state: paused;
            animation-play-state: paused; }
    .ispinner .ispinner__blade:nth-child(1) {
      -webkit-animation-delay: -1.6666666667s;
              animation-delay: -1.6666666667s;
      -webkit-transform: rotate(30deg) translate(0, -150%);
              transform: rotate(30deg) translate(0, -150%); }
    .ispinner .ispinner__blade:nth-child(2) {
      -webkit-animation-delay: -1.5833333333s;
              animation-delay: -1.5833333333s;
      -webkit-transform: rotate(60deg) translate(0, -150%);
              transform: rotate(60deg) translate(0, -150%); }
    .ispinner .ispinner__blade:nth-child(3) {
      -webkit-animation-delay: -1.5s;
              animation-delay: -1.5s;
      -webkit-transform: rotate(90deg) translate(0, -150%);
              transform: rotate(90deg) translate(0, -150%); }
    .ispinner .ispinner__blade:nth-child(4) {
      -webkit-animation-delay: -1.4166666667s;
              animation-delay: -1.4166666667s;
      -webkit-transform: rotate(120deg) translate(0, -150%);
              transform: rotate(120deg) translate(0, -150%); }
    .ispinner .ispinner__blade:nth-child(5) {
      -webkit-animation-delay: -1.3333333333s;
              animation-delay: -1.3333333333s;
      -webkit-transform: rotate(150deg) translate(0, -150%);
              transform: rotate(150deg) translate(0, -150%); }
    .ispinner .ispinner__blade:nth-child(6) {
      -webkit-animation-delay: -1.25s;
              animation-delay: -1.25s;
      -webkit-transform: rotate(180deg) translate(0, -150%);
              transform: rotate(180deg) translate(0, -150%); }
    .ispinner .ispinner__blade:nth-child(7) {
      -webkit-animation-delay: -1.1666666667s;
              animation-delay: -1.1666666667s;
      -webkit-transform: rotate(210deg) translate(0, -150%);
              transform: rotate(210deg) translate(0, -150%); }
    .ispinner .ispinner__blade:nth-child(8) {
      -webkit-animation-delay: -1.0833333333s;
              animation-delay: -1.0833333333s;
      -webkit-transform: rotate(240deg) translate(0, -150%);
              transform: rotate(240deg) translate(0, -150%); }
    .ispinner .ispinner__blade:nth-child(9) {
      -webkit-animation-delay: -1s;
              animation-delay: -1s;
      -webkit-transform: rotate(270deg) translate(0, -150%);
              transform: rotate(270deg) translate(0, -150%); }
    .ispinner .ispinner__blade:nth-child(10) {
      -webkit-animation-delay: -0.9166666667s;
              animation-delay: -0.9166666667s;
      -webkit-transform: rotate(300deg) translate(0, -150%);
              transform: rotate(300deg) translate(0, -150%); }
    .ispinner .ispinner__blade:nth-child(11) {
      -webkit-animation-delay: -0.8333333333s;
              animation-delay: -0.8333333333s;
      -webkit-transform: rotate(330deg) translate(0, -150%);
              transform: rotate(330deg) translate(0, -150%); }
    .ispinner .ispinner__blade:nth-child(12) {
      -webkit-animation-delay: -0.75s;
              animation-delay: -0.75s;
      -webkit-transform: rotate(360deg) translate(0, -150%);
              transform: rotate(360deg) translate(0, -150%); }
  .ispinner.ispinner--animating .ispinner__blade {
    -webkit-animation-play-state: running;
            animation-play-state: running;
    will-change: opacity; }
  .ispinner.ispinner--white .ispinner__blade {
    background-color: white; }
  .ispinner.ispinner--gray .ispinner__blade {
    background-color: #8c8c8c; }
  .ispinner.ispinner--large {
    width: 35px;
    height: 35px; }
    .ispinner.ispinner--large .ispinner__blade {
      width: 8.5714285714%;
      height: 25.7142857143%;
      border-radius: 50%/16.67%; }

@-webkit-keyframes iSpinnerBlade {
  0% {
    opacity: 0.85; }
  50% {
    opacity: 0.25; }
  100% {
    opacity: 0.25; } }

@keyframes iSpinnerBlade {
  0% {
    opacity: 0.85; }
  50% {
    opacity: 0.25; }
  100% {
    opacity: 0.25; } }
