* {
  box-sizing: border-box;
}

.Register-container-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.Regmobile {
  cursor:text;
}



.Register-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Register-ht {
  height: 6px;
}

.Register-toggle-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  width: 140px;
  height: 30px;
  background: #E0E0E0;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
 transition: all 0.3s ease;
/* box-shadow: 0px 0px 8px 0px rgb(17 17 17 / 34%) inset; */
}

.Register-toggle-lbl {
   content: attr(data-off);
    width: 70px;
    height: 16px;
    background: #fff;
    border-radius: 26px;
    left: 2px;
    top: 2px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #111;
    padding: 5px 0px;
}

.Register-container {
  display: flex;
  flex-direction: column;
  margin-top: 8vh;
  width: 320px;
  height: 440px;
  border-radius: 4px;
}

.Register-header {
  display: flex;
  flex-direction:column;
  flex-grow: 0.4;
  padding-right: 14px;
  padding-left: 14px;
}

.Register-logo {
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  font-family: Vanilla Extract;
  text-decoration: none;
  color:#495057;
  letter-spacing: 2px;
}


.font-face-gm {
 font-family: 'vanilla_extractregular';
}


.Register-logo-style {
  color:#ff0000;
  letter-spacing: 2px;
}

.Register-title {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 4px;
  align-items: center;
  font-weight: 401;
  font-size: 1.2rem;
  color:#495057;
  color: #808080;

}

.Register-title-err {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #ff6e78;
  color:white;
  border-radius: 10px;
  font-size: 1rem;
}

.Err-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
}

.Register-title-separator {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
}

.Register-main {
  display: flex;
  flex-direction: column;
  flex-grow: 2.4;
  justify-content: space-between;
  padding-top: 0px;
  padding-right: 14px;
  padding-bottom: 14px;
  padding-left: 14px;
}

.Register-user {
  display: flex;
  flex-direction: column;
  padding-bottom:10px;
}

.msg {
  margin-top: 2px;
  display:block;
  margin-left:10px;
  width: 242px;
  font-size:13px;
  color:#686868;
}

.msgm {
  margin-top: 4px;
  display:block;
  margin-left:10px;
  width: 242px;
  font-size:13px;
  color:#686868;
}

.msgmerr {
  margin-top: 4px;
  display:block;
  margin-left:10px;
  width: 242px;
  font-size:13px;
  color : #de071c;
}

.msgerr {
  margin-top: 2px;
  display:block;
  margin-left:10px;
  width: 242px;
  font-size:13px;
  color : #de071c;
}

.Register-email {
  display: flex;
  flex-direction: column;
  padding-bottom: 2px;
}

.Register-formcontrol:focus-within {
  outline: none;
  border:2px solid #bfdeff;
  background-color: #ffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.Register-mobile-wrapper {
  display: flex;
  justify-content:flex-start;
  align-items: center;
}

.Register-mobile-input {
  display:flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f9f9f9;
  outline: none;
  border: none;
  width: 100%;
  font-size:1rem;
  border:none;
}

.Register-mobile-input:focus {
  outline: none;
  border: none;
}


.Register-mobile {
  background-color: #f9f9f9;
  font-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.Register-formcontrol {
  display: flex;
  width: 100%;
  padding: 0.7125rem 1rem;
  border: 2px solid #ffffff;
  border-radius: 6px;
  background-color: #f9f9f9;
  font-size: 1rem;

  -webkit-appearance: none;
  -moz-appearance: none;
   appearance: none;
}


.Register-mobile-code {
  display:flex;
  justify-content:flex-start;
  align-items: center;
  cursor: pointer;
  width: 120px;
}

.Register-country-code {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color:#606060;
}

.Register-mobile-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  padding-left:2px;
  padding-right:2px;
}

.Register-password-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 2px;
}

.Register-password-show {
  position: absolute;
  top:13px;
  right:10px;
  cursor: pointer;
  color: #808080;
  font-size: 1rem;
}


.Register-footer {
  display: flex;
  flex-direction: column;
}

.Register-note {
  display: flex;
  justify-content: center;
  padding: 0.6rem;
}

.Register-signup-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  cursor: pointer;
  color:#ffffff;
  border: none;
  border-radius: 6rem;
  background-color: #4582ec;
  font-size: 1rem;
}


input:focus {
  outline: none;
  border:2px solid #bfdeff;
  background-color: #ffffff;

  -webkit-appearance: none;
  -moz-appearance: none;
   appearance: none;
}

input:focus ~ .Register-password-show {
  background: #ffffff;
}



button:focus {
  outline: none;
}

.Register-signup-button:hover {
  background-color: #226be8;
}



.Register-password-show:hover {
  background-color: #f9f9f9;
  color:#4582ec;
}


hr {
  border: 0;
  border-top: 1px solid #e9ecef;
}

.btn-container{
  display:flex;
  justify-content: center;
  align-items: center;
  padding:1px;
  border-radius: 26px;
  background: #E0E0E0;
}

.btn-container-email {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color:cornflowerblue;
  padding-right:20px;
  padding-left: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  border-radius: 26px;
  transition: 0.4s;
 translate: 0px;
  -webkit-transform: translateX(0%)
}

.btn-container-emailmv {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E0E0E0;
    color: #606060;
  padding-right:20px;
  padding-left: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  border-radius: 26px;
  transition: 0.4s;
  -webkit-transform:translateX(110%);
}

.btn-container-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right:20px;
  padding-left: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  color: #606060;
  transition: 0.4s;
  -webkit-transform:translateX(0%);
}

.btn-container-mobilemv {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color:cornflowerblue;
  padding-right:20px;
  padding-left: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 26px;
  cursor: pointer;
  transition: 0.4s;
  -webkit-transform:translateX(-97%);
}

.Register-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Register-dropdown-block {
  display: none;
}

.Register-dropdown-body {
  display: flex;
  position: absolute;
  justify-content:flex-start;
  flex-direction: column;
  align-items:flex-start;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid lightgray;
  background-color: #fff;
  font-size: 1rem;
  color:#404040;
  top:340px;
  width:400px;
  height: 320px;
  z-index: 99;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.Register-list {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  flex-direction: column;
  border: 1px solid darkgrey;
  border-radius:10px;
  padding: 10px;
  font-size:1rem;
  width: 100%;
}

.item {
  display: flex;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.item:hover {
  background-color: lightgrey;
}

.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mobilenuminp {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 4px;
  color:#404040;
  width: 100%;
}

.mobilenuminp:focus {
  border: none;
  outline: none;
}

.alist {
  height: 300px;
  width: 100%;
  overflow-y:scroll;
  overflow-x: hidden;
}
