* {
  box-sizing: border-box;
}

.poolnotifycontainer {
  display: flex;
  flex-direction:row;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  max-width: 100%;
}

.poolnotify-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.poolnotifywrapper {
  position: fixed;
  left: 90px;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  padding: 10px;
  width: 30%;
  height: 400px;
}

.poolnotifywrapperbuffer {
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
  padding: 10px;
  width: 40%;

}

.poolnotify-arrow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top:50px;
  width: 100%;
}

.poolnotify-title-panel {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 200px;
   width: 200px;
}

.poolnotify-titlearrow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
  border-radius: 50px;
  cursor: pointer;
}

.poolnotify-titlearrow:hover {
  background-color: #f5f1fe;
}

.poolnotify-btn {
  display: flex;
  width: 90%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color:#ff6b17;

  background-image: linear-gradient(135deg, #ff5733  0%, #ff6b17 100%);

   border-radius: 4px;
  font-size: 1.2rem;
  color:#ffffff;
}

.poolnotify-img {
  max-width: 100%;
  max-height: 100%;
  border-radius:6px;
}

.poolnotify-desc {
  margin-top:0px;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.poolnotify-member-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.poolnotify-member-team {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  padding: 4px;
  border: 2px solid lightgrey;
  color:darkgrey;
  font-size: 1.2rem;
  font-weight:bold;
  cursor: pointer;
}

.poolnotify-member-title {
  font-size: 1.2rem;
  font-weight:400;
  color:#404040;
  cursor: default;
}

.poolnotify-member-icon {
  color:coral;
}

.poolnotify-membercount {
  padding: 2px;
  font-size: 1.2rem;
}


@media (max-width: 912px) {

 .poolnotifycontainer {
   display: flex;
   margin-top:0px;
   padding: 4px;
   flex-direction: column;
   max-width: 100vw;
 }


 .poolnotifywrapper {
   position: static;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   width: 100%;
   height: auto;
   padding: 10px;
 }

 .poolnotify-desc {
   margin-top: 0px;
   margin-left: 0px;
   padding: 10px;
   max-width: 94vw;
 }

 .poolnotify-arrow {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   margin-top:0px;
   width: 100%;
 }

}
