.Poolpostbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid #d4d4d5;
}

.Poolpostbox-profile-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Pooldetails-profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width:44px;
  height:44px;
}

.Poolpostbox-txt-wrapper {
  display: flex;
  align-items: center;
}

.Poolpostbox-formcontrol {
  display:block;
  word-wrap: break-word;
  padding: 0.8rem;
  border: 1px solid #b8b8b9;
  border-radius: 20px;
  background-color: #ffffff;
  font-size: 1rem;
  width:38vw;
  outline: none;
  cursor: text;
}

.Poolpostbox-formcontrol:focus {
  border:1px solid #bfdeff;
  background-color: #ffffff;
}

.Poolpostbox-formcontrol:empty:before {
    content: attr(data-placeholder);
}

.Poolpostbox-post-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Poolpostbox-postbtn-icn {
  display: flex;
  align-items: center;
  font-size: 2rem;
  color:#3390ec;
  cursor: pointer;
}

.Poolpostbox-postbtn-icn-disable {
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: #b8b8b9;
}

.Poolpostbox-cancelbtn-icn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  color:#3390ec;
  cursor: pointer;
}

.Poolpostbox-cancelbtn-icn-disable {
  display: flex;
  font-size: 1.1rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.Poolpostbox-emptyspace {
  width: 8px;
  border: 1px solid #fff;
}

.Poolpostbox-emptyspace-post {
  display: flex;
  width: 18px;
}

.Poolpostbox-img {
  max-width: 100%;
  border-radius: 50px;
}

@media (max-width: 780px) {

  .Poolpostbox-formcontrol {
    display:block;
    word-wrap: break-word;
    padding: 0.8rem;
    border: 1px solid #b8b8b9;
    border-radius: 20px;
    background-color: #ffffff;
    font-size: 1rem;
    width:60vw;
    outline: none;
  }


}
