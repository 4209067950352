* {
  box-sizing: border-box;
}

.wrapper-room {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content:flex-start;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  padding: 4px;
}

.wrapper-title {
  height: 8%;
  width: 100%;
}

.wrapper-body {
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left:10px;
  padding-right:10px;
}

.wrapper-video-all {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.wbody {
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-left:10px;
  padding-right:10px;
}

.wrapper-body-v2 {
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-left:10px;
  padding-right:10px;
}

.wrapper-body-v3 {
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-left:10px;
  padding-right:10px;
}

.wrapper-body-v4 {
  height: 80%;
  max-width:80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-left:10px;
  padding-right:10px;
}

.wrapper-body-v4-nowrap {
  max-height: 80%;
  max-width:100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left:10px;
  padding-right:10px;
}

.wrapper-body-v5-nowrap {
  max-height: 80%;
  max-width:100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left:10px;
  padding-right:10px;
}

.wrapper-body-v5 {
  height: 80%;
  max-width:80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-left:10px;
  padding-right:10px;
}

.wrapper-container-c2 {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
  flex-wrap: wrap;
  border-radius: 10px;
  margin: 0;
  padding:10px;
}

.wrapper-container-c3 {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
  flex-wrap: wrap;
  border-radius: 10px;
  margin: 0;
  padding:10px;
}

.wrapper-container-c4 {
  height: 50%;
  width: 50%;
  border-radius: 10px;
  margin: 0;
  padding:10px;
}

.wrapper-container-c5 {
  height: 50%;
  width: 30%;
  border-radius: 10px;
  margin: 0;
  padding:10px;
}

.wrapper-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
  flex-wrap: wrap;
  border-radius: 10px;
  margin: 0;
  padding:10px;
}

.wrapper-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17%;
  width: 100%;
}

.videoroom-chat {
  width: 290px;
  height: 100%;
  border-radius: 10px;
  border: 1px solid lightgrey;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.videoroom-chat-off {
  display:none;
}

.vd5 {
  object-fit:fill;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.vd4 {
  object-fit:fill;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.vd3 {
  object-fit:fill;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.vd2 {
  object-fit:fill;
  width: 100%;
  height: 96%;
  border-radius: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.vd1 {
  object-fit:fill;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.licon {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fc492d;
  font-size: 1.4rem;
}

.leave {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fc492d;
  width: auto;
  font-size: 1rem;
  padding-left: 12px;
  padding-right: 12px;
  background-size: 20px;
  background-position: center left 12px;
}

.mic {


}

.video-call-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.video-action-button {
  background-repeat: no-repeat;
  background-size: 24px;
  border: none;
  height: 3.4rem;
  margin: 0 8px;
  box-shadow:0 2px 6px 0 rgba(136, 148, 171, 0.2), 0 24px 20px -24px rgba(71, 82, 107, 0.1);
  border-radius: 50px;
  width: 3.4rem;
  cursor: pointer;
  outline: none;
  background-color: #fff;
  box-shadow: 0 0 10px gray;
}

.video-action-button-leave {
  background-repeat: no-repeat;
  background-size: 24px;
  border: none;
  height: 3.4rem;
  margin: 0 8px;
  box-shadow:0 2px 6px 0 rgba(136, 148, 171, 0.2), 0 24px 20px -24px rgba(71, 82, 107, 0.1);
  border-radius: 50px;
  width: 8rem;
  cursor: pointer;
  outline: none;
  background-color: #fff;
  box-shadow: 0 0 10px gray;
}

.video-action-button-leave  {
  opacity: 0.7;
}

.video-action-button:hover {
  opacity: 0.7;
}

.video-details {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  padding: 2px;
  margin-left: 10px;
  top:-100px;
  color:#fff;
  width: 100%;
}

.video-user-hand {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding:0px;
  width: 100%;
}

.video-user-img {
  display: flex;
  justify-content:flex-start;
  align-items:center;
  color:#fff;
  width:auto;
  height:50px;
  padding-left:10px;
  padding-right:10px;
  border-radius: 20px;
}

.video-user-img-off  {
  display: flex;
  justify-content:flex-start;
  align-items:center;
  color:#fff;
  width:200px;
  height: 40px;
  padding-left:10px;
  padding-right:10px;
  border-radius: 10px;
  border:none;
}

.video-user-txt {
  padding: 10px;
  width: auto;
}

.video-user-details {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 10px;
  background-color: black;
}

.video-user-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  padding:4px;
}

.video-user-line {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:1.4rem;
}

.video-user-fullscreen {
  display:flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.video-user-name {
  display:inline-block;
  max-width: 150px;
  width:auto;
  color:#fff;
  padding: 2px;
  align-items: center;
  font-size: 1.1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid transparent;
}

.video-chat-alerta {
  display: inline-flex;
  position:relative;
  top:-14px;
  left:-22px;
  font-size: 2rem;
  color:red;
}

.video-chat-icon {
  margin-top:100px;
  margin-left:-30px;
  z-index: 99;
  width: 60px;
}

.video-user-img img {
  max-width:100%;
  max-height:100%;
}

.rec-btn-wrapper {
  display:flex;
  justify-content: center;
  align-items: center;
}

.rec-btn {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  border: 1px solid black;
  background-color: crimson;
  box-shadow:0 2px 6px 0 rgba(136, 148, 171, 0.2), 0 24px 20px -24px rgba(71, 82, 107, 0.1);
  box-shadow: 0 0 10px black;
}

video {
  -webkit-transform: scaleX(-1) !important;
  transform: scaleX(1) !important;
}


@media (max-width: 780px) {


  .video-action-button {
    background-repeat: no-repeat;
    background-size: 24px;
    border: none;
    height:auto;
    margin: 0 8px;
    box-shadow:0 2px 6px 0 rgba(136, 148, 171, 0.2), 0 24px 20px -24px rgba(71, 82, 107, 0.1);
    border-radius: 50px;
    width: 100px;
    padding: 4px;
    cursor: pointer;
    outline: none;
    background-color: #fff;
    box-shadow: 0 0 10px gray;
  }

  .wrapper-container-c2 {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items:center;
    flex-wrap: wrap;
    border-radius: 10px;
    margin: 0;
    padding:10px;
  }

  .video-call-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding:0px;
  }

  .wrapper-body-v2 {
    height: 80%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left:10px;
    padding-right:10px;
  }

  .wrapper-body-v3 {
    height: 80%;
    width: 85%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding-left:10px;
    padding-right:10px;
  }

  .wrapper-container-c3 {
    height: 34%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items:center;
    flex-wrap: wrap;
    border-radius: 10px;
    margin: 0;
    padding:10px;
  }

  .wrapper-container-c4 {
    height: 40%;
    width: 50%;
    border-radius: 10px;
    margin: 0;
    padding:5px;
  }

  .wrapper-body-v4 {
    max-height: 80%;
    max-width:100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    padding-left:10px;
    padding-right:10px;
  }



  .wrapper-body-v5 {
    height: 80%;
    max-width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left:10px;
    padding-right:10px;
  }

  .wrapper-container-c5 {
    height: 30%;
    width: 50%;
    border-radius: 10px;
    margin: 0;
    padding:10px;
  }

  .leave {
    display: flex;
    justify-content: center;
    align-items: center;
    color:#fc492d;
    width: 100%;
    font-size: 0.9rem;
    padding-left: 10px;
    padding-right: 10px;
  }

  .video-user-name {
    display:inline-block;
    max-width: 150px;
    width:auto;
    color:#fff;
    padding: 2px;
    align-items: center;
    font-size: 0.9rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 1px solid transparent;
  }

  .video-user-line {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:1rem;
  }

  .video-user-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    padding:4px;
  }

  .video-user-img {
    display: flex;
    justify-content:flex-start;
    align-items:center;
    color:#fff;
    width:auto;
    height:30px;
    padding-left:10px;
    padding-right:10px;
    border-radius: 20px;
  }

  .video-user-txt {
    padding: 10px;
    font-size: 0.9rem;
    width: auto;
  }

  .video-details {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    padding: 2px;
    margin-left: 10px;
    top:-80px;
    color:#fff;
    width: 100%;
  }

  .videoroom-chat {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffff;
    border-radius: 10px;
    z-index: 99;
    border: 1px solid lightgrey;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  }

}
