.topicsearch-container {
   position:relative;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   width: 100%;
   border: none;
}

.topicsearch-inp {
  width: 100%;
  padding: 4.5px;
  border: none;
  outline: none;
  font-size: 1.2rem;
}

.topicsearch-inp:focus {
  outline: none;
  border: none;
  font-size: 1.2rem;
}

.topicsearc-result {
  position: absolute;
  top:54px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 8px 16px rgb(0 0 0 / 15%);
}

.topicsearch-line {
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.topicsearch-line-all {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  color:#404040;
  border: 2px solid lightgrey;
  border-style: dashed;
}

.topicsearch-line-all:hover {
  background-color: #f8f9fa;
}

.topicsearch-line:hover {
  background-color:#9683ec;
  color:whitesmoke;
  cursor: pointer;
}

.topicsearch-clearicon {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: darkgrey;
  border-right: 1px solid lightgray;
  cursor: pointer;
}

.topicsearch-closebtn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  padding: 2.5px;
  color: darkgrey;
  cursor: pointer;
}

@media (max-width: 780px) {

  .topicsearc-result {
    position: absolute;
    top:50px;
    left: 0px;
    width: 100%;
    height: auto;
    border-radius: 10px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 8px 16px rgb(0 0 0 / 15%);
  }



}
