* {
  box-sizing: border-box;
}

.Squadpicker-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.Squadpicker-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 10px;
  width: auto;
  background-color: #ffff;
}

.Squadpicker-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Squadpicker-header {
  display: flex;
  justify-content:space-between;
  align-items: center;
  color:#484848;
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
}

.Squadpicker-cancel {
  font-size: 1.2rem;
  cursor: pointer;
}

.Squadpicker-cancel:hover {
  color:red;
}

.Squadtitle {
  color:#484848;
  padding: 2px;
  font-size: 0.9rem;
}

.Squadline {
  height: 30px;
}

.Squadsubtitle {
  padding: 2px;
  font-size: 0.8rem;
}

.Squadname {
  color:#484848;
  font-weight:400;
  padding: 4px;
}

.Squadpicker-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid lightgray;
  cursor: pointer;
  height: 100px;
  width: 100px;
}

.Squadpicker-box-wrapper {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.Squadimg {
  max-width: 100%;
  max-height: 100%;
}


.Squadpicker-box:hover {
  background-color: #f9f9f9;
}

@media (max-width: 780px) {

 .Squadpicker-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.25);
  }

  .Squadpicker-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .Squadpicker-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 10px;
    width:100%;
    height:auto;
    overflow-y: scroll;
    background-color: #ffff;
  }

}
