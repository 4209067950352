.sharewrapper-wrapper {
  display: flex;
  flex-direction: column;
  border-width: 1px;
  background-color: #ffff;
  padding: 10px;
  width:30%;
  border-radius: 10px;
}

.sharewrapper-header {
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  width:100%;
  padding:8px;
}

.sharewrapper-title {
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  width:100%;
  border-width: 1px;
  padding: 4px;
}

.sharewrapper-title-disable {
  display: flex;
  color:lightgray;
  justify-content: center;
  font-size: 1.2rem;
  width:100%;
  border-width: 1px;
  padding: 4px;
}

.sharewrapper-input {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 4px;
}

.sharewrapper-input-box {
  display: flex;
  justify-content:row;
  border-radius: 20%;
  width: 50px;
  height: 50px;
  padding: 8px;
  background: #fff;
  border: 2px solid #666;
  color: #666;
  text-align: center;
  font: 32px Arial, sans-serif;
}

.sharewrapper-input-disablebox {
  display: flex;
  justify-content:row;
  border-radius: 20%;
  width: 50px;
  height: 50px;
  padding: 8px;
  background: #fff;
  border: 2px solid lightgrey;
  color: #666;
  text-align: center;
  font: 32px Arial, sans-serif;
}

.sharewrapper-input-enablebox {
  display: flex;
  justify-content:row;
  border-radius: 20%;
  width: 50px;
  height: 50px;
  padding: 8px;
  background: #fff;
  border: 2px solid #666;
  color: #666;
  text-align: center;
  font: 32px Arial, sans-serif;
}


.sharewrapper-confirm-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sharewrapper-confirm-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding:10px;
  cursor: pointer;
  width: 40%;
  color:#ffffff;
  border: none;
  border-radius: 6rem;
  background-color: #4582ec;
  font-size:1.2rem;
  cursor: pointer;
}

.sharewrapper-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  color:red;
  height:10px;
  width: 100%;
}

.sharewrapper-show-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 4px;
}

@media (max-width: 780px) {


  .sharewrapper-wrapper {
    display: flex;
    flex-direction: column;
    border-width: 1px;
    background-color: #ffff;
    padding: 10px;
    width:80%;
    border-radius: 10px;
  }

}

@media (max-width: 320px) {

  .sharewrapper-wrapper {
    display: flex;
    flex-direction: column;
    border-width: 1px;
    background-color: #ffff;
    padding: 10px;
    width:100%;
    border-radius: 10px;
  }

}
