.Tags-wrapper {
  position: fixed;
  top:0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 6px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;
}

.Tag-header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.Tags-list {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap:10px;
  flex-wrap: wrap;
}

.Tags-container {
  display: flex;
  justify-content:flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  width: 50%;
  min-height: 450px;
  height:450px;
  border-radius: 20px;
  background-color: #ffff;
  border: 1px solid lightgray;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  overflow: scroll;
   scrollbar-width: none;
}

.Tags-container::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.Tag-clear {
  font-size: 14px;
  color:red;
  border-bottom: 1px solid black;
  letter-spacing: 2px;
  border-bottom-style: dashed;
  cursor: pointer;
  padding: 2px;
}


.Tag-clearblank {
  font-size: 14px;
  color:red;
  border-bottom: 1px solid #ffff;
  letter-spacing: 2px;
  border-bottom-style: dashed;
  cursor: pointer;
  padding: 2px;
}




@media (max-width: 960px) {

  .Tags-container {
    display: flex;
    justify-content:center;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 90%;
    min-height: 400px;
    height:400px;
    border-radius: 20px;
    background-color: #ffff;
    border: 1px solid lightgray;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    overflow: scroll;
     scrollbar-width: none;
  }

   .Tags-container::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

}
