* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* main navigation-bar container */

.Homepage-topnavbar-navigation-bar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #ffffff;
  height: 64px;
  border-bottom: 1px solid #f2f4f7;
  z-index:14;
}

.Topnavigation-logo-img {
  max-width:8vw;
}

.Topnavigation-logo-pool-img {
  max-width:36px;

}

 /* logo container */

.Homepage-topnavbar-navbar-logo {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content:center;
  cursor: pointer;
  font-size: 1.6rem;
  color:#495057;
  text-decoration: none;
}

/* navigation bar logo  */

.Homepage-topnavbar-nlogo {
  font-size: 1.6rem;
  color:#ff0000;
}

/* searchbar container */

.Homepage-topnavbar-navbar-searchbar {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content:center;
}

/* wrapper for searchbar container */

.Homepage-topnavbar-wrapper-searchbar {
  display: flex;
  width: 34vw;
  height: 44px;
  border-radius: 10px;
  outline:none;
  border: none;
  overflow: hidden;
  cursor: pointer;
  background-color: #f9f9f9;
}

.Homepage-topnavbar-searchtext {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 29vw;
  border: none;
  outline: none;
  padding: 10px;
  color:#9DA5AA;
  overflow: hidden;
}

.Homepage-topnavbar-searchline {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  color:lightgray;
}

.Homepage-topnavbar-searchicon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 3.5vw;
  background-color: #f9f9f9;
  border: none;
  outline: none;
  overflow: hidden;
  font-size: 1.4rem;
  color:#9DA5AA;
}

/* navigation bar panel */

.Homepage-topnavbar-navbar-navpanel {
  display:flex;
  flex-grow: 1;
  justify-content:center;
  align-items: center;
}

/* navigation bar notification */

.Homepage-topnavbar-navbar-notification {
  display: flex;
  margin-top: 6px;
  font-size: 2.2rem;
  justify-content: center;
  color:#495057;
  cursor: pointer;
  border: 6px solid #ffffff;
  border-radius: 50%;
}

/* navigation bar menu */

.Homepage-topnavbar-navbar-menu {
  display: flex;
  padding: 4px;
}

/* navigation bar profile */

.Homepage-topnavbar-navbar-profile {
  height: 1.875rem;
  width: 1.875rem;
  background-color: #f2f4f7;
  border-radius: 50%;
  display: inline-block;

  border: none;
  cursor: pointer;
}

.Homepage-topnavbar-navbar-profile-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  background-color: #fff;
  border-radius: 50%;

  border:1px dashed  #495057;
  cursor: pointer;
}

/* navigation bar pool */

.Homepage-topnavbar-navbar-pool {
  display: flex;
  font-size: 1.4rem;
  padding-top: 7px;
  padding-bottom:7px;
  padding-left: 22px;
  padding-right: 22px;
  border-radius: 50px;
  cursor: pointer;
  background-color: #1d9bf0;
  color:#ffffff;

  -webkit-box-shadow: 0px 0px 14px 14px #f9f9f9;
  -moz-box-shadow: 0px 0px 14px 14px #f9f9f9;
   box-shadow: 0px 0px 14px 14px #f9f9f9;
}

/* navigation bar empty space */

.Homepage-topnavbar-navbar-navpanel-space {
  width: 40px;
}

.Homepage-topnavbar-space {
  width: 12px;
}


/* navigation bar pool on hover */

.Homepage-topnavbar-navbar-pool:hover, .Homepage-topnavbar-navbar-pool:active{
  padding-top: 7px;
  padding-bottom:7px;
  padding-left: 22px;
  padding-right: 22px;
  border-radius: 50px;
  outline: none;
  background-color:#1b8cfe;
  color:#ffffff;

  -webkit-box-shadow: 0px 0px 20px 20px #f9f9f9;
  -moz-box-shadow: 0px 0px 20px 20px #f9f9f9;
   box-shadow: 0px 0px 20px 20px #f9f9f9;
}

/* navbar-notification on hover */

.Homepage-topnavbar-navbar-notification:hover, .Homepage-topnavbar-navbar-notification:active {
  color:cornflowerblue;
}

/* navbar-profile on hover */

.Homepage-topnavbar-navbar-profile-outer:hover, .Homepage-topnavbar-navbar-profile--outer:active {
  background-color: #fff;
  border:2px dashed #495057;
}


.Homepage-topnavbar-mymenu {
  position: fixed;
  top:90px;
  cursor: pointer;
  z-index:2;
  border: 1px solid blue;
}

.Homepage-topnavbar-mymenu:hover {
  color:red;
}

.Homepage-topnavbar-searchbar {
  position: fixed;
  left: 0;
  top: 60px;
  background-color: #fff;
  min-height: 100%;
}

.Homepage-topnavbar-search-btn {
  display: none;
}

.Homepage-topnavbar-pool-btn {
  display: none;
}

.Homepage-topnavbar-navbar-num {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  position: absolute;
  top:10px;
  margin-left:16px;
  height: 25px;
  width: 25px;
  border-radius: 50px;
  color:#fff;
  background-color: tomato;
  border: 1px solid tomato;
}

@media (max-width: 780px) {

  /* main navigation-bar container */

  .Homepage-topnavbar-search-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    color:#808080;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .Homepage-topnavbar-pool-btn {
    display: none;
  }

  .Homepage-topnavbar-search-btn:hover {
    color:#1d9bf0;
  }

  /* logo container */

  .Homepage-topnavbar-navbar-logo {
    display:flex;
    align-items: center;
    justify-content:center;
    cursor: pointer;
    color:#495057;
    text-decoration: none;
    padding: 4px;
  }

  .Homepage-topnavbar-navbar-pool {
    display: flex;
    font-size: 1.2rem;
    padding-top: 6px;
    padding-bottom:6px;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 50px;
    cursor: pointer;
    background-color: #1d9bf0;
    color:#ffffff;

    -webkit-box-shadow: 0px 0px 8px 8px #f9f9f9;
    -moz-box-shadow: 0px 0px 8px 8px #f9f9f9;
     box-shadow: 0px 0px 8px 8px #f9f9f9;
  }

  .Homepage-topnavbar-navbar-pool:hover {
    display: flex;
    font-size: 1.2rem;
    padding-top: 6px;
    padding-bottom:6px;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 50px;
    cursor: pointer;
    background-color: #1d9bf0;
    color:#ffffff;

    -webkit-box-shadow: 0px 0px 14px 14px #f9f9f9;
    -moz-box-shadow: 0px 0px 14px 14px #f9f9f9;
     box-shadow: 0px 0px 14px 14px #f9f9f9;
  }

  .Homepage-topnavbar-navbar-navpanel-space {
    width: 15px;
  }


  .Homepage-topnavbar-navbar-notification {
    display: flex;
    margin-top: 6px;
    font-size: 2rem;
    justify-content: center;
    color:#495057;
    cursor: pointer;
    border: 2px solid #ffffff;
    border-radius: 50%;
  }

  .Homepage-topnavbar-navbar-notification:hover, .Homepage-topnavbar-navbar-notification:active {
     color:cornflowerblue;
  }

  .Homepage-topnavbar-navbar-profile {
    height: 1.875rem;
    width: 1.875rem;
    background-color: #f2f4f7;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }

  .Homepage-topnavbar-navbar-navpanel {
    display: flex;
    flex-grow: 1;
    justify-content:center;
    align-items: center;
  }

  .Homepage-topnavbar-wrapper-searchbar {
    display: none;
  }

  .Homepage-topnavbar-searchbar {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #fff;
  }

}

@media (max-width: 450px) {

  .Homepage-topnavbar-navbar-navpanel-space {
    width: 4px;
  }

  .Homepage-topnavbar-img {
    width: 30%;
  }

  .Homepage-topnavbar-space {
    width: 6px;
  }

  .Homepage-topnavbar-search-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    color:#808080;
    color:#495057;
    font-size: 1.6rem;
    cursor: pointer;
  }

  .Homepage-topnavbar-navbar-notification {
    display: flex;
    margin-top: 6px;
    font-size: 1.6rem;
    justify-content: center;
    color:#495057;
    cursor: pointer;
  }

  .Homepage-topnavbar-navbar-pool {
    display: flex;
    display: none;
    font-size: 0.1rem;
    padding-top: 7px;
    padding-bottom:7px;
    padding-left: 22px;
    padding-right: 22px;
    border-radius: 50px;
    cursor: pointer;
    background-color: #1d9bf0;
    color:#ffffff;

    -webkit-box-shadow: 0px 0px 14px 14px #f9f9f9;
    -moz-box-shadow: 0px 0px 14px 14px #f9f9f9;
     box-shadow: 0px 0px 14px 14px #f9f9f9;
  }

  .Homepage-topnavbar-pool-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    color:#495057;
    font-size: 1.6rem;
    cursor: pointer;
  }

  .Homepage-topnavbar-pool-btn:hover {
   color:cornflowerblue;
  }

  .Homepage-topnavbar-navbar-num {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    position: absolute;
    top:10px;
    margin-left:12px;
    height: 24px;
    width: 24px;
    border-radius: 50px;
    color:#fff;
    background-color: tomato;
    border: 1px solid tomato;
 }

}
