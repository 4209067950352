.Pbimg-wrapper {
  padding: 20px;
}

.Pbimg-c5 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0095c7;
  border: 10px solid #0095c7;
  border-radius: 10px;
  width: 10px;
  height: 10px;
}

.Pbimg-c4 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4dabd4;
  border:30px solid #4dabd4;
  border-radius: 30px;
  width: 30px;
  height: 30px;
}

.Pbimg-c3 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9bcee6;
  border: 70px solid #9bcee6;
  border-radius: 70px;
  width: 60px;
  height: 60px;
}

.Pbimg-c2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3f1f8;
  border: 100px solid #e3f1f8;
  border-radius: 100px;
  width: 80px;
  height:80px;
}

.topic {
  font-size: 1.6rem;
  color:#404040;
  transform: rotate(-10deg);
  text-shadow: 1px 1px 2px white, 0 0 1em white;
}

.topica {
  font-size: 1.8rem;
  color:#404040;
  transform: rotate(-10deg);
  text-shadow: 1px 1px 2px white, 0 0 1em white;
}

.line {
  font-size: 2.4rem;
  color:#404040;
  transform: rotate(-2deg);
}
