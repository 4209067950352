
.Aboutprioryou-wrapper {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 padding: 10px;
}

.Aboutprioryou-header {
  display: flex;
  width:100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color:whitesmoke;
}

.Aboutprioryou-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Aboutprioryou-text {
  font-size: 18px;
  cursor: pointer;
}

.Aboutprioryou-text:hover {
  color:cornflowerblue;
}

.Aboutprioryou-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 80%;
  font-size: 20px;
  word-break: break-all;
  padding: 10px;
  font-weight: bold;
  color:'#404040';
}

.Aboutprioryou-content-txt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  font-size: 20px;
  padding: 20px;
}

.Aboutprioryou-content-line {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding:10px;
  font-weight: bold;
  color:'#404040';
}



@media (max-width: 800px) {

  .Aboutprioryou-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    font-size: 20px;
    word-break: break-all;
    padding: 10px;
  }

  .Aboutprioryou-content-line {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    padding:10px;
    font-weight: bold;
    color:'#404040';
  }

  .Aboutprioryou-subtext {
    padding: 10px;
  }

  .Aboutprioryou-content-txt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-size: 18px;
    word-break: break-all;
    padding: 20px;
  }

  .Aboutprioryou-wrapper {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 0px;
  }
}

@media (max-width: 300px) {

  .Aboutprioryou-content-line {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    padding:10px;
    font-weight: bold;
    color:'#404040';
  }


  .Aboutprioryou-content-txt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    word-break: break-all;
    padding: 20px;
  }

  .Aboutprioryou-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    word-break: break-all;
    padding: 10px;
    font-weight: bold;
    color:'#404040';
  }

}
