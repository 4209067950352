* {
  box-sizing: border-box;
}

.Team-header-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left:20px;
  padding-top: 10px;
  color:black;
  width: 100%;
  font-size: 1.6rem;
  text-transform: capitalize;
}


.Textcont {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.4rem;
}

.Team-header {
  position: relative;
  cursor: pointer;
}

.Team-header::before {
  content: "";
  position: absolute;
  left: 7%;
  bottom: 1px;
  width: 100%;
  height: 8px;
  transform: skew(-12deg) translateX(0px);
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  z-index: -1;
}

.Team-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  top:250px;
  left:20vw;
  width:59vw;
  background-color: #fff;
  z-index: 99;
}

.Team-list-title {
  display: flex;
  min-width: 200px;
  width: 40%;
  color: #484848;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  padding: 10px;
}

.Team-profile-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.Team-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.Team-profile-menu {
  position: relative;
  top:-18px;
  display: flex;
  justify-content: flex-end;
  align-items:center;
  width: 100%;
}

.Team-profile-submenu-item {
  font-size:1rem;
  padding: 8px;
  border-radius: 6px;
}

.Team-profile-submenu-item-dis {
  font-size:0.9rem;
  padding: 6px;
  border-radius: 6px;
}

.Team-profile-submenu-item:hover {
  background-color:#f9f9f9;
}

.Team-profile-menu-dis {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items:center;
  width: 100%;
  visibility: hidden;
}

.Team-profile-outline {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 200px;
  max-width: 600px;
  height: 200px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #404040;
}

.Team-profile-submenu {
  position: absolute;
  left:160px;
  top:0px;
  padding: 8px;
  width: 100%;
  border-radius: 10px;
  background-color: #ffff;
  border: 1px solid lightgrey;
  cursor: pointer;
}

.Team-profile-submenu-dis {
  position: absolute;
  left:160px;
  top:0px;
  padding: 8px;
  width: 100%;
  border-radius: 10px;
  background-color: #ffff;
  border: 1px solid lightgrey;
  visibility: hidden;
}

.Team-menu-dot {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
  margin-top: 12px;
}

.Team-menu-dot-dis {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  visibility: hidden;
}

.Team-menu-dot:hover {
  color:cornflowerblue;
}

.Team-profile-circle {
  display: flex;
  border-radius: 50px;
  width: 80px;
  height: 80px;
}

.Team-profile-name {
  display: flex;
  width: 200px;
  justify-content: center;
  align-items: center;
  color: #484848;
  padding: 8px;
  word-break: break-all;

}

.Team-super-user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color:#404040;
  font-size: 0.8rem;
}

.Team-super-user-dis {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color:#ffff;
  visibility:hidden;
  font-size: 0.8rem;
}


.Team-empspace {
   display: flex;
   width: 10px;
}

.Team-title-img {
  max-width: 40%
}

.Team-list-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: black;
  align-items: center;
  font-size: 1.8rem;
  padding: 10px;
  background-color: #fff;

  text-transform: capitalize;
  background: repeating-linear-gradient(white, black 2px, black 2px, black 3px);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


@media (max-width: 780px) {

  .Team-wrapper {
    display: block;
    position: relative;
    top:294px;
    left:0px;
    width: 100%;
    background-color: #fff;
  }

  .Team-profile-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .Team-profile-submenu {
    position: absolute;
    left:80px;
    top:0px;
    padding: 8px;
    width: 70%;
    border-radius: 10px;
    background-color: #ffff;
    border: 1px solid lightgrey;
    cursor: pointer;
  }

  .Team-profile-submenu-item {
    font-size:0.9rem;
    padding: 8px;
    border-radius: 6px;
  }

  .Team-list-title {
    display: flex;
    min-width: 200px;
    width: 100%;
    color: #484848;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    padding: 10px;
  }

  .Team-title-img {
    max-width: 60%
  }

  .Textcont {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1.4rem;
  }

}
