.Poolcommentbox-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
}

.Poolcommentbox-profile-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Poolcommentbox-profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width:30px;
  height:30px;
  font-size: 0.8rem;
  border: 1px solid #b8b8b9;
}

.Poolcommentbox-txt-wrapper {
  display: flex;
  width: 55%;
  justify-content: center;
  align-items: center;
}


.Poolcommentbox-formcontrol {
  display:block;
  word-wrap: break-word;
  padding: 0.5rem;
  border: 1px solid #b8b8b9;
  color:black;
  border-radius: 20px;
  background-color: #ffffff;
  font-size: 0.9rem;
  outline:  none;
  width:350px;
  cursor: text;
}

.Poolcommentbox-formcontrol:focus {
  border:1px solid #bfdeff;
  background-color: #ffffff;
}

.Poolcommentbox-formcontrol:empty:before {
    color:darkgray;
    content: attr(data-placeholder);
}

.Poolcommentbox-post-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Poolcommentbox-postbtn-icn {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  color:#3390ec;
  cursor: pointer;
}

.Poolcommentbox-postbtn-icn-disable {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  color: #b8b8b9;
}

.Poolcommentbox-cancelbtn-icn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  color:#3390ec;
  cursor: pointer;
}

.Poolcommentbox-cancelbtn-icn-disable {
  display: flex;
  font-size: 0.9rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.Poolcommentbox-emptyspace {
  display: flex;
  width: 8px;
}

.Poolcommentbox-emptyspace-post {
  display: flex;
  width: 18px;
}

.Poolcommentbox-img {
  max-width: 100%;
  height: auto;
  border-radius: 50px;
}
