.Popupmenu-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top:0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  padding:20px;
  background-color: rgba(0, 0, 0, 0.2);
}

.Popupmenu-container {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #ffff;
  min-height: 140px;
  border-radius: 20px;
  border: 1px solid lightgray;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.Popupmenu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  cursor: pointer;
  border-bottom:1px solid lightgray;
}

.Popupmenu-menu-o {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Popupmenu-menu-opt {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 1.6rem;
  cursor: pointer;
}

.Popupmenu-menu-opt-dis {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 1.6rem;
  color:#ffff;
  visibility: hidden;
}

.Popupmenu-menu {
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding: 4px;
  font-weight:bold;
  width: 100%;
}

@media (max-width: 780px) {

  .Popupmenu-item {

  }

}
