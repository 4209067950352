.Login-container-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.Login-container {
  display: flex;
  flex-direction: column;
  margin-top: 8vh;
  width: 298px;
  height: 419px;
  border-radius: 4px;
}

.Login-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 0.6;
  padding-right: 14px;
  padding-left: 14px;
  font-size: 1.6rem;
  text-decoration: none;
  color:#000000;
}

.Login-logo {
  color:#fe2c55;
}

.Login-main-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 2.4;
  justify-content: space-around;
  padding-top: 0px;
  padding-right: 14px;
  padding-bottom: 14px;
  padding-left: 14px;
}

.Login-content {
  display: flex;
  flex-direction: column;
}

.Login-main {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  font-size: 1.4rem;
  font-weight: 401;
  color:#484848;
  color: #808080;
}

.Login-email {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Login-formcontrol {
  display: flex;
  width: 100%;
  padding: 0.7125rem 1rem;
  border: 2px solid #ffffff;
  border-radius: 6px;
  background-color: #f9f9f9;
  font-size: 1rem;

  -webkit-appearance: none;
  -moz-appearance: none;
   appearance: none;
}

.Login-password-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 2px;
}

.Login-password-show {
  position: absolute;
  right:10px;
  border: none;
  outline: none;
  background-color: #f9f9f9;
  font-size: 1rem;
  cursor: pointer;
  color: #808080;
  padding: 0;
}

.Login-forget-password {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 4px;
  padding-bottom: 30px;
}

.Login-forget-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color:#484848;
  border: none;
  border-radius: 6rem;
  background-color:#ffffff;
  font-size: 0.9rem;
}

.Login-footer {
  display: flex;
  flex-direction: column;
}

.Login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  cursor: pointer;
  color:#ffffff;
  border: none;
  border-radius: 6rem;
  background-color: #4582ec;
  font-size: 1rem;
}

.Login-separator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #979797;
  padding: 6px;
}

.Login-google-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color:#808080;
  background-color:#ffffff;
  border: 1px solid #eceff1;
  border-radius: 6rem;
  font-size: 1rem;
  cursor: pointer;
  padding: 12px;
}

.Login-formcontrol:focus {

  border:2px solid #bfdeff;
  background-color: #ffffff;

  -webkit-appearance: none;
  -moz-appearance: none;
   appearance: none;
}

input:focus ~ .Login-password-show {
  background: #ffffff;
}

button:focus {
  outline: none;
}

.Login-button:hover {
  background-color: #226be8;
}

.Login-google-button:hover {
  background-color: #f2f4f7;
  color:#484848;
}

.Login-password-show:hover {
  background-color: #f9f9f9;
  color:#4582ec;
}

.Login-forget-button:hover {
  background-color: #ffffff;
  color:#4582ec;
}

.Login-separator {
  display: flex;
  padding-left: 14px;
  padding-right: 14px;
}

.Login-separator-line {
  border-bottom: 1px solid #e9ecef;
  width: 100%;
}

.LoginemailFlag {
  padding-top: 10px;
}

.Login-err-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  padding: 4px;
}

.Login-title-err {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: #ff6e78;
  background-color: #ffff;
  color:white;
  color:red;
  border-radius: 10px;
  font-size: 1rem;
}

.Login-title-word {
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  text-align: center;
}
