.Fpassword-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  padding:4px;
  -webkit-backdrop-filter: blur(10px);
   backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.4);
}

.Fpassword-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid darkgrey;
}

.Fpassword-container {
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  align-items:center;
  max-width: 400px;
}

.Fpassword-header {
  display: flex;
  justify-content: space-between;
  align-items:center;
  width: 100%;
  padding: 10px;
  border-bottom:1px solid lightgrey;
}

.Fpassword-header-center {
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 1.2rem;
   padding: 4px;
   font-weight:500;
}

.Fpassword-header-right {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: 4px;
  font-size: 1.4rem;
  color:#fff;
}

.Fpassword-header-left {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  padding: 4px;
  font-size: 1.4rem;
}

.Fpassword-close {
  border-radius: 50px;
  cursor: pointer;
}

.Fpassword-header-left:hover {
  background-color: #f5f1fe;
}

.Fpassword-option-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Fpassword-body {
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.Fpassword-body-up {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Fpassword-body-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  word-wrap: break-word;
  overflow: hidden;
  width: 100%;
  font-size: 1rem;
}

.Fpassword-body-text-txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Fpassword-body-down {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.Fpassword-option-error {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2px;
  width: 100%;
  color:red;
}

.Fpassword-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4px;
  font-size: 1.2rem;
  font-weight: bold;
}

.Fpassword-input {
    display: flex;
    width: 100%;
    font-size: 1.2rem;
    border: none;
    outline: none;
}

.Fpassword-input:focus {
  outline: none;
  border: none;
}

.Fpassword-confirm-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 14px;
  cursor: pointer;
  color:#ffffff;
  border: none;
  border-radius: 6rem;
  background-color: #4582ec;
  font-size: 1.1rem;
  cursor: pointer;
}

.Fpassword-resend {
  color:#226be8;
  font-size: 1rem;
  cursor: pointer;
}

.Fpassword-resend-dis {
  color:#404040;
  font-size: 1rem;
}

.Fpassword-confirm-btn:hover {
  background-color: #226be8;
}

.Fpassword-screen-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0.6rem;
  font-size: 1.6rem;
  color:#404040;
}

.Fpassword-error {
    color:red;
}

.Fpassword-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.Fpassword-option {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #404040;
}

.Fpassword-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#404040;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px;
  width: 100px;
  border-radius: 50px;
  color:#ffff;
  background-color: cornflowerblue;
}


.Fpassword-btn-dis {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#404040;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 4px;
  width: 100px;
  border-radius: 50px;
  background-color: #ffff;
}



.Fpassword-option-input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid darkgrey;
    border-radius: 10px;
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    cursor: text;
}


.Fpassword-list {
  height: 200px;
  width: 100%;
  overflow-y:scroll;
  overflow-x: hidden;
}

.Fpassword-list-wrapper {
  display: flex;
  position: absolute;
  justify-content:flex-start;
  flex-direction: column;
  align-items:flex-start;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid lightgray;
  background-color: #fff;
  font-size: 1rem;
  color:#404040;
  top:400px;
  width:400px;
  height: 240px;
  z-index: 99;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

}

.Fpassword-next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 50px;
  color:#404040;
  border: 1px solid #404040;
  cursor: pointer;
  background-color:#f9f9f9;
}

.Fpassword-next:hover {
  color:black;
}

.Fpassword-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
