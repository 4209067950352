.alert-wrapper {
 position: absolute;
 top:100px;
 left:0;
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 padding: 10px;
}

.alert-contains-icons {
  font-size: 2.4rem;
  color:#ffff;
}

.alert-contains-icons-close {
  font-size: 2.4rem;
  color:#ffff;
  cursor: pointer;
}

.alert-contains-icons-close:hover {
  color:lightgray;
}


.alert-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
  padding: 20px;
  z-index: 1;
  background-color: #ffff;
  border-radius: 10px;
  background:linear-gradient(to bottom right,#FF2A68,#FF5E3A);
  box-shadow:0 2px 4px rgba(0,0,0,.5);
}

.alert-contains {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 1.4rem;
  color:#ffff;
  padding: 10px;
}

.alert-contains-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 780px) {

  .alert-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 1;
    background-color: #ffff;
    border-radius: 10px;
    background:linear-gradient(to bottom right,#FF2A68,#FF5E3A);
    box-shadow:0 2px 4px rgba(0,0,0,.5);
  }

  .alert-contains {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-size: 1.2rem;
    color:#ffff;
  }

  .alert-contains-icons-close {
    font-size: 2.2rem;
    color:#ffff;
    cursor: pointer;
  }


}
