.main-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  margin-top: 160px;
}

.friend-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  padding: 4px;
  width: 100%;
}

.friend-wrapper {
  display:flex;
  justify-content:center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 4px;
}

.friend-proflie-wrapper {
  display: flex;
  justify-content:center;
  align-items: center;
  padding: 4px;
}

.friend-container-topic {
  font-size: 1.2rem;
}

.friend-start {
  display:flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height:100%;
}

.friend-btn {
  cursor: pointer;
  font-size: 1.2rem;
  padding: 14px;
  background-color: #90ee90;
  border-radius: 40px;
  border: 1px solid #404040;
}

.freindlst {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.freind-img {
  max-width:30%;
}

@media (max-width: 780px) {

.friend-btn {
   cursor: pointer;
   font-size: 1rem;
   padding: 14px;
   background-color: #90ee90;
   border-radius: 40px;
   border: 1px solid #404040;
 }

}
