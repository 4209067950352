.Searchpage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  width: 100%;
  z-index: 9;
}

.Searchpage-poolcontainer {
  position:absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top:0;
  background-color: #fff;
}


@media (max-width: 780px) {

  .Searchpage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 74px;
    width: 100%;
    z-index: 9;
  }



}
