.Postsearch-wrapper {
  position: absolute;
  top:250px;
  left:20.2vw;
  height:600px;
  width: 70%;
  background-color: #fff;
}


.Postsearch-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 14px;
  width: 100%;
  background-color: #fff;
}


.Postsearch-listwrapper {
  display: flex;
  align-items:center;
  flex-direction: column;
  padding:10px;
  width: 60%;
  height: auto;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid lightgray;
}

.Postsearch-listwrapper img {
  border-radius: 10px;
}

.Postsearch-listwrapper:hover {
  cursor: pointer;
  background-color:#fcfcfc;
}


.Postsearch-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
  width: 70%;
  border-bottom:1px solid #f2f2f2;
}

.Postsearch-titlearrow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
  border-radius: 50px;
  cursor: pointer;
}

.Postsearch-titlearrow:hover {
  background-color: #f5f1fe;
}

.Postsearch-titlearrow-tile {
  padding: 2px;
  font-size: 1.2rem;
  font-weight: 400;
  color:#404040;
}

.Postsearch-spinner {
  display:flex;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.Postsearch-replybox {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  width: 70%;
}

@media (max-width: 780px) {

  .Postsearch-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    top:140px;
    left:0px;
    height:600px;
    width: 100%;
    background-color: #fff;
  }

  .Postsearch-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px;
    width: 100%;
    border-bottom:1px solid #f2f2f2;
  }

  .Postsearch-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 14px;
    width: 100%;
    background-color: #fff;
  }

  .Postsearch-listwrapper {
    display: flex;
    align-items:center;
    flex-direction: column;
    padding:10px;
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid lightgray;
  }

}
