.Poolreplylist-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

.Poolreplylist-details {
  display: flex;
  width: 100%;
  padding: 4px;
}

.Poolreplylist-profile-wrapper {
  display: flex;
  justify-content: center;
}

.Poolreplylist-profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width:26px;
  height:26px;
  border: 1px solid #b8b8b9;
  cursor: pointer;
}

.Poolreplylist-profile-ept {
  display: flex;
  width:40px;
}

.Poolreplylist-cmt {
  display: flex;
  flex-direction: column;
  width: 76%;
  color:black;
}

.Poolreplylist-cmt-name {
  display: flex;
  justify-content: flex-start;
  font-size: 0.8rem;
  color:black;
}

.Poolreplylist-cmt-comment {
  overflow-wrap: break-word;
  width: 100%;
  max-width:500px;
  font-size: 0.9rem;
}

.Poolreplylist-panel {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
  color:black;
}

.Poolreplylist-img {
  max-width: 100%;
  height: auto;
  border-radius: 50px;
}

.Poolreplylist-emptyspace {
  display: flex;
  width: 14px;
}

.Poolreplylist-panel-btn {
  display: flex;
  padding-top:10px;
  justify-content: center;
  align-items: center;
}

.Poolreplylist-emptycml {
  display: flex;
  height: 6px;
}

.Poolreplylist-edit {
  display: flex;
  justify-content: center;
  align-items: baseline;
  cursor: pointer;
  font-size: 0.9rem;
}

.Poolreplylist-up {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
}

.Poolreplylist-liekcount {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
}

.Poolreplylist-panel-cmt {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Poolreplylist-cmt-replyto {
  color:#226be8;
  cursor: pointer;
}

.Poolreplylist-cmt-reply {
  cursor: text;
}

.Poolreplylist-del {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  color:black;
  width: 100%;
  height: 100%;
  top:0px;
  left: 0px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
}

.Poolreplylist-cmt-time {
  color:#737373;
}

.Poolreplylist-spinner {
  margin-left: 60px;
  display: flex;
  justify-content:flex-start;
  align-items: center;
}

.Poolreplylist-cmt-txt {
  cursor: pointer;
}
