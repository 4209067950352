* {
  box-sizing: border-box;
  margin: 0px;
}

.Homepagemaincontainer-postform::-webkit-scrollbar {
    width: .0em;
}

.Homepagemaincontainer-postform::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.Homepagemaincontainer-postform::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.Homepagemainwrapper {
  position: absolute;
  top:-90px;
  left:0px;
  display: flex;
  width: 100%;
  height: 100vh;
  z-index:11;
  background-color: #fff;
}

.Homepagemain-leftnav {
  display: flex;
  flex-direction: column;
  min-width: 20vw;
  width: 20vw;
  height: 100vh;
}

.leftnav {
  display: flex;
  position: fixed;
  flex-direction: column;
  border-right:1px solid #f2f4f7;
  width: 20vw;
  height: 100vh;
  top:70px;
  padding-top: 4px;
  background-color: #fff;
}

.linkbtn {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color:#495057;
  cursor: pointer;
  width: 100%;
  padding: 6px;
  height:3.6rem;
  font-size: 1.4rem;
}

.Homepagemain-btn {
  display: inline-flex;
  align-items: center;
  margin-left: 2vw;
  font-size: 1.3rem;
}

.linkbtn:hover {
   background-color: #f2f4f7;
   border-radius: 50px;
   color:#495057;
 }

.activebtn {
  display: flex;
  color:#ff6347;
  font-size:2rem;
  width: auto;
 }

 .homebtn {
   display: flex;
   color:#857aff;
   font-size:2.6rem;
   width: auto;
 }

.timelinebtn {
  display: flex;
  align-items: center;
  justify-content: center;
  color:#479cf8;
  font-size:2rem;
}

.callbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  color:tomato;
  font-size:2rem;
}

.teambtn {
  display: flex;
  align-items: center;
  justify-content: center;
  color:#479cf8;
  font-size:2rem;
}

.resourcesbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  color:#ffbf01;
  font-size:2rem;
}

.smarttagbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  color:cornflowerblue;
  font-size:2rem;
}



.Homepage-center {
  display: flex;
  flex-direction: column;
  justify-content:center;
  max-width: 800px;
  background-color: #fff;
}

.pagelist {
  display: flex;
  flex-direction: column;
  margin-top: 130px;
}

.Homepage-right {
  display: flex;
  flex-grow: 1;
}

.leftnav-hgt {
  height: 15px;
}

.Homepage-edt {
  min-height: 100px;
  max-height: 120px;
  border-radius: 10px;
  width: 100%;
  word-wrap: break-word;
  border: 1px solid darkgray;
  padding: 4px;
  overflow-x : hidden;
}

.Homepage-editwrapper {
  display: flex;
  position: fixed;
  top:100px;
  justify-content: center;
  align-items: center;
  width: 45vw;
  background-color: #fff;
}

.leftnav1 {
  display: none;
}

.lc {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color:#495057;
  padding: 0.8vw;
}

@media(max-width: 880px) {

  .linkbtn {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color:#495057;
    cursor: pointer;
    width: 100%;
    padding: 6px;
    height:3.6rem;
    font-size: 1.1rem;
  }

}

@media (max-width: 780px) {



  .Homepagemainwrapper {
    position: static;
    top:0px;
    left:0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    z-index:11;
    background-color: #fff;
  }

  .Homepagemain-leftnav {
    display: flex;
    width: 100%;
    flex-direction:row;
    height: 0px;
  }

  .Homepage-editwrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    top:150px;
  }

  .Homepage-edt {
    min-height: 100px;
    max-height: 120px;
    width: 100%;
    word-wrap: break-word;
    border: 1px solid darkgray;
    padding: 4px;
    border-radius: 10px;
    overflow-x : hidden;
  }

  .leftnav {
    display: none;
  }

   .leftnav1 {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     flex-direction: row;
     position: fixed;
     top:64px;
     background-color: #fff;
     border-bottom: 1px solid #f2f4f7;
     z-index: 9;
     padding: 2px;
     height: 80px;
   }

   .linkbtn {
     display: inline-flex;
     flex-direction:column;
     justify-content: center;
     align-items: center;
     font-weight:400;
     color:#495057;
     border-radius: 10px;
   }

   .linkbtn:hover {
      background-color: #f2f4f7;
      border-radius: 10px;
      color:#495057;
    }

   .Homepagemain-btn {
     display:flex;
     align-items: center;
     justify-content:center;
     flex-direction: column;
     padding: 14px;
   }

   .activebtn {
     display: flex;
     color:#ff6347;
     font-size:1.8rem;
     width: auto;
    }

    .homebtn {
      display: flex;
      color: cornflowerblue;
      font-size:1.8rem;
      width: auto;
    }

   .timelinebtn {
     display: flex;
     align-items: center;
     justify-content: center;
     color:#479cf8;
     font-size:1.8rem;
   }

   .callbtn {
     display: flex;
     align-items: center;
     justify-content: center;
     color:tomato;
     font-size:1.8rem;
   }

   .teambtn {
     display: flex;
     align-items: center;
     justify-content: center;
     color:#479cf8;
     font-size:1.8rem;
   }

   .resourcesbtn {
     display: flex;
     align-items: center;
     justify-content: center;
     color:#ffbf01;
     font-size:1.8rem;
   }

   .smarttagbtn {
      display: flex;
      align-items: center;
      justify-content: center;
      color:cornflowerblue;
      font-size:1.8rem;
   }

}

@media(max-width: 600px) {

  .Homepagemain-btn {
    display:flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    padding: 4px;
  }

}

@media(max-width: 490px) {

  .Homepagemain-btn {
    display:flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    padding: 2px;
  }

}

@media(max-width: 450px) {

  .Homepagemain-btn {
    display:flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    padding: 0px;
  }

}


.Homepagemaincontainer-container {
  display: flex;
  margin-top: 64px;
  width: 100%;
  height: 100%;
}

.Homepagemaincontainer-left-navigation {
  display: flex;
  flex-direction: column;
  position: fixed;
  top:70px;
  width: 20vw;
  height: 100vh;
  border-right:1px solid #f2f4f7;
}

.Homepagemaincontainer-leftnavbtn {
  display: inline-flex;
  justify-content:space-between;
}

.Homepagemaincontainer-navigation-button {
  display: inline-flex;
  align-items: center;
  margin-left: 2vw;
  font-size: 1.4rem;
}

.Homepagemaincontainer-activebtn {
  display: flex;
  color:#ff6347;
  font-size:2rem;
  width: auto;
}

.Homepagemaincontainer-activebtntxt {
  display: flex;
  align-items: center;
  justify-content: center;
  color:#495057;
  width: auto;
}

.Homepagemaincontainer-timelinebtn {
  display: flex;
  align-items: center;
  justify-content: center;
  color:#479cf8;
  font-size:2rem;
}

.Homepagemaincontainer-timelinebtntxt {
  display: flex;
  align-items: center;
  justify-content: center;
  color:#495057;
}

.Homepagemaincontainer-callbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  color:#5eee52;
  font-size:2rem;
}

.Homepagemaincontainer-callbtntxt {
  display: flex;
  align-items: center;
  justify-content: center;
  color:#495057;
}

.Homepagemaincontainer-teambtn {
  display: flex;
  align-items: center;
  justify-content: center;
  color:#479cf8;
  font-size:2rem;
}

.Homepagemaincontainer-teambtntxt {
  display: flex;
  align-items: center;
  justify-content: center;
  color:#495057;
}

.Homepagemaincontainer-resourcesbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  color:#ffbf01;
  font-size:2rem;
}

.Homepagemaincontainer-resourcesbtntxt {
  display: flex;
  align-items: center;
  justify-content: center;
  color:#495057;
}

.Homepagemaincontainer-message-input-wrapper {
  display:flex;
  flex-direction: column;
  align-self: flex-start;
  flex-grow: 1;
}

.Homepagemaincontainer-navigation-button:hover {
   background-color: #f2f4f7;
   border-radius: 50px;
 }

.Homepagemaincontainer-wrapper-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-grow: 2.5;
}

.Homepagemaincontainer-postbox {
  display: flex;
  width: 50vw;
  height: auto;
  border: 2px solid #f2f4f7;
  border-radius: 0.75rem;
  background-color: #ffffff;
  cursor: pointer;
}

.Homepagemaincontainer-postform {
  display: block;
  padding: .40rem;
  word-wrap: break-word;
  cursor: pointer;
  max-width: 43vw;
  max-height: 180px;
  border: none;
  outline: none;
  overflow: auto;
}

.Homepagemaincontainer-postform  img {
    max-width: 20%;
    max-height: 20%;
}

.Homepagemaincontainer-postform:empty:before {
  content: attr(data-placeholder);
  color:darkgray;
}

.Homepagemaincontainer-emoji-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color:darkgrey;
}

.Homepagemaincontainer-emojibtn {
  border: none;
  cursor: pointer;
  color:darkgrey;
  background-color: #ffffff;
  font-size: 1.4rem;
}

.Homepagemaincontainer-image-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color:darkgrey;
  cursor: pointer;
}

.Homepagemaincontainer-image-button:hover {
  color:cornflowerblue;
}

.Homepagemaincontainer-imgbtn {
  border: none;
  cursor: pointer;
  color:darkgrey;
  background-color: #ffffff;
  font-size: 1.4rem;
}

.Homepagemaincontainer-emojibtn:hover {
   color:cornflowerblue;
 }

.Homepagemaincontainer-imgbtn:hover {
   color:cornflowerblue;
 }

.Homepagemaincontainer-post-button {
  display: flex;
  height: 30px;
}

.Homepagemaincontainer-btnpost {
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 2.4rem;
  color:#60acfd;
}

.Homepagemaincontainer-btncancel {
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size:1rem;
  color:#60acfd;
}

.Homepagemaincontainer-postlist {
  margin-left: 40px;
  width: 50vw;
  height: auto;
}

.Homepagemaincontainer-post {
  display: inline-flex;
  background-color: #f4f7fa;
  border: 1px solid #efeff1;
  width: auto;
  border-radius: 10px;
  padding: 14px;
}

.Homepagemaincontainer-smallspace {
  display: flex;
  flex-grow: 1.6;
  position:fixed;
  top:90px;
  height: 100vh;
  left: 78vw;
  width:19vw;
  justify-content: space-around;
}

.Homepagemaincontainer-postbox:focus-within {
  border: 2px solid #bfdeff;
  border: 2px solid #fff;
  border-radius: 0.75rem;;
  background-color: #ffffff;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.lc {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color:#495057;
  padding: 0.8vw;
}
