.sharewrapper {
  position:fixed;
  height: 100vh;
  width: 100vw;
  top:0;
  left:0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.share-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: auto;
  padding-bottom:20px;
  padding-top:0px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.share-canel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.share-input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.share-formcontrol {
  display: flex;
  width: 100%;
  padding:1rem;
  border: 2px solid #ffffff;
  border-radius: 6px;
  background-color: #f9f9f9;
  font-size: 1rem;
  width: 80%;
  border: 2px solid #404040;
  -webkit-appearance: none;
  -moz-appearance: none;
   appearance: none;
}

.share-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left:30px;
  padding-right:30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid black;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  color:#404040;
  background-color: lightgreen;
}

.share-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.share-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-top: -20px;
  width: 100%;
}



.share-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%
}

.share-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  word-break:break-all;
}



.share-account-profile {
  height: 4rem;
  width: 4rem;
  background-color: #f2f4f7;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.share-edit {

  /* Remove First */
 -webkit-appearance: none;
 -moz-appearance: none;
  appearance: none;

  width: 100%;

  min-height: 17.5vh;

  background-color: #fff;
  font-size: 1rem;
  outline: none;
  resize: none;
  border: none;
  overflow: auto;
  cursor: default;
  border-radius: 0px;
  border: 1px solid #fff;
  border-bottom: 1px solid lightgrey;
  color:#464646;
  word-break: break-all;

  cursor: pointer;
}

.share-edit:empty:before {
    content: attr(placeholder);
    color:#757575;
}



.share-canel-ico {
  padding: 4px;
  font-size: 1.2rem;
  cursor: pointer;
  z-index:1;
}

.share-canel-ico:hover {
  color:red;
}

@media (max-width: 780px) {

  .share-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-bottom:20px;
    padding-top:0px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #ffff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }

}
