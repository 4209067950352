.Sterotype-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 6px;
}

.Sterotype-container {
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  width: 40%;
  min-height: 400px;
  height: 80%;
  border-radius: 20px;
  background-color: #ffff;
  border: 1px solid lightgray;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.Sterotype-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4px;
  font-size: 1.4rem;
}

.Sterotype-choice {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-size: 1.4rem;
  padding: 8px;
}

.Sterotype-clear {
  display: flex;
  justify-content: center;
  align-items: center;
  color:RED;
  cursor: pointer;
}

.Sterotype-clear-dis {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  visibility: none;
}

.Sterotype-inner-wrapper {
  display: flex;
  justify-content: center;
  align-items:center;
  flex-wrap: wrap;
  width: 100%;
}

.Sterotype-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.Sterotype-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 10px;
  background-color: #ffff;
  padding: 10px;
  border: 2px solid #404040;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.Sterotype-card-sel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 10px;
  background-color: #ffff;
  padding: 10px;
  border: 2px solid cornflowerblue;
  box-shadow: rgb(0,0,0,0.4) 0px 8px 24px;
}

.Sterotype-card-sel:focus {
    box-shadow: rgb(0,0,0,0.4) 0px 8px 24px;
}

.Sterotype-card:hover {
  color:#404040;
  box-shadow: rgb(0,0,0,0.4) 0px 8px 24px;
}


.Sterotype-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7vw;
  height: 7vw;
  border-radius: 50px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.Sterotype-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 4px;
  width: 100%;
}

.Sterotype-imgsrc {
  max-width: 100%;
  max-height: 100%;
}


.Welcome-container-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Welcome-container-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: bold;
}

.Welcome-container-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Welcome-container-getstarted {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  cursor: pointer;
  padding: 14px;
  border-radius: 10px;
  color:#ffff;
  background-color: #ff4645;
}




@media (max-width: 780px) {


  .Sterotype-container {
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 90%;
    border-radius: 20px;
    border: 1px solid lightgray;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  }

  .Sterotype-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12vw;
    height: 12vw;
    border-radius: 50px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .Sterotype-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size:1.4rem;
  }


}
