.Fpasswordcode-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  padding:4px;
}

.Otpscreen-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid darkgrey;
}

.Otpscreen-container {
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  align-items:center;
  max-width: 400px;
}

.Otpscreen-header {
  display: flex;
  justify-content: space-between;
  align-items:center;
  width: 100%;
  padding: 10px;
  border-bottom:1px solid lightgrey;
}

.Otpscreen-header-center {
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 1.2rem;
   padding: 4px;
   font-weight:500;
}

.Otpscreen-header-right {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: 4px;
  font-size: 1.4rem;
  color:#fff;
}

.Otpscreen-header-left {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  padding: 4px;
  font-size: 1.4rem;
}

.Otpscreen-close {
  border-radius: 50px;
  border: 1px solid red;
  cursor: pointer;
}

.Otpscreen-header-left:hover {
  background-color: #f5f1fe;
}

.Otpscreen-body {
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.Otpscreen-body-up {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Otpscreen-body-text {
  display: block;
  word-wrap: break-word;
  overflow: hidden;
  width: 100%;
  font-size: 1rem;
}

.Otpscreen-body-down {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.Otpscreen-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Otpscreen-input {
    display: flex;
    width: 100%;
    padding:1rem;
    border: 1px solid #808080;
    border-radius: 6px;
    font-size: 1.2rem;
    appearance: none;
    outline: none;
}

.Otpscreen-input:focus {
  outline: none;
  border: 1px solid #808080;
}

.Otp-confirm-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 14px;
  cursor: pointer;
  color:#ffffff;
  border: none;
  border-radius: 6rem;
  background-color: #4582ec;
  font-size: 1.1rem;
  cursor: pointer;
}

.Otp-resend {
  color:#226be8;
  font-size: 1rem;
  cursor: pointer;
}

.Otp-resend-dis {
  color:#404040;
  font-size: 1rem;
}

.Otp-confirm-btn:hover {
  background-color: #226be8;
}

.Otp-screen-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0.6rem;
  font-size: 1.6rem;
  color:#404040;
}

.Otpscreen-error {
    color:red;
}

.Otpscreen-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.Fpasswordcode-back {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 4px;
   width: 100%;
}

.Fpasswordcode-back-btn {
  font-size: 1.2rem;
  border-bottom-style: dashed;
  border-bottom-style: 1px solid dashed black;
  cursor: pointer;
}
