

.timepickerwrapper1 {
  display: flex;
  flex-direction: row;
  align-items:flex-start;
  flex-direction: column;
  height: 400px;
  width: 180px;
  border-radius: 10px;
  border: 1px solid lightgray;
  padding: 10px;
  background-color: #ffff;
}

.timpcancel {
  top:-16px;
  left:-6px;
  position: relative;
  cursor: pointer;
}

.rrow  {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.rrowr  {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid lightgray;
}

.opl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  padding: 10px;
}

.opla {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.eleek {
  display: flex;
  justify-content:center;
  align-items: center;
  padding: 10px;
  font-size: 1rem;
  width: 100%;
}

.elee {
  display: flex;
  justify-content:center;
  align-items: center;
  padding: 10px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
}

.elee:hover {
  border-radius: 10px;
  background-color: cornflowerblue;
  color:#ffff;
}

.eleea {
  display: flex;
  justify-content:center;
  align-items: center;
  padding: 10px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
}

.eleea:hover {
  background-color: cornflowerblue;
  color:#ffff;
}

.setbtn {
  display: flex;
  justify-content: center;
  align-items:center;
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid lightgray;
}

.emrow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}
