* {
  box-sizing: border-box;
}

.Addresources-modal {
  display: flex;
  justify-content:center;
  align-items: center;
  position: fixed;
  top:0;
  left:0;
  z-index:99;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.Addresources-modal_content {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 40%;
  height: auto;
  padding: 1.25rem;
  border-radius: 5px;
}

.Addresources-topspace {
  margin-top: 170px;
}

.Addresources-close {
  position: relative;
  top:-20px;
  left: 10px;
  color: Black;
  float: right;
  font-size: 2rem;
}

.Addresources-close:hover {
  color:#3390ec;
  cursor: pointer;
}

.Addresources-container-link {
  display: flex;
  flex-direction: column;
}

.Addresources-container-name {
  display: flex;
  flex-direction: column;
}

.Addresources-btn {
  padding: 10px;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #3390ec;
  color: #ffffff;
  font-size: 1rem;
}

.Addresources-btn:hover {
  background-color: #2e82d4;
  border: none;
  outline: none;
  cursor: pointer;
}

.Addresources-btn-dis {
  padding: 10px;
  border-radius: 20px;
  border: none;
  outline: none;
  background-color: lightgray;
  color: #ffffff;
  font-size: 1rem;
}


.Addresources-container-btn {
  display: flex;
  justify-content: flex-end;
}

.Addresources-formcontrol {
  display: flex;
  width: 100%;
  padding: 0.7125rem 1rem;
  border: 2px solid #ffffff;
  border-radius: 6px;
  background-color: #f9f9f9;
  font-size: 1rem;

  -webkit-appearance: none;
  -moz-appearance: none;
   appearance: none;
}

.Addresources-header {
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.Addresources-header-title {
  font-size: 1.2rem;
  font-weight: bold;
  color:cornflowerblue;
}

.Addresources-link {
  padding: 2px;
}


@media (max-width: 780px) {

  .Addresources-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    z-index:99;
    width: 100vw;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.25);
  }

  .Addresources-topspace {
    margin-top: 170px;
  }

  .Addresources-modal_content {
    background-color: white;
    width: 100%;
    height: auto;
    padding: 1.25rem;
    border-radius: 5px;
  }

}
