.Detailswrapper {
  position:absolute;
  top:70px;
  left:0px;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 1;
}

.Detailslink {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #ffff;
}

.Detailstitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.Detailstitlearrow {
  display: flex;
  align-items: center;
  font-size: 2rem;
  border-radius: 50px;
  cursor: pointer;
  color:#495057;
}

.Detailstitlearrow:hover {
  background-color: #f5f1fe;
}

.Detailstitletext {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  color:#495057;
}

.Detailsempspace {
  width: 100px;
}

.Detailsnavitem {
  display: flex;
  text-decoration: none;
  color:#495057;
  font-size: 1.2rem;
  border: 1px solid #ffffff;
}

.Detailsnavitemactive {
  display: flex;
  text-decoration: none;
  color:#495057;
  font-size: 1.2rem;
  border: 1px solid #ffffff;
  border-bottom: 1px solid #495057;
}

.Detailsnavitem:hover {
  border-bottom: 1px solid #495057;
}

.Detailsroute {
  width: 100%;
  background-color: #fff;
}


@media (max-width: 780px) {

  .Detailswrapper {
     position:absolute;
     top:14px;
     left:0px;
     display: flex;
     justify-content:flex-start;
     align-items: center;
     flex-direction: column;
     width: 100%;
     height: 100vh;
     background-color: #fff;
     z-index: 1;
 }

}


@media (max-width: 630px) {

  .Detailswrapper {
     position:absolute;
     top:24px;
     left:0px;
     display: flex;
     justify-content:flex-start;
     align-items: center;
     flex-direction: column;
     width: 100%;
     height: 100vh;
     background-color: #fff;
     z-index: 1;
 }

}

@media (max-width: 580px) {

  .Detailswrapper {
     position:absolute;
     top:40px;
     left:0px;
     display: flex;
     justify-content:flex-start;
     align-items: center;
     flex-direction: column;
     width: 100vw;
     height: 100vh;
     background-color: #fff;
     z-index: 1;
 }

}
