.followerscontainer {
  display: flex;
  justify-content:space-between;
  align-items: center;
  width: 40vw;
  border-radius: 10px;
  padding: 14px;
  border: 1px solid #E8EBEE;
  cursor: pointer;
  background-color: #fff;
}

.followerouter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.followerwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.followerhtspace {
  height:90px;
}

.followerscontainer:hover {
  background-color: #fafafa;
}

.followers-profileimg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  padding: 4px;
  color:darkgrey;
  font-size: 1.2rem;
  font-weight:bold;
}

.followersprfwrapper {
   display: flex;
   justify-content: center;
   align-items: center;
}

.followers-username {
   width: 120px;
   word-break: break-all;
}

.followers-btn {
  padding: 10px;
  border-radius: 20px;
  outline:1px solid #404040;
  cursor: pointer;
  font-size: 1rem;
}

.followers-btn:hover {
  background-color:#ff6347;
  outline:1px solid #fff;
  color:#fff;
}


@media (max-width: 760px) {

  .followerscontainer {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    width: 90vw;
    border-radius: 10px;
    padding: 14px;
    border: 1px solid darkgrey;
    cursor: pointer;
    background-color: #fff;
  }

  .followersprfwrapper {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
  }

  .followers-username {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     word-break: break-all;
  }



}
