.Request-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
}

.Request-header {
  display: flex;
  flex-wrap: wrap;
  word-break: break-all;
  justify-content:center;
  align-items: center;
  font-size:1.2rem;
  padding: 8px;
}

.Request-search {
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding: 8px;
  width:70vw;
  border-radius: 30px;
  border: 2px solid black;
}

.Request-bar {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  width: 100%;
  color: black;
  border: none;
  cursor: pointer;
  outline: none;
}

.Request-bar-input {
  width: 100%;
  font-size: 1.2rem;
  padding: 8px;
  color: black;
  border: none;
  cursor: pointer;
  outline: none;
}

.Request-bar-input:focus {
   border: none;
   outline: none;
}

.Request-search-icon {
  cursor: pointer;
}

.Request-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  padding:10px;
  border: 1px solid black;
  border-radius:10px
}

.Request-request-btn {
    padding:10px;
    border-radius: 20px;
    cursor: pointer;
    background-color: #ff6347;
    color: #fff;
    border: none;
    outline: none;
    font-size: 1rem;
}

.Request-detail-wrapper {
  display: flex;
  justify-content:center;
  align-items: center;
  cursor: pointer;
}


.Request-text-name-wrapper {
  display: flex;

  justify-content: center;
  align-items: center;
  color: #484848;
  padding: 8px;
}


.Request-text-name {
  display: flex;
  flex-wrap: wrap;
  width:200px;
  word-break: break-all;
  overflow: hidden;
}

.Request-searchbarContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 70vw;
    border-radius: 10px;
    border: 1px solid #f2f4f7;
    overflow: auto;
    background-color: #fff;
    box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.02), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
}


@media (max-width: 520px) {


  .Request-text-name {
    display: flex;
    flex-wrap: wrap;
    width:100px;
    word-break: break-all;
    overflow: hidden;
  }

}


@media (max-width: 330px) {


  .Request-text-name {
    display: flex;
    flex-wrap: wrap;
    width:70px;
    word-break: break-all;
    overflow: hidden;
  }

}
