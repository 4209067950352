.takeawaywrapper {
  position: absolute;
  height: 100vh;
  width: 100%;
  top:0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1a1a1a;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 15px;
  line-height: 1.6em;
  padding: 4px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.takeaway-canel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.takeaway-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100%;
}

.takeaway-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: auto;
  padding-bottom:20px;
  padding-top:0px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.takeaway-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%
}

.takeaway-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  word-break:break-all;
}

.takeaway-account-profile {
  height: 4rem;
  width: 4rem;
  background-color: #f2f4f7;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.takeaway-edit {

  /* Remove First */
 -webkit-appearance: none;
 -moz-appearance: none;
  appearance: none;

  width: 100%;

  min-height: 17.5vh;

  background-color: #fff;
  font-size: 1rem;
  outline: none;
  resize: none;
  border: none;
  overflow: auto;
  cursor: default;
  border-radius: 0px;
  border: 1px solid #fff;
  border-bottom: 1px solid lightgrey;
  color:#464646;
  word-break: break-all;

  cursor: pointer;
}

.takeaway-edit:empty:before {
    content: attr(placeholder);
    color:#757575;
}

.takeaway-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left:30px;
  padding-right:30px;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 1px solid black;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  color:#404040;
  background-color: lightgreen;
}

.takeaway-canel-ico {
  padding: 4px;
  font-size: 1.2rem;
  cursor: pointer;
}

.takeaway-canel-ico:hover {
  color:red;
}

@media (max-width: 780px) {

  .takeaway-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-bottom:20px;
    padding-top:0px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #ffff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }

}
