.Pooldelete-wrapper {
  
}

.Pooldelete-container {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 10px;
  background-color: #ffffff;
}

.Pooldelete-title {
  display: flex;
  border-bottom: 1px solid #f2f4f7;
  padding-bottom: 20px;
}

.Pooldelete-btn {
  display: flex;
  justify-content: flex-end;
  padding: 2px;
}

.Pooldelete-cancel {
  display: flex;
  cursor: pointer;
  padding: 4px;
}

.Pooldelete-del {
  display: flex;
  cursor: pointer;
  padding: 4px;
}

.Pooldelete-note {
  padding-top: 10px;
}

.Pooldelete-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4582ec;
}

.Pooldelete-del {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius:15px;
  background-color: #4582ec;
  color: #ffffff;
}

.Pooldelete-empty {
  display: flex;
  width: 14px;
}
