.Pbsuccess-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 400px;
  height: 200px;
  border-radius: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.Pbsuccess-container {
  position: fixed;
  left: 0;
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 14px;
}

.Pbsuccess-btn {
  outline: none;
  font-size: 1.6rem;
  background-color: #fff;
  color:#404040;
  cursor: pointer;
  border-radius: 30px;
  padding:6px;
  border: none;
}

.Pbsuccess-imgwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Pbsuccess-btn:hover {
  color:cornflowerblue;
}

.Pbsuccess-img  {
  max-width:50%;
}
