.videochatwrapper {
  display: flex;
  flex-direction: column;
  padding: 1vw;
  justify-content: flex-start;
  height: 100%;
}

.videochat-wrapper {
  display: flex;
  justify-content:space-between;
  align-items: center;
  flex-direction: column;
  height: 660px;;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.videochat-upper {
 width: 100%;
 height: 362px;
 overflow-y: hidden;
 overflow-x: hidden;
}

.videochat-bottom {
  width: 100%;
  height:60px;
}

.videochat-title {
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  font-size: 1.2rem;
  font-weight:bold;
  color:#404040;
  border-bottom: 1px solid #f0f0f0;
}

.videoclosedisable {
  color:#fff;
}

.videoclose {
  position: relative;
  top:-8px;
  left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  color:darkgrey;
  cursor: pointer;
  padding: 3px;
}

.videoclose:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color:#808080;
  background-color: #f5f1fe;
}

.videogrpchat {
  display: flex;
  justify-content:space-between;
  align-items: center;
  width: 100%;
  padding-bottom:2px;
}

.videomsgwrp {
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 2px;
}

.videomsgwrp-empspace {
  width: 10px;
}

.videomsgtitle {
  font-size: 1rem;
  font-weight:500;
  cursor: pointer;
  padding: 2px;
  border:none;
  color:tomato;
}

.videomsgtitledis {
  font-size: 1rem;
  font-weight:500;
  cursor: pointer;
  padding: 2px;
  border:none;
  color:darkgrey;
}

.videomsgtitledis:hover {
  color:#404040;
}

.videochatbody {
  padding: 0.6vw;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.videopartbody {
  padding: 0.6vw;
  height: 100%;
  overflow-y:hidden;
  overflow-x: hidden;
}

.videobdyemptyspace {
  height: 10px;
}

.videoprofilewrapper {
  display: flex;
  justify-content:flex-start;
  align-items: center;
}

.videoprofilename {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 0.9rem;
  padding: 0.1vw;
}

.videoprofilenametxt {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 240px;
  font-weight: 500;
}

.videoprofilechat {
  word-break: break-all;
  width: 220px;
  font-size: 0.9rem;
  padding-left:2px;
  padding-top:2px;
}

.video-profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width:32px;
  height:32px;
  border: 1px solid #b8b8b9;
}

.videochatmsg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 50px;
  background-color: #f0f2f5;

}

.videochat-postform {
  display: block;
  word-wrap: break-word;
  cursor: pointer;
  width: 22vw;
  border-radius: 10px;
  border: none;
  outline: none;
  overflow: auto;
  font-size: 1rem;
}

.videochat-postform:empty:before {
  content: attr(data-placeholder);
  color:darkgray;
}

.videochat-btnpost {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 1.6rem;
  color:#60acfd;
  background-color: #f0f2f5;
  width: auto;
}

.videoprofiletime {
  color: #737373;
  font-size: 0.8rem;
}

.videochatwrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.videochatprofileimg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  padding: 4px;
  color:darkgrey;
  font-size: 1rem;
  font-weight:bold;
}

.videochatusername {
   width: 120px;
   word-break: break-all;
}



@media (max-width: 769px) {

  .videochat-btnpost {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    font-size: 1.4rem;
    color:#60acfd;
    background-color: #f0f2f5;
  }

  .videochat-postform {
    display: block;
    padding-left:0.4rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    word-wrap: break-word;
    cursor: pointer;
    width: 42vw;
    border-radius: 10px;
    border: none;
    outline: none;
    overflow: auto;
    font-size: 0.9rem;
  }

  .videochattitle {
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding: 4px;
    font-size: 1rem;
    font-weight:400;
    color:darkgrey;
    border-bottom: 1px solid #f0f0f0;
  }

  .videoclose {
    position: static;
    top:-8px;
    left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    color:darkgrey;
    cursor: pointer;
    padding: 3px;
  }

}
