.poolreqcancel-modal {
  display: flex;
  justify-content:center;
  align-items: center;
  position: fixed;
  top:0;
  left: 0;
  z-index:99;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.poolreqcancel-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  color:#fff;
  width: 35%;
  height: auto;
  border-radius: 14px;
}

.poolreqcancel-upperpanel {
  width: 100%;
  color:#404040;
}

.poolreqcancel-imgwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.poolreqcancel-img {
  max-width: 4rem;
}

.poolreqcancel-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-left: 31vw;
  margin-bottom:60px;
  font-size: 1.6rem;
  cursor: pointer;
  color:#404040;
  height: 30px;
  width: 30px;
  border-radius: 50px;
}

.poolreqcancel-icon:hover {
  background-color: #f2f2f2;
}

.poolreqcancel-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color:#404040;
  padding: 6px;
}

.poolreqcancel-topic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color:#7280f1;
  padding: 2px;
  word-break: break-all;
  border-bottom: 1px solid #e2e7e1;
}

.poolreqcancel-lowerpanel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 14px;
}

.poolreqcancel-btnconfirm-wrapper {
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  cursor: pointer;
}

.poolreqcancel-btnconfirm {
  font-size: 1rem;
  border-radius: 14px;
  color:#fff;
  color:#404040;
  background-color: #f2f2f2;
  border: 2px solid #fff;
  padding-top:8px;
  padding-bottom:8px;
  padding-left:22px;
  padding-right:22px;
  font-weight: bold;
}

.poolreqcancel-btnconfirm:hover {
  border: 2px solid #bababa;
}

.poolreqcancel-topicname {
  color:#404040;
  padding: 4px;
}


@media (max-width: 912px) {

  .poolreqcancel-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    color:#fff;
    width: 80%;
    height: auto;
    border-radius: 14px;
  }

  .poolreqcancel-icon {
     display: flex;
     justify-content: center;
     align-items: center;
     position: absolute;
     margin-left: 70vw;
     margin-bottom:60px;
     font-size: 1.6rem;
     cursor: pointer;
     color:#404040;
     height: 30px;
     width: 30px;
     border-radius: 50px;
 }

}
