* {
  box-sizing: border-box;
}

.publish-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 170px;
  padding: 4px;
  width: 100%;
}
