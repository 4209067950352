* {
  box-sizing: border-box;
}

.Publisherr-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  height: 100vh;
  position: absolute;
  z-index: 1;
  top:70px;
  padding: 2px;
  background-color: #ffff;
}

.Publisherr-header {
  display: flex;
  justify-content:space-between;
  align-items: flex-start;
  width: 100%;
  height: 25%;
}

.Publisherr-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 4px;
}

.Publisherr-body {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   padding: 4px;
}

.Publisherr-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2.2rem;
  color: tomato;
  width: 100%;
  max-width: 100%;
}

.Publisherr-topicdetails {
  display: flex;
  border: 1px solid lightgray;
  width: 70%;
}

.Publisherr-topic {
  display: flex;
  width: 7vw;
  padding: 4px;
  background-color: #f3f3f3;
}

.Publisherr-topicname {
  display: flex;
  padding: 4px;
  width: 22vw;
}

.Publisherr-post {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: 6px;
  cursor: pointer;
  font-size: 1.2rem;
  background-color: #fe6347;
  color:#fff;
}

.Publisherr-empty {
   height: 20px;
}

.Prequestrr-textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  display: flex;
  width: 100%;
  padding: 0.7125rem 1rem;
  border: none;
  border-bottom: 1px solid lightgray;
  background-color: #fff;
  font-size: 1rem;
  outline: none;
  resize: none;
}

.Publisherr-titlearrow {
  display: flex;
  align-items: center;
  font-size: 2.4rem;
  border-radius: 50px;
  cursor: pointer;
}

.Publisherr-titlearrowhide {
  display: flex;
  align-items: center;
  font-size: 2.4rem;
  border-radius: 50px;
  color:#fff;
}

.Publisherr-arrowwrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Publisherr-titlearrow:hover {
  background-color: #f5f1fe;
}

.Publisherr-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  height:100%;
}

.Publisherr-img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.Publisherr-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Publisherr-line {
  width: 50%;
  border-bottom:1px solid lightgray;
}


.dd {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  width: 100%;
}

.Publisherr-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#404040;
  padding: 3px;
}

.Publisherr-subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;

  background: linear-gradient(to right, #ee0979, #ff6a00);
  background-clip: text;  -webkit-background-clip: text;  -moz-background-clip: text;  -moz-text-fill-color: transparent;  -webkit-text-fill-color: transparent;

  display: inline-block;
  padding: 3px;

}

.Publisherr-space {
  height:10px;
  width: 30%;
  border-bottom: 1px solid lightgray;
}



@media (max-width: 780px) {

  .Publisherr-empty {
     height: 10px;
  }

  .Publisherr-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    top:0px;
  }

  .Publisherr-header {
    display: flex;
    margin-top: 50px;
    justify-content:space-between;
    align-items: flex-start;
    width: 100%;
    height: 25%;
  }

  .Publisherr-topicdetails {
    display: flex;
    border: 1px solid lightgray;
    width: 100%;
  }

  .Publisherr-topic {
    display: flex;
    width: 40%;
    padding: 2px;
    background-color: #f3f3f3;
  }

  .Publisherr-topicname {
    display: flex;
    padding: 3px;
    width: 100%;
  }

  .Prequestrr-textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    display: flex;
    width: 100%;
    padding: 0.7125rem 1rem;
    border: none;
    border-bottom: 1px solid lightgray;
    background-color: #fff;
    height:50px;
    font-size: 1rem;
    outline: none;
    resize: none;
  }

}
