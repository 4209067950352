* {
  box-sizing: border-box;
}

.tm-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  top:250px;
  left:20vw;
  height: 100%;
  width:70%;
  background-color: #fff;
  background-color: #fff;
  z-index: 99;
}

.tm-outerwrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
}

.tm-outercontainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 80%;
}

.tm-listitem {
  width: 90%;
}

.tm-collapsible {
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 18px;
  padding-bottom: 0px;
  padding-top: 0px;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 15px;
  border: none;
  border-left: 2px solid lightgrey;
  border-left-style: dashed;
}

.tm-topicday {
  position: relative;
  left:-36px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 34px;
  border-radius: 50px;
  border: 2px solid #484848;
  background-color: #fb7762;
}

.tm-edit-btn {
  display: flex;
  cursor: pointer;
  font-size:0.8rem;
  color:#9e9e9e;
  cursor: pointer;
  border: none;
  background: #fff;
}

.tm-edit-btn:hover {
  color:cornflowerblue;
}

.tm-topicdate {
  position: relative;
  top:-32px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 6px;
  font-size: 1rem;
  padding: 4px;
  color:#9e9e9e;
}

.tm-content {
  height: 0px;
  transition: height 0.3s ease;
  overflow: hidden;
  background-color: #fff;
  border: none;
  border-left: 2px solid lightgrey;
  border-left-style: dashed;
}

.tm-content-max {
  min-height: 100px;
  transition: height 0.3s ease;
  overflow: hidden;
  background-color: #fff;
  border: none;
  border-left: 2px solid lightgrey;
  border-left-style: dashed;
  cursor: pointer;
}

.coledit {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  border-left: 2px solid lightgrey;
  border-left-style: dashed;
}

.topc-date-spc {
  width: 80%;
}



.dsec {
  margin-left: 30px;
}



.content-lst {
  -moz-transition: max-height .5s;
  -ms-transition: max-height .5s;
  -o-transition: max-height .5s;
  -webkit-transition: max-height .5s;
  transition: max-height .5s;
  max-height: 0;
  overflow: hidden;
  background-color: #fff;
  border: none;
  border-left: 2px solid #fff;
  border-left-style: dashed;
}



.collapsible-lst {
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 18px;
  padding-bottom: 0px;
  padding-top: 0px;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 15px;
  border: none;
  border-left: 2px solid #fff;
  border-left-style: dashed;
}

.tm-header-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left:20px;
  padding-top: 10px;
  color:black;
  width: 100%;
  font-size: 1.6rem;
  text-transform: capitalize;
}

.tm-header {
  position: relative;
  cursor: pointer;
}

.tm-header::before {
  content: "";
  position: absolute;
  left: 7%;
  bottom: 1px;
  width: 100%;
  height: 8px;
  transform: skew(-12deg) translateX(0px);
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  z-index: -1;
}



.tm-list-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: black;
  align-items: center;
  font-size: 1.8rem;
  padding: 10px;
  background-color: #fff;

  text-transform: capitalize;
  background: repeating-linear-gradient(white, black 2px, black 2px, black 3px);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tm-container {
  display: flex;
  flex-grow: 1;
  background-color: #fff;
  width: 100%;
}

.tm-circlecontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
}

.tm-line {
  display: flex;
  justify-content: center;
  border: 1px solid #484848;
  height: 100%;
}

.tm-lineblank {
  display: flex;
  justify-content: center;
  width: 1px solid;
  border: 1px solid #fff;
  height: 100%;
}

.tm-day {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  border-radius: 100px;
}

.tm-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
	width: 50px;
	border-radius: 50px;
	border: 4px solid #484848;
	background-color: #fb7762;
}

.tm-innerwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.tm-inwrapcontainer {
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  min-height: 110px;
  max-width: 100%;
  border-radius: 8px;
  background-color: #fff;
   box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.02),
   0 18px 36px -18px rgba(0, 0, 0, 0.3),
   0 -12px 36px -8px rgba(0, 0, 0, 0.025);

}

.tm-date {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #8266a1;
   border-top-left-radius: 8px;
   border-bottom-left-radius: 8px;
	 color:#fff;
	 width: 14vw;
   font-size: 1rem;
}

.tm-main {
  display: flex;
	flex-direction: column;
	padding: 4px;
	width: 34vw;
}

.tm-title {
   display: block;
   width: 100%;
   word-wrap: break-word;
   color:#484848;
   border: 1px solid darkgrey;
	 border-style: dashed;
	 border-radius: 10px;
	 padding: 6px;
   font-size: 1.1rem;
}

.tm-title-edit {
  display: block;
  width: 100%;
  word-wrap: break-word;
  border: 1px solid #fff;
  color:#484848;
  padding: 6px;
  font-size: 1.1rem;
  border-bottom: 1px solid #eceff1;
}


.tmp-desc {
  position: relative;
  top:10px;
  margin-left: 30px;
  display: block;
	word-wrap: break-word;
  color:#484848;
	min-height: 70px;
  width: 84%;
  border: 1px solid darkgrey;
  border-style: dashed;
	border-radius: 10px;
	padding: 6px;
  font-size: 0.9rem;
}

.tmp-desc-edit {
  position: relative;
  top:-10px;
  margin-left: 30px;
  display: block;
	word-wrap: break-word;
  color:#9e9e9e;
	min-height: 70px;
	width: 90%;
  border: 1px solid #fff;
  border-style: dashed;

	padding: 6px;
  font-size: 0.9rem;
  z-index: 13;
  border-bottom: 1px solid #eceff1;
}


.tm-desc {
  display: block;
	word-wrap: break-word;
  color:#9e9e9e;
	min-height: 80px;
	width: 100%;
  border: 1px solid darkgrey;
  border-style: dashed;
	border-radius: 10px;
	padding: 6px;
  font-size: 1rem;
}

.tmp-desc:focus {
  border: 1px solid #fff;
  outline:2px solid #bfdeff;
}

.tmp-desc:empty:before {
  content: attr(data-placeholder);
  color:darkgray;
}

.tm-desc-edit {
  position: relative;
  top:-10px;
  margin-left: 30px;
  display: block;
  word-wrap: break-word;
  color:#9e9e9e;
  min-height: 70px;
  width: 90%;
  border: 1px solid #fff;
  border-style: dashed;

  padding: 6px;
  font-size: 0.9rem;
  z-index: 13;
  border-bottom: 1px solid #eceff1;
}

.tmp-desc-edit:empty:before {
  content: attr(data-placeholder);
  color:darkgray;
}

.tm-edit {
  display: flex;
	justify-content:flex-end;
	padding-top: 4px;
}

.tm-editbtn {
  color:darkgray;
  border: none;
  background-color: #fff;
  cursor: pointer;
  font-size: 1rem;
}

.tm-editbtn:hover {
	color:#3390ec;
}


.tm-title:focus {
  border: 1px solid #fff;
  outline:2px solid #bfdeff;
}

.tm-desc:focus {
  border: 1px solid #fff;
  outline:2px solid #bfdeff;
}

.tm-title:empty:before {
  content: attr(data-placeholder);
  color:darkgray;
}

.tm-title-edit:empty:before {
  content: attr(data-placeholder);
  color:darkgray;
}

.tm-desc:empty:before {
  content: attr(data-placeholder);
  color:darkgray;
}

.tm-desc-edit:empty:before {
  content: attr(data-placeholder);
  color:darkgray;
}

.tm-emptyspace {
  height: 10px;
}

.tm-takeaway {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}


@media (max-width: 780px) {

  .tm-listitem {
    width: 84%;
  }

  .tm-wrapper {
    display: block;
    position: absolute;
    top:294px;
    left:0px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
  }

  .tm-outercontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 4px;
  }

.tm-inwrapcontainer {
  	display: flex;
  	flex-direction: column;
  	flex-grow: 2;
  	min-height: 200px;
  	max-width: 100%;
 }

 .tm-main {
  	display: flex;
  	flex-direction: column;
  	padding: 4px;
  	width: 54vw;
 }

 .tm-date {
  	display: flex;
  	justify-content: center;
  	align-items: center;
  	height: 60px;
  	width: 100%;
  	padding: 10px;
    font-size: 1.1rem;
	  border-top-left-radius: 4px;
	  border-top-right-radius: 4px;
	  border-bottom-left-radius: 0px;
	  border-bottom-right-radius: 0px;
 }

.tm-title {
    display: block;
		border: 1px solid darkgrey;
		border-style: dotted;
    word-wrap: break-word;
    width: 100%;
    font-size: 1rem;
}

.tm-title-edit {
  display: block;
  width: 100%;
  word-wrap: break-word;
  border: 1px solid #fff;
  color:#484848;
  padding: 6px;
  font-size: 1rem;
  border-bottom: 1px solid #eceff1;
}

.tm-desc {
    display: block;
		border: 1px solid darkgrey;
		border-style: dotted;
    word-wrap: break-word;
    width: 100%;
		min-height: 100px;
    font-size: 0.9rem;
	}

.tm-desc-edit {
    display: block;
    border: 1px solid #fff;
    border-style: dotted;
    word-wrap: break-word;
    width: 100%;
    min-height: 100px;
    font-size: 0.9rem;
  }

	.tm-circlecontainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100px;
	}

}
