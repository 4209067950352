* {
 box-sizing: border-box;
}

.browserpoolcontainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100vh;
  width: 90%;
  margin-top: 160px;
  padding-bottom:  30px;
  padding-left: 30px;
  padding-right: 30px;
}

.browselist-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.browselist-epool-img {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60%;
}

.browselist-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.browselist-crtpool {
  border-radius: 50px;
  padding: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  background-color: #1ee592;
  cursor: pointer;
}

.browselist-img {
  width: 300px;
}

.browselist-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.browselist-link {
  display: flex;
  padding: 16px;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  border: 1px solid #ffffff;
  border-radius: 10px;
  text-decoration: none;
  color:#484848;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.browselist-link:hover {
  border: 1px solid #b8b8b9;
  border-radius: 10px;
}

.browselist-link:hover .browselist-desc  {
  color:#4582ec;
}

.browselist-desctable {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.browselist-topicdetails {
  display: flex;
  padding: 4px;
}

.browselist-topic {
  display: flex;
  justify-content:flex-start;
  align-items:center;
  border-radius: 10px;
  width: 60px;
  height: auto;
  padding: 10px;
  background-color: #f3f3f3;
  font-size: 0.9rem;
}

.browselist-desc {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  font-size: 0.9rem;
  width:230px;
  height: auto;
  border-radius: 10px;
  background-color: #f3f3f3;
  flex-wrap: wrap;
  word-break: break-all;
}

.browselist-descaa {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
  font-size: 0.9rem;
  width:100%;
  border-radius: 10px;
  background-color: #f3f3f3;
  flex-wrap: wrap;
  word-break: break-all;
}


.browselist-spinner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.browselist-wrapperimg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
}

.browselist-poolscrollimg {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  width:240px;
  height:180px;
  object-fit: contain;
}

.browselist-poolimg {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.tag-wrpapper-line {
  width: 100%;
}

.topic-tag-all {
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  border-bottom-style: dashed;
}

.tag-line {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.tag-sub-line {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.topic-tag-title {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:30px;
  background-color: black;
  padding:8px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  color:#ffff;
  border: 2px solid #ffff;
  cursor: pointer;
}

.topic-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:30px;
  background-color: black;
  padding:8px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  color:#ffff;
  border: 2px solid #ffff;
}

.topic-tag-sel {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:30px;
  background-color:#ffff;
  padding:8px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  color:black;
  border: 2px solid black;

  cursor: pointer;


  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.topicsel {
  display: flex;
  justify-content:center;
  align-items: center;
  word-break:break-all;
  letter-spacing: 2px;
  font-size: 16px;
}

.topicselicon1 {
  display: flex;
  justify-content:center;
  align-items: center;
  color:black;
}

.topicselicon1:hover {
  color:crimson;
}

.tag-head {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-family: 'vanilla_extractregular';
  letter-spacing: 2px;
}

.topicselicon {
  display: flex;
  justify-content:center;
  align-items: center;
}

.topic-tag-wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-radius:30px;
   background-color: #ffff;
   padding:8px;
   padding-left: 20px;
   padding-right: 20px;
   font-size: 24px;
   color:black;
   border: 2px solid black;
   cursor: pointer;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.topic-tag-wrapper:hover {
  color:black;
   background-color: whitesmoke;
   cursor: pointer;
   border: 2px solid black;
   color:black;
}

.topicselicon:hover {
  color:crimson;
}

.topicsel1 {
  visibility: hidden;
}

.topic-tag-sel:hover {
  color:#ffff;
  background-color: whitesmoke;
  color:black;
  cursor: pointer;
  font-size: 20px;
  border: 2px solid black;
}

.topic-tag:hover {
 color:#ffff;
 background-color: whitesmoke;
 color:black;
 cursor: pointer;
 font-size: 20px;
 border: 2px solid black;
}

.tagselect {
  display: flex;
  justify-content:center;
  align-items: center;
  flex-wrap: wrap;
  gap:10px;
}

.topic-tag-wrapper-width {
  width:30px;
}

@media (max-width: 780px) {

  .browserpoolcontainer {
    position: absolute;
    margin-top:120px;
    top: 0;
    left: 0;
    width: 100%;
  }

  .browselist-title {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .browselist-img {
     width:280px;
   }

  .browselist-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }

  .browselist-link {
     display: flex;
     padding: 16px;
     flex-direction: column;
     width: 100%;
     cursor: pointer;
     border: 1px solid #ffffff;
     border-radius: 10px;
     text-decoration: none;
     color:#484848;
   }

   .browselist-desc {
     display: flex;
     justify-content: flex-start;
     align-items: center;
     padding: 4px;
     font-size: 0.9rem;
     width:100%;
     height: 50px;
     border-radius: 10px;
     background-color: #f3f3f3;
     flex-wrap: wrap;
     word-break: break-all;
   }

}

@media (max-width: 430px) {

  .topic-tag-wrapper {
     display: flex;
     justify-content: space-between;
     align-items: center;
     border-radius:30px;
     background-color: #ffff;
     padding:8px;
     padding-left: 20px;
     padding-right: 20px;
     font-size: 22px;
     color:black;
     border: 2px solid black;
     cursor: pointer;
     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .topic-tag-wrapper-width {
    width:30px;
  }

  .topicsel {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    letter-spacing: 2px;
    font-size: 15px;
  }

  .topseliner {
    padding: 2px;
  }

}

@media (max-width: 410px) {

  .topic-tag-wrapper {
     display: flex;
     justify-content: space-between;
     align-items: center;
     border-radius:30px;
     background-color: #ffff;
     padding:8px;
     padding-left: 10px;
     padding-right: 10px;
     font-size: 20px;
     color:black;
     border: 2px solid black;
     cursor: pointer;
     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .topic-tag-wrapper-width {
     width:30px;
  }

}

@media (max-width: 370px) {

  .topic-tag-wrapper {
     display: flex;
     justify-content: space-between;
     align-items: center;
     border-radius:30px;
     background-color: #ffff;
     padding:8px;
     padding-left: 5px;
     padding-right: 5px;
     font-size: 18px;
     color:black;
     border: 2px solid black;
     cursor: pointer;
     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .topic-tag-wrapper-width {
     width:12px;
  }

}


@media (max-width: 370px) {

  .topic-tag-wrapper {
     display: flex;
     justify-content: space-between;
     align-items: center;
     border-radius:30px;
     background-color: #ffff;
     padding:8px;
     padding-left: 4px;
     padding-right: 4px;
     font-size: 17px;
     color:black;
     border: 2px solid black;
     cursor: pointer;
     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .topic-tag-wrapper-width {
     width:10px;
  }

  .topic-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:30px;
    background-color: black;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 20px;
    word-break: break-all;
    color:#ffff;
    border: 2px solid #ffff;
  }





}
