* {
  box-sizing: border-box;
}

.Homepage-topnavbar-notification-notificationpanel-cl {
  position: fixed;
  overflow: auto;
  left:60vw;
  top:66px;
  color:#495057;
  background-color: #ffffff;
  border-radius: 10px;
  width:28vw;
  height:540px;
  box-shadow: 0px 0px 2px 2px #E1E1E1;
  z-index: 15;
}

.Homepage-topnavbar-notification-notificationpanel-op {
  position: fixed;
  overflow: auto;
  left:60vw;
  top:66px;
  color:#495057;
  background-color: #ffffff;
  border-radius: 10px;
  width:28vw;
  height:540px;
  box-shadow: 0px 0px 2px 2px #E1E1E1;
}

.Homepage-topnavbar-notification-notificationpanel-op::-webkit-scrollbar {
  width: 8px;
}

.Homepage-topnavbar-notification-notificationpanel-op::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.Homepage-topnavbar-notification-notificationpanel-op::-webkit-scrollbar-thumb {
  background: darkgray;
  border-radius: 10px;
}


.Homepage-topnavbar-notification-notificationHeader {
   color:#495057;
   font-size:1.5625rem;
   padding: 10px;
   border-bottom: 1px solid #E1E1E1;
}

.Homepage-topnavbar-notification-notificationList {
   display: flex;
   flex-direction:column;
   justify-content:center;
   
   overflow: hidden;
   padding-bottom: 60px;
}

.Homepage-topnavbar-notification-spinner {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 8px;
}

.Homepage-topnavbar-notification-notificationFeed {
  display: flex;
  align-items: center;
  justify-content:center;
  border-bottom: 1px solid #EDEDED;
  padding: 14px;
  font-size: 1.2rem;
}

.Homepage-topnavbar-notification-notificationFeed-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.9rem;
  width: 100%;
  min-height: 60px;
  border-radius: 8px;
  background-color: #fff;
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.Homepage-topnavbar-notification-notificationFeed-check {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  height: 8px;
  width: 8px;
  background-color: #b878ed;
}

.Homepage-topnavbar-notification-notificationFeed-checked {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  height: 8px;
  width: 8px;
  background-color: #fff;
}

.Homepage-topnavbar-noti-close {
  display: none;
}

.Homepage-topnavbar-profile-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Homepage-topnavbar-wrapper-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.Homepage-topnavbar-profile-wrapperaa {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}


.Homepage-topnavbar-profile-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 20%;
}

.Homepage-topnavbar-profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width:40px;
  height:40px;
}

.Homepage-topnavbar-notification-text {
  display: flex;
  justify-content:flex-start;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 4px;
  color:#808080;
}

.Homepage-topnavbar-notification-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#ff8a6e;
  font-size: 1.2rem;
}

.Homepage-topnavbar-notification-msgtxt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  word-break: break-all;
  font-size: 1rem;
  padding: 4px;
  margin-left: 4px;
}

.Homepage-topnavbar-noti-msg {
  padding: 2px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Homepage-topnavbar-noti-msgtxt {
  width: 160px;
  padding:2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Homepage-topnavbar-notification-time {
  display: flex;
  padding: 4px;
  color:lightgrey;
  width: 100%;
}

.Homepage-topnavbar-notiheader-msg {
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 10px;
   padding: 10px;
   border: 1px solid lightgrey;
   border-style: dashed;
}

.Homepage-topnavbar-notiheader-msg:hover  {
  background-color:#f9f9f9;
}

@media (max-width: 940px) {

  .Homepage-topnavbar-notification-notificationpanel-cl {
    position: fixed;
    overflow: auto;
    left:60vw;
    top:66px;
    color:#495057;
    background-color: #ffffff;
    border-radius: 10px;
    width:34vw;
    height:540px;
    box-shadow: 0px 0px 2px 2px #E1E1E1;
  }

  .Homepage-topnavbar-notification-notificationpanel-op {
    position: fixed;
    overflow: auto;
    left:60vw;
    top:66px;
    color:#495057;
    background-color: #ffffff;
    border-radius: 10px;
    width:34vw;
    height:540px;
    box-shadow: 0px 0px 2px 2px #E1E1E1;
  }

}


@media (max-width: 780px) {

  .Homepage-topnavbar-notification-notificationpanel-cl {
    position: fixed;
    top:0px;
    left:0px;
    width:0px;
    height: 100vh;
    color:#495057;
    background-color: #ffffff;
    border-radius:0px;
    padding: 0px;
    transition:0.3s;
    overflow-x: hidden;
  }

  .Homepage-topnavbar-notification-notificationpanel-op {
    position: fixed;
    top:0px;
    left:0px;
    width:100%;
    height: 100vh;
    color:#495057;
    background-color: #ffffff;
    border-radius:0px;
    padding: 0px;
    transition:0.3s;
    z-index: 14;
  }

  .Homepage-topnavbar-notification-notificationpanel-op::-webkit-scrollbar {
    width: 0px;
  }

  .Homepage-topnavbar-notification-notificationpanel-op::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .Homepage-topnavbar-notification-notificationpanel-op::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 0px;
  }

  .Homepage-topnavbar-notification-notificationpanel-cl::-webkit-scrollbar {
    width: 0px;
  }

  .Homepage-topnavbar-notification-notificationpanel-cl::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .Homepage-topnavbar-notification-notificationpanel-cl::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 0px;
  }

  .Homepage-topnavbar-notification-notificationHeader {
     display: flex;
     align-items: center;
     justify-content: space-between;
     color:#495057;
     font-size:1.5625rem;
     border-bottom: 1px solid #E1E1E1;
  }

  .Homepage-topnavbar-notiheader {
     display: flex;
     justify-content: center;
     align-items: center;
  }

  .Homepage-topnavbar-notiheader-msg {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     border-radius: 10px;
     padding: 10px;
     border: 1px solid lightgrey;
     border-style: dashed;
  }

  .Homepage-topnavbar-noti-close {
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 2px;
     font-size:2.4rem;
     color:#495057;
     cursor: pointer;
  }

}
