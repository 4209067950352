.Sterotypesel-wrapper {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items:center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  left: 0px;
  top:0px;
  background-color: rgba(0, 0, 0, 0.4);
}

.Sterotypesel-container-close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.Sterotypesel-container-closeicon {
  font-size: 1.4rem;
  padding: 4px;
  cursor: pointer;
}

.Sterotypesel-container-closeicon:hover {
  color:cornflowerblue;
}

.Sterotypesel-container {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  flex-direction: column;
  width: 500px;
  height: auto;
  border-radius: 20px;
  background-color: #ffff;
  border: 1px solid lightgray;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.Sterotypesel-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px;
  font-size: 1.2rem;
}

.Sterotypesel-choice {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-size: 1.4rem;
  padding: 4px;
}

.Sterotypesel-clear {
  display: flex;
  justify-content: center;
  align-items: center;
  color:RED;
  cursor: pointer;
}

.Sterotypesel-clear-dis {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  visibility: none;
}

.Sterotypesel-inner-wrapper {
  display: flex;
  justify-content: center;
  align-items:center;
  flex-wrap: wrap;
  width: 100%;
}

.Sterotypesel-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.Sterotypesel-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 10px;
  background-color: #ffff;
  padding: 10px;
  border: 2px solid #404040;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.Sterotypesel-card-sel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 10px;
  background-color: #ffff;
  padding: 10px;
  border: 2px solid cornflowerblue;
  box-shadow: rgb(0,0,0,0.4) 0px 8px 24px;
}

.Sterotypesel-card-sel:focus {
    box-shadow: rgb(0,0,0,0.4) 0px 8px 24px;
}

.Sterotypesel-card:hover {
  color:#404040;
  box-shadow: rgb(0,0,0,0.4) 0px 8px 24px;
}

.Sterotypesel-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 50px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.Sterotypeimage-img {
  max-width: 100%;
  max-height: 100%;
}

.Sterotypesel-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 4px;
  width: 100%;
}


.Sterotypesel-container-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Sterotypesel-container-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: bold;
}

.Sterotypesel-container-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Sterotypesel-container-getstarted {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  cursor: pointer;
  padding: 14px;
  border-radius: 10px;
  color:#ffff;
  background-color: #ff4645;
}

.Sterotypesel-nostrotype {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-style: dashed;
  border-radius: 10px;
  font-size: 1rem;
  padding: 4px;
  cursor: pointer;
}

.Sterotypesel-nostrotype:hover {
  border: 1px solid cornflowerblue;
  border-style: dashed;
}


@media (max-width: 780px) {

  .Sterotypesel-container {
    display: flex;
    justify-content:flex-start;
    align-items: center;
    flex-direction: column;
    width: 80%;
    height:auto;
    border-radius: 20px;
    border: 1px solid lightgray;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  }

  .Sterotypesel-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12vw;
    height: 12vw;
    border-radius: 50px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }


}
