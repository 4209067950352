.Details-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 6px;
}

.Details-container {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  flex-direction: column;
  width: 40%;
  padding: 10px;
  min-height: 500px;
  height: 80%;
  border-radius: 20px;
  background-color: #ffff;
  border: 1px solid lightgray;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.Details-gender-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  width: 70%;
  font-size: 1.2rem;
  color:#404040;
}

.Details-gender-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4px;
  width: 100%;
}

.Details-gender {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 1.4rem;
  width: 100%;
  color:#404040;
}

.Detailstitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.Details-icon-gender {
  font-size: 1.8rem;
  color: cornflowerblue;
}

.Details-location-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Detailstitlearrow {
  display: flex;
  align-items: center;
  font-size: 2rem;
  border-radius: 50px;
  cursor: pointer;
  color:#404040;
}

.Detailstitlearrow:hover {
  background-color: #f5f1fe;
}

.Detailstittitletext {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  color:#404040;
}

.Details-container-location {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Details-location-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 1.4rem;
  width: 100%;
  color:#404040;
}

.Details-location-name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Details-location {
  font-size: 1rem;
  padding: 4px;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
}

.Details-location:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid black;
}

.Details-container-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Details-container-getstarted {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  cursor: pointer;
  padding: 14px;
  border-radius: 10px;
  color:#ffff;
  background-color: #ff4645;
}


.Details-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color:#484848;
  border: none;
}

.Details-timetitle-zone {
  display: flex;
  align-items: center;
  padding: 4px;
  border: 1px solid lightgray;
  border-radius: 20px;
  cursor: pointer;
}

.Details-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}


@media (max-width: 780px) {

  .Details-location {
    font-size: 1rem;
    padding: 4px;
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
  }

  .Details-container {
    display: flex;
    justify-content:flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    min-height: 500px;
    height: 80%;
    border-radius: 20px;
    border: 1px solid lightgray;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  }

  .Details-container-getstarted {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    cursor: pointer;
    padding: 14px;
    border-radius: 10px;
    color:#ffff;
    background-color: #ff4645;
  }

}
