.changepasswordwrapper {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #fff;
}

.changepassword-container {
  width: 100%;
}

.changepassword-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  width: 100%;
}

.changepassword-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.changepassword-body-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
}

.changepassword-inp {
  display: flex;
  width: 100%;
  padding:  1rem;
  border-radius: 6px;
  background-color: #fff;
  font-size: 1rem;
  border: 2px solid lightgray;
  outline: none;
  -moz-outline:none;
}

.changepassword-inp:focus {
  border: 2px solid #bfdeff;
}

.changepassword-inp-space {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.9rem;
  height: 10px;
  padding: 10px;
  color:red;
  width: 100%;
}

.changepassword-btn {
  display: flex;
  justify-content: center;
  width: 100%;
}

.changepassword-savebtn {
  background-color: tomato;
  background-color: #1d9bf0;
  background-color: cornflowerblue;
  border: 2px solid #404040;
  
  color:#ffffff;

  outline: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 50px;
  outline: none;
  -moz-outline:none;
}

.changepassword-savebtn-dis {
  background-color: lightgray;
  color:#ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 50px;
}

.changepassword-spinner {

}

.changepassword-msg {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.verifypin {
  display: flex;
  justify-content: center;
  align-items: center;
}

.showbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.verifypinbtn {
  padding: 4px;
  border-bottom-style: dashed;
  cursor: pointer;
}

.verifypinbtn1 {
  padding: 4px;
  border-bottom-style: dashed;
}

.pinerrormsg {
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  color:red;
  padding:20px;

}

@media (max-width: 780px) {

  .changepassword-body-inner {
    width: 80%;
  }

}
