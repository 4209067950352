.Poolpage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Poolpage-poolcontainer {
  position:absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top:-80px;
  background-color: #fff;
}


@media (max-width: 870px) {


  .Poolpage-poolcontainer {
    position:absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top:-40px;
    background-color: #fff;
  }


}
