.success-notify-wrapper {
  position: fixed;
  top:80px;
  width: auto;
}

.success-notify-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color:#69d392;
  color:#404040;
  font-family: 'Roboto Condensed';
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  font-size: 1rem;
  border-radius: 4px;
  animation-duration: .5s;
  animation-name: bounce;
  padding: 4px;
}

.success-notify-item-msg {
  display: flex;
  justify-content: center;
  align-items:center;
  padding: 4px;
}

.success-notify-close {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
}


@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
  40% {-webkit-transform: translateY(-30px);}
  60% {-webkit-transform: translateY(-15px);}
}

@keyframes bounce {
   0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
   40% {transform: translateY(-30px);}
   60% {transform: translateY(-15px);}
}

.bounce {
  -webkit-animation-name: bounce;
   animation-name: bounce;
}
