.Poolnotifydetaildesc-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 450px;
  background-color: #fff;
}

.Poolnotifydetaildesc-wrapper {
  display: flex;
  flex-direction: column;
  width: 50vw;
  background-color: #fff;
}

.Poolnotifydetaildesc-profile {
  display: flex;
}

.Poolnotifydetaildesc-desctable {
  display: flex;
  flex-direction: column;
}

.Poolnotifydetaildesc-profileimg {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.Poolnotifydetaildesc-profile-name {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Poolnotifydetaildesc-topicdetails {
  display: flex;
  border: 1px solid lightgray;
  width: 100%;
}

.Poolnotifydetaildesc-topic {
  display: flex;
  width: 90px;
  padding: 4px;
  background-color: #f3f3f3;
}

.Poolnotifydetaildesc-topicname {
  padding: 4px;
  width:400px;
  word-break: break-all;
}

.Poolnotifydetaildesc-empty {
  display: flex;
  height: 20px;
}

.Poolnotifydetaildesc-profile-empty {
  display: flex;
  width: 10px;
}

.Poolnotifydetaildesc-desc {
  width: 40vw;
  word-break: break-all;
}

.Poolnotifydetaildesc-share {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
}

.Poolnotifydetaildesc-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom:4px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid lightgrey;
}

.Poolnotifydetaildesc-btn:hover {
  background-color:#f9f9f9;
}


.Poolnotifydetaildesc-comment {
  display: flex;
}

.Poolnotifydetaildesc-pstbox {
 display: flex;
 flex-direction: column;
}

.Poolnotifydetaildesc-shareicon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
}

.Poolnotifydetaildesc-sharetxt {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  padding: 4px;
}


@media (max-width: 912px) {

  .Poolnotifydetaildesc-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left:0px;
    background-color: #fff;
  }

  .Poolnotifydetaildesc-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
  }

  .Poolnotifydetaildesc-desc {
    width: 90vw;
    word-break: break-all;
  }

}
