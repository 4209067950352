.datepicker-wrapper {
  position:relative;
  top:20px;
  right:20px;
}

.datepicker-custompicker {

}

.datepicker-custompicker input[type=text] {
  display: none;
}
