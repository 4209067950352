.Duration-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.Duration-wrapper {
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  padding: 10px;
  width: 60%;
  border: 1px solid lightgray;
  border-radius: 10px;
  background-color: #ffff;
}

.Duration-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:#484848;
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
}

.Duration-cancel {
  cursor: pointer;
}

.Duration-cancel:hover {
  color:red;
}

.Duration-container {
  display: flex;
  justify-content:center;
  align-items: center;
  flex-wrap: wrap;
  width:auto;
}

.Duration-box-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 4px;
  border-radius: 10px;
}

.Duration-box-wrapper:hover {
  color:#ffff;
  background-color: tomato;
}

@media (max-width: 780px) {

  .Duration-modal {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     z-index: 99;
     padding: 10px;
     width: auto;
     background-color: rgba(0, 0, 0, 0.25);
   }

  .Duration-wrapper {
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    padding: 10px;
    width: 90%;
    height: auto;
    overflow-y: auto;
    border: 1px solid lightgray;
    border-radius: 10px;
    background-color: #ffff;
  }


}
