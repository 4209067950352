.username-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.username-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 40%;
}
