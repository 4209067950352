.ac-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  top:250px;
  left:20vw;
  height: 100%;
  width:70%;
  background-color: #fff;
}

.ac-void {
  display: none;
}

.ac-team {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ac-header {
  position: relative;
}

.ac-header::before {
  content: "";
  position: absolute;
  left: 7%;
  bottom: 1px;
  width: 100%;
  height: 8px;
  transform: skew(-12deg) translateX(0px);
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  z-index: -1;
}

.ac-textcont {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.4rem;
}


.ac-wrapper-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
}

.ac-header-topic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
}

.ac-header-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.ac-hastag {
  display: flex;
  justify-content: center;
  align-items: center;
  color:black;
  width:auto;
  font-size: 1.6rem;
  text-transform: capitalize;
}

.ac-postbox-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items:flex-start;
  flex-direction: column;
  width: 70%;
  background-color: #fff;
}

.ac-active-list {
  display: flex;
  justify-content: center;
  align-items:flex-start;
  flex-direction: column;
  width:100%;
  background-color: #fff;
}

.ac-active-item {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  padding: 20px;
  width: 80%;
}

.ac-upbtn {
  display: none;
  cursor: pointer;
}

.ac-imagecontainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border: 2px solid darkgrey;
  border-style: dashed;
  border-radius: 10px;
  background-position:center;
  background-size:contain;
  background-repeat:no-repeat;
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.ac-postform {
  display: block;
  padding: .40rem;
  word-wrap: break-word;
  cursor: pointer;
  max-width: 100%;
  max-height: 180px;
  border: none;
  outline: none;
  overflow: auto;
}

.ac-postform  img {
  max-width: 20%;
  max-height: 20%;
}

.ac-postform:empty:before {
  content: attr(data-placeholder);
  color:darkgray;
}

.ac-postform-drag {
  display: block;
  padding: .40rem;
  word-wrap: break-word;
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
  border: none;
  outline: none;
  overflow: auto;
  min-height: 40px;
  border-radius: 10px;
  border: 1px solid darkgray;
  border-style: dashed;
}

.ac-remove-image {
  position: absolute;
  top:0px;
  right:-4px;
  padding: 2px 6px 3px;
  text-decoration: none;
  font-size:1.2rem;
  font-weight: bold;
  color:#808080;
  cursor: pointer;
}

.ac-remove-image:hover {
  color:#ff0000;
}

.ac-himg-container {
  display: flex;
  justify-content:center;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  border: 1px solid darkgray;
  border-style: dashed;
  border-radius: 10px;
  padding: 4px;
  overflow: auto;
  max-height: 240px;
  cursor: pointer;
}

.ac-himg-container:focus {
  border: 1px solid darkgray;
  border-style: dashed;
  outline: none;
}

.ac-himg-clear {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
  cursor: pointer;
  font-size: 1.4rem;

}

.ac-himg-clear-btn {
  color:darkgray;
}

.ac-himg-clear-btn:hover {
  color:#ff493b;
}

.ac-replyuser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.ac-replyuser-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
  width: 100%;
}

.ac-replyuser-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  font-size: 1.2rem;
}

.ac-replyuser-cancel:hover {
  color:#ff493b;
}

.ac-reply-details {
  display: flex;
  align-items:center;
  flex-direction: column;
  font-size: 0.8rem;
  width: 100%;
  height: 80px;
  border-radius: 10px;
  background-color: #f5f6f6;
  border: 1px solid lightgray;
}

.ac-item-img {
  display:flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap:12px;
}

.ac-post-panel-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ac-post-panel-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ac-item-img img {
    max-width: 65px;
    max-height: 65px;
    border-radius: 10px;
    padding: 4px;
    cursor: pointer;
}

.ac-postbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: auto;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #808080;
  border-style: dashed;
}

.ac-postbox-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.ac-header-search {
  display: flex;
  justify-content:center;
  align-items: center;
  background-color: #fff;
  color:#909090;
  font-size: 1.6rem;
  cursor: pointer;
}

.ac-message-input-wrapper {
  display:flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 4px;
  overflow: hidden;
}

.ac-reply-text {
   width: 100%;
   color: #404040;
   border-radius: 10px;
   padding: 10px;
   overflow: hidden;
   word-wrap: break-word;
   text-overflow: ellipsis;
}

.ac-item-wrapper-img {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  gap:12px;
  width: 100%;
  border-radius: 4px;
}

.ac-button-panel {
  display:flex;
  justify-content:space-between;
  align-items: center;
  width:100%;
  padding: 2px;
}

.ac-emoji-button {
  border: none;
  cursor: pointer;
  color:darkgrey;
  background-color: #ffffff;
  font-size: 1.4rem;
}

.ac-emoji-button:hover {
  color:cornflowerblue;
}

.ac-post-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ac-btnpost {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  color:#60acfd;
}

.ac-btncancel {
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size:1rem;
  color:#60acfd;
}

.ac-btnpost {
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 2.4rem;
  color:#60acfd;
}

.ac-header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.8rem;
  width: 1.8rem;
  font-size: 0.9rem;
  border-radius: 50%;
  color:#fff;
  padding: 16px;
  background-color: #fa523e;
}


@media (max-width: 780px) {

  .ac-wrapper {
    position: relative;
    top:110px;
    left:0;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    overflow: auto;
  }

  .ac-postform {
    display: block;
    padding: .2rem;
    word-wrap: break-word;
    cursor: pointer;
    width:100%;
    max-height: 100px;
    border: none;
    outline: none;
    overflow: auto;
  }

  .ac-active-list {
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    width: 100%;
  }

  .ac-postform-drag {
    display: block;
    padding: .40rem;
    word-wrap: break-word;
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
    border: none;
    outline: none;
    overflow: auto;
    min-height: 40px;
    border-radius: 10px;
    border: 1px solid darkgray;
    border-style: dashed;
  }

  .ac-postbox-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding:4px;
  }

  .ac-postbox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #ffffff;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #808080;
    border-style: dashed;
  }

  .ac-postbox-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items:flex-start;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
  }


}

@media (max-width: 620px) {

  .ac-wrapper {
    position: relative;
    top:120px;
    left:0;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    overflow: auto;
  }


}

@media (max-width: 510px) {

  .ac-wrapper {
    position: relative;
    top:136px;
    left:0;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    overflow: auto;
  }

}

@media (max-width: 450px) {

  .ac-wrapper {
    position: relative;
    top:140px;
    left:0;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    overflow: auto;
  }

}
