.smart-tag-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  top:250px;
  left:20vw;
  height: 100%;
  width:59vw;
  background-color: #fff;
}

.smart-tag-header-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.6rem;
  border-bottom: 1px solid #f2f2f2;
}

.smart-tag-header {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  width: 100%;
  padding: 4px;
  height: 44px;
  z-index: 1;
}

.smart-tag-hash {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.smart-tag-header {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: auto;
}

.smart-tag-header::before {
  content: "";
  position: absolute;
  left: 7%;
  bottom: 8px;
  width:100%;
  height:8px;
  transform: skew(-12deg) translateX(0px);
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  z-index: -1;
}

.smart-tag-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.4rem;
}

.smart-tag-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 10px;
  background-color: #fff;
  text-transform: capitalize;
  background: repeating-linear-gradient(white, black 2px, black 2px, black 3px);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.smart-tag-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.smart-tag-list {
  display: flex;
  justify-content:flex-start;
  align-items: center;
  font-size: 1.2rem;
  padding: 14px;
  width: 94%;
  background-color: #f2f4f7;
  color:black;
  border-radius: 30px;
  border: 2px solid black;
  cursor: pointer;
}

.smart-tag-list-text {
  margin-left: 20px;
  word-break: break-all;
}

.smart-tag-list:hover {
  background-color: #b7f067;
  color:black;
}

.smart-tag-list-wrapper {
  width: 100%;
  padding:6px;
}

@media (max-width: 780px) {
  
  .smart-tag-text {
     overflow: hidden;
     white-space: nowrap;
     text-overflow: ellipsis;
     font-size: 1rem;
     width:180px;
  }

  .smart-tag-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    top:294px;
    left:0;
    height: 100%;
    width:100%;
    background-color: #fff;
  }

  .smart-tag-header-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.6rem;
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
  }

  .smart-tag-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: black;
    font-size: 1.8rem;
    font-weight: bold;
    padding: 10px;
    background-color: #fff;
    text-transform: capitalize;
    background: repeating-linear-gradient(white, black 2px, black 2px, black 3px);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }


}
