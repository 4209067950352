.call-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  top:250px;
  width:59vw;
  height: 100%;
  background-color: #fff;
}

.call-list-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: black;
  align-items: center;
  font-size: 1.8rem;
  padding: 10px;
  background-color: #fff;

  text-transform: capitalize;
  background: repeating-linear-gradient(white, black 2px, black 2px, black 3px);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.call-list-title1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: black;
  align-items: center;
  font-size: 1.8rem;
  padding: 2px;
  background-color: #fff;

  text-transform: capitalize;
  background: repeating-linear-gradient(white, black 2px, black 2px, black 3px);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.call-list-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.call-header {

  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 4px;
}

.call-participants {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
  border-radius: 10px;
}

.call-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  height:380px;
  border-radius: 10px;
  background: transparent url(../../../../assets/dashboard/giphy.gif) 0 0;
  color:greenyellow;
  font-weight: bold;
  font-size: 2rem;
}

.call-dock {
  display: flex;
  justify-content:center;
  align-items: center;
  padding-left:10px;
  padding-right:10px;
  border-radius: 10px;
  background-color: #fff;
  width: 100%;

}





.call-dock-btn-chat {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  border-radius: 10px;
  cursor: pointer;
  color:#fff;
  font-size: 1.4rem;
}

.call-status-online {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  position: absolute;
  top:88px;
  margin-left:40px;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  color:#fff;
  background-color: greenyellow;
  border: 2px solid #1fd655;
}

.call-status-offline {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  position: absolute;
  top:88px;
  margin-left:40px;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  color:#fff;
  background-color: whitesmoke;
  border: 2px solid #a1a3a6;
}

.call-dock-btn-end {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #757575;
  padding: 2px;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  border-radius: 10px;
  cursor: pointer;
  font-size: 2rem;
  color:#00ff76;
}


.call-online-user {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  height: 50px;
}

.call-account-profile {
  height: 3rem;
  width: 3rem;
  background-color: #f2f4f7;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid darkgray;
  box-shadow: 0 0 10px #333;
}

.call-account-outer {
  padding-left:10px;
  padding-right:10px;
}



.call-empty-height {
  height: 10px;
}

.call-btn {
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 20px;
  cursor: pointer;
  background-color:#ff6347;
  color:#fff;
  border: none;
  outline: none;
  font-size: 1.2rem;
  box-shadow: 0 0 16px #999;
}

@media (max-width: 780px) {

  .call-wrapper {
     display: block;
     position: absolute;
     top:294px;
     left:0px;
     width: 100%;
     background-color: #fff;
     z-index: 99;
  }

  .call-list-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .call-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    height:280px;
    border-radius: 10px;
    background: transparent url(../../../../assets/dashboard/giphy.gif) 0 0;
    color:greenyellow;
    font-weight: bold;
    font-size: 2rem;
  }




  .call-dock-btn-chat {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    border-radius: 10px;
    cursor: pointer;
    color:#fff;
    font-size: 1.4rem;
  }

  .call-dock-btn-chat {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    border-radius: 10px;
    cursor: pointer;
    color:#fff;
    opacity:0.8;
    font-size: 1.4rem;
  }



  .call-dock-btn-end {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #757575;
    padding: 4px;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.4rem;
    color:#00ff76;
  }



  .call-account-profile {
    height: 2.5rem;
    width: 2.5rem;
    background-color: #f2f4f7;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid darkgray;
  }

  .call-status-online {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    position: absolute;
    top:84px;
    margin-left:32px;
    height: 12px;
    width: 12px;
    border-radius: 50px;
    color:#fff;
    background-color: greenyellow;
    border: 2px solid #1fd655;
  }

  .call-status-offline {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    position: absolute;
    top:84px;
    margin-left:32px;
    height: 12px;
    width: 12px;
    border-radius: 50px;
    color:#fff;
    background-color: whitesmoke;
    border: 2px solid #a1a3a6;
  }


}
