* {
  box-sizing: border-box;
}

.Resources-wrapper-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top:250px;
  width:59vw;
  background-color: #fff;
  z-index: 99;
}

.Resources-btn-wrapper {
  display: flex;
  padding: 6px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #ff5f5f;
  color:#ffffff;
}

.Resources-btn {
  display: flex;
  align-items: center;
  width: 100%;
  flex-grow: 0.1;
  padding: 8px;
}

.Resources-btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color:#ffffff;
}

.Resources-btn-txt {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.Resources-link-list {
  width: 100%;
  flex-grow: 2;
}

.Resources-list {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}

.Resources-item {
  display: flex;
  width: 94%;
  background-color: #f2f4f7;
  border-radius: 10px;
}

.Resources-item-link {
  display: flex;
  align-items: center;
  width: 84%;
}

.Resources-link {
  display: flex;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  color:#484848;
}

.Resources-item-icon {
  display: flex;
  flex-grow: 2;
  justify-content:center;
  align-items: center;
}

.Resources-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  cursor: pointer;
}

.Resources-icon:hover {
  color:#60acfd;
}

.Resources-loading {
   display: flex;
   justify-content: center;
   align-items: center;
   height:0px;
   width: 100%;
}

.Resources-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  padding: 4px;
}

.Requestcallimg {
  max-width: 100%;
}

@media (max-width: 780px) {

  .Resources-wrapper-container {
     margin-top: 20px;
     height: 100%;
     width: 100%;
     background-color: #fff;
     z-index: 1;
  }

}
