.homepagecontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.modelwrapper {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index:99;
}

.popupmsg {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.25);
}

.spacer {
  height: 70px;
}
