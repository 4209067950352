.reqconfirmwrapper {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 99;
}

.reqconfirmcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width:34vw;
  height:140px;
  padding:30px 20px;
  border-radius: 10px;
}

.reqconfirtitle {
  display: flex;
  border-bottom: 1px solid #f2f4f7;
  padding-bottom: 20px;
  width: 100%;
}

.reqconfirbtn {
  display: flex;
  padding-top: 10px;
  width: 100%;
}

.reqconfircancel {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#404040;
  cursor: pointer;
}

.reqconfirempty {
  display: flex;
  width: 14px;
}

.reqconfirmbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius:15px;
  background-color: #fb5343;
  color: #ffffff;
  cursor: pointer;
}

.reqconfirbtn {
  display: flex;
  justify-content: flex-end;
  padding: 2px;
  width: 100%;
}

 @media (max-width: 820px) {

   .reqconfirmcontainer {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     background-color: #fff;
     width:70vw;
     height:140px;
     padding:30px 20px;
     border-radius: 10px;
   }


}
