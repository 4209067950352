.recovery-confirm-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding:8px;
  padding-left:20px;
  padding-right:20px;
  cursor: pointer;
  width: 100%;
  color:#ffffff;
  border: none;
  border-radius: 6rem;
  background-color: #4582ec;
  font-size:1.2rem;
  cursor: pointer;
}
