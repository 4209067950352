.Searchresult-wrapper-route {
  width: 100%;
}


.Searchresult-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top:100px;
  height: 100vh;
}


.Searchresult-Subprofilecontainer {
  position: relative;
  top:0;
  left: 0;
  margin-top: -10px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  }

.Searchresult-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  margin-top: 74px;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.Searchresult-profiletitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px;
  padding-left: 180px;
}

.Searchresult-profiletitlearrow {
  display: flex;
  align-items: center;
  font-size: 2rem;
  border-radius: 50px;
  cursor: pointer;
  color:#495057;
}

.Searchresult-profiletitlearrow:hover {
  background-color: #f5f1fe;
}

.Searchresult-profiletitletext {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  color:#495057;
}

.Searchresult-profiletitlesubmenu {
  display: flex;
  padding-left: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #f2f4f7;
}

.Searchresult-profilemptyspace {
  width: 80px;
}

.Searchresult-profilenavitem {
  display: flex;
  text-decoration: none;
  color:#495057;
  font-size: 1.2rem;
  border: 1px solid #ffffff;
}

.Searchresult-profilenavitema {
  display: flex;
  text-decoration: none;
  color:#495057;
  font-size: 1.2rem;
  border: 1px solid #ffffff;
  border-bottom: 1px solid #495057;
}


@media (max-width: 780px) {

  .Searchresult-Subprofilecontainer {
    position: relative;
    top:-80px;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #ffff;
  }

  .Searchresult-profiletitle {
    margin-top:50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 10px;
    padding-left:20px;
  }

}
