.settingwrapper {
  position: absolute;
  top:70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  background-color: #fff;
}

.settingcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.settingpanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
}

.settingitem {
  display: flex;
  justify-content:space-around;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color:black;
  width:100%;
  height: 80px;
  border-radius: 10px;
  background-color: #ffff;
  font-size: 1.2rem;
  border-bottom:1px solid lightgray;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%), 0 5px 26px 0 rgb(67 94 131 / 15%);
}

 .settingitem:hover  {
  background-color: #f8f9fa;
  }

.settinglink {
  display: flex;
  justify-content: center;
  align-items: center;
  color:black;
}

.settinglinkspace {
  width: 20px;
}

.settingspace {
  height: 10px;
}

.setting-empspace {
  height: 80px;
}


@media (max-width: 780px) {

  .settingwrapper {
    position: absolute;
    margin-top: 10px;
    top:0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
    background-color: #fff;
  }

  .settingpanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
  }

  .setting-empspace {
    height: 70px;
  }

  .settingspace {
    height: 40px;
  }


}
