.aPopup-modal {
  display: flex;
  position: fixed;
  justify-content:center;
  align-items: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.Popup-modal_content {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 40%;
  height: auto;
  padding: 1.25rem;
  border-radius: 5px;
  margin-bottom: 10%;
  animation: appear 350ms ease-in 1;
}

.Popup-close {
  color: Black;
  float: right;
  font-size: 2rem;
}

.Popup-close:hover {
  color:#3390ec;
  cursor: pointer;
}


.Popup-container-link {
  display: flex;
  flex-direction: column;
}

.Popup-container-name {
  display: flex;
  flex-direction: column;
}


.Popup-btn {
  padding: 10px;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #3390ec;
  color: #ffffff;
  font-size: 1rem;
}

.Popup-btn:hover {
  background-color: #2e82d4;
  border: none;
  outline: none;
  cursor: pointer;
}

.Popup-container-btn {
  display: flex;
  justify-content: flex-end;
}


.Popup-formcontrol {
  display: flex;
  width: 100%;
  padding: 0.7125rem 1rem;
  border: 2px solid #ffffff;
  border-radius: 6px;
  background-color: #f9f9f9;
  font-size: 1rem;

  -webkit-appearance: none;
  -moz-appearance: none;
   appearance: none;
}

.Popup-header {
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.Popup-header-title {
  font-size: 1.2rem;
}



@media (max-width: 850px) {
  .Popup-modal_content {
    width: 80%;
    margin-top: -100px;
  }
}
