* {
  box-sizing: border-box;
}
.Topnavigationpool-header {
  display: flex;
  position: fixed;
  justify-content:space-between;
  align-items: center;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  width: 100vw;

  z-index: 2;
  border-left:90px solid #fff;
  border-right:90px solid #fff;

}

.Homepage-topnavbar-navbar-logo {
  letter-spacing: 2px;
}

.Topnavigation-navbar {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content:space-between;
  color: #4d4d4d;
  width: 100%;
}


.Topnavigation-navbar a,
a:hover,
a:focus,
a:active {
  text-decoration: none;

}

.Topnavigation-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  text-decoration: none;
  color:#000000;
}

.Topnavigation-logoline  {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Topnavigation-cmenu {
  flex-grow: 2;
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.Topnavigation-gateway {
  display: flex;
  flex-grow: 0.1;
  justify-content:space-between;;
}

.Topnavigation-btn {
  padding-left: calc(100vw / 54.6);
  padding-right: calc(100vw / 54.6);
  padding-top: calc(100vw / 136.5);
  padding-bottom: calc(100vw / 136.5);
  border-radius:4px;
  color:black;
}



.Topnavigation-joinnow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Topnavigation-signin {
  display: flex;
}

.Topnavigation-joinnow:hover {
  background-color: #FAFAFA;
}

.Topnavigation-signin :hover {
  border: 1px solid #e0e0e0;
  background-color: #FCFCFC;
}



@media (max-width: 896px) {

  .Topnavigationpool-header {
    display: flex;
    position: fixed;
    justify-content:space-between;
    align-items: center;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #ffffff;
    width: 100vw;

    z-index: 1;
    border-left:20px solid #fff;
    border-right:20px solid #fff;

  }



  .Topnavigation-navbar {
    font-size: 16px;
  }

  .Topnavigation-btn {
    padding-left: calc(100vw / 54.6);
    padding-right: calc(100vw / 54.6);
    padding-top: calc(100vw / 120);
    padding-bottom: calc(100vw / 120);
    border-radius:4px;
    color:black;
  }



  .Topnavigation-logo {
    font-size: 1.2rem;
  }
}


@media (max-width: 650px) {


}
