.card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 200px;
  border-radius: 10px;
  border: 1px solid black;
  border-style: dashed;
  padding: 10px;
}

.confirmbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  background-color: cornflowerblue;
}

.cardimg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.imglogo {
  max-width:200px;
  max-height:40px;
}

.cardcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.code {
  font-size: 14px;
}

@media (max-width: 520px) {

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  border: 1px solid black;
  border-style: dashed;
  padding: 10px;
}

}

@media print {
    @page {
        size: 200mm 130mm;
        margin: 0;
    }
}
