.Profilepicturecontainer {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Profilepicture-imgpreview {
  display: flex;
  font-size: 1.2rem;
}

.Profilepicture-userprofileicon {
  display:flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border-radius: 110px;
}

.Profilepicture-btn {
  display: flex;
  justify-content: center;
  width: 100%;
}
.Profilepicture-uploadbtn {
  color:#ff0000;
  border-bottom: 1px solid #ff0000;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
}

.Profilepicture-savebtn {
  background-color: tomato;
  color:#ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 50px;
}

.Profilepicture-savebtn-dis {
  background-color:lightgray;
  color:dimgray;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 50px;
}

.Profilepicture-icobtn {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Profilepicture-err {
  color:#e60023;
}

.Profilepicture-inptuploadButton {
  display:none;
}

.Profilepicture-img {
    max-width: 100%;
    max-height: 100%;
    background-position: -25px -20px;
    border-radius: 100%;
    background-size: cover;
}

.Profilepicture-emptyspace {
  height: 10px;
}
