* {
  box-sizing: border-box;
}

.videowrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;

}

.videotext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} 

.videocontainer {
  font-family: 'Archivo Black', sans-serif;
  background-color: #2c2c2c;
  background: radial-gradient(ellipse at center, #29382c 0%, #0c100d 100%);
  color: #fff;
  width: 600px;
  height: 340px;
  border-radius: 10px;
  box-shadow: 0 0 16px #333;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color:greenyellow;
}

.main-wrapper {
  position: relative;
  padding: 0;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.noise-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  z-index: 10;
  opacity:1;
  border-radius: 10px;
}
.noise-wrapper:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%);
  }

.noise {
  position: absolute;
  top: -500px;
  right: -500px;
  bottom: -500px;
  left: -500px;
  background: transparent url(../../../../assets/dashboard/noise.jpg) 0 0;
  background-size: 320px 320px;
	opacity: .35;
	animation: noise 0.99s steps(8,end) infinite both;
}

@keyframes noise {
  0% {
    transform: translateX(0px,0px); }
  10% {
    transform: translate(-100px, 100px);
  }
  20% {
    transform: translate(150px, -100px);
  }
  30% {
    transform: translate(-100px,100px);
  }
  40% {
    transform: translate(100px, -150px);
  }
  50% {
    transform: translate(-100px, 200px);
  }
  60% {
    transform: translate(-200px, -100px);
  }
  70% {
    transform: translateY(50px, 100px);
  }
  80% {
    transform: translate(100px, -150px);
  }
  90% {
    transform: translate(0px, 200px);
  }
  100% {
    transform: translate(-100px, 100px);
  }
}

@media (max-width: 780px) {

}
