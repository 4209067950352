.Download-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  font-family: Vanilla Extract;
  color:#495057;
  letter-spacing: 2px;
  height:100vh;
  width: 100%;
}


.Download-title {
  font-size: 22px;
  color:#404040;
}
