* {
  box-sizing: border-box;
}

.Mainbody-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-top: 50px;
  color: #4d4d4d;
}

.Mainbody-left-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 50px;
  width: 100%;
  height: 450px;
  background-color: #fbfbfd;
  border-radius: 14px;
}

.Mainbody-title {
  display: flex;
  justify-content:flex-start;
  align-items:center;
  flex-wrap: wrap;
  font-size: 1.8rem;
  padding: 18px;
}

.Mainbody-title-sub {
  display: flex;
  justify-content:center;
  align-items:center;
  flex-wrap: wrap;
  font-size: 1.4rem;
  padding: 20px;
}

.Mainbody-titlesub {
  display: flex;
  justify-content:center;
  align-items:center;
  font-size: 1.2rem;
}

.Mainbody-learnmore {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  font-size:1.8rem;
}


.Mainbody-left-block-desktop {
  background-color: #fbfbfd;
}



.Mainbody-right-block {
  display: flex;
  justify-content:center;
  align-items: center;
  border-radius: 14px;
  height: 450px;
  background-color: #fbfbfd;
  width:90%;
}

.Mainbody-img {
  max-width: 100%;
  height: auto;
}

.Mainbody-learnmore-wrapper {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
}

.Mainbody-learnmore-container {
  display:flex;
  width: 170px;
  flex-direction: column;
  cursor: pointer;
  text-decoration:none;
        color:black;
}

.Mainbody-learnmore-line {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Mainbody-left-block-mobile {
  display: none;
}

.imgloop  {
  max-width: 70%;
}

.Mainbody-loop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Imgdoom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Mainbody-pool-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  padding: 4px;
}

.Mainbody-pool-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left:30px;
  padding-right:30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid black;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  color:#404040;
  background-color: lightgreen;
}

.Mainbody-pool-learnmore {
  display: none;
}

.Mainbody-pool-popupmenu {
  display: none;
}

.Mainbody-about {
   display: flex;
   justify-content:center;
   align-items: center;
   width:100%;
   border-radius: 10px;
   padding:4px;
   padding-right: 10px;
}

.Mainbody-text {
  border-bottom: 1px solid black;
  text-decoration: none;
  border-bottom-style: dashed;
  padding: 4px;
  cursor: pointer;
  letter-spacing: 2px;
  color: black;
}

.Mainbody-about:hover {
  color:cornflowerblue;
}

.Mainbody-android {
   display: flex;
   flex-direction:column;
   justify-content: center;
   align-items: center;
   border: 1px solid #ffff;
   border-radius: 10px;
   padding: 10px;
}

.Mainbody-android-link {
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  text-decoration:none;
  color: black;
}

.Mainbody-android:hover {
 background-color: whitesmoke;
 border: 1px solid black;
 border-style: dashed;
}



@media (max-width: 810px) {

 .Mainbody-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }

 .Mainbody-img {
   max-width: 80vw;
   height: auto;
 }

 .Mainbody-title-sub {
   display: flex;
   justify-content:center;
   align-items:center;
   font-weight: bold;
   flex-wrap: wrap;
   font-size: 1.2rem;
   padding: 20px;
 }

 .Mainbody-title {
   display: flex;
   justify-content:center;
   align-items:flex-start;
   flex-wrap:wrap;
   font-size: 1.2rem;
   font-weight: bold;
   padding:18px;
 }


 .Mainbody-left-block {
   display: none;
 }

 .Mainbody-right-block {
   display: flex;
   justify-content:center;
   align-items: center;
   flex-direction: column;
   border-radius: 14px;
   background-color: #fbfbfd;
   width:100%;
   height: 100%;
 }

 .Mainbody-learnmore-wrapper {
   display: flex;
   flex-direction: column;
   cursor: pointer;
   padding:0px;
 }


 .Mainbody-left-block-mobile {
   display: block;
 }


 .Mainbody-learnmore {
   cursor: pointer;
   font-size: 1.4rem;
 }

 .Mainbody-loop {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   width: 100%;
 }

 .Mainbody-titlesub {
   display: flex;
   justify-content:center;
   align-items:center;
   flex-direction: column;
   font-size: 1.2rem;
   padding: 2px;
 }

 .imgloop  {
   max-width: 100%;
 }

 .Mainbody-pool-learnmore {
   display: none;
   justify-content: center;
   align-items: center;
   font-size: 1rem;
   padding: 4px;
 }

 .Mainbody-pool-popupmenu {
   display: block;
 }

}
