.Alldone-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 6px;
  background-color: rgba(0, 0, 0, 0.4);
}

.Alldone-container {
  display: flex;
  justify-content:space-between;
  align-items: center;
  flex-direction: column;
  width: 40%;
  min-height: 200px;
  height: auto;
  border-radius: 20px;
  background-color: #ffff;
  border: 1px solid lightgray;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}


.Alldone-container-getstarted {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  cursor: pointer;
  padding: 14px;
  border-radius: 10px;
  color:#ffff;
  background-color: #ff4645;
}

.Alldone-container-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Alldone-container-imgcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Alldone-container-img {
  max-width: 50%;
}


@media (max-width: 780px) {


  .Alldone-container {
    display: flex;
    justify-content:space-between;
    align-items: center;
    flex-direction: column;
    width: 80%;
    height: auto;
    border-radius: 20px;
    border: 1px solid lightgray;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  }

  .Alldone-container-img {
    max-width: 80%;
  }


}
