.PoolEditDelete-wrapper {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 15%;
  padding: 10px;
  border-radius: 10px;
  background-color:#ffffff;

 -webkit-box-shadow: 0px 0px 2px 2px #E1E1E1;
 -moz-box-shadow: 0px 0px 2px 2px #E1E1E1;
  box-shadow: 0px 0px 2px 2px #E1E1E1;

}

.PoolEditDelete-empty {
  height: 10px;
}

.PoolEditDelete-btn {
  cursor: pointer;
  padding: 6px;
}

.PoolEditDelete-btn:hover {
   background-color: #f2f4f7;
   border-radius: 4px;
}

@media (max-width: 870px) {

  .PoolEditDelete-wrapper {
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 30%;
    padding: 10px;
    border-radius: 10px;
    background-color:#ffffff;

   -webkit-box-shadow: 0px 0px 2px 2px #E1E1E1;
   -moz-box-shadow: 0px 0px 2px 2px #E1E1E1;
    box-shadow: 0px 0px 2px 2px #E1E1E1;

  }

}
