.pooldelete-delete {
  display: flex;
  position:fixed;
  top:0;
  left:0;
  justify-content:center;
  align-items: center;
  z-index:14;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.pooldelete_content-delete {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 30%;
  height: auto;
  padding: 1.25rem;
  border-radius: 5px;
}

.pooldelete-delete-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
}

.pooldelete-delete-cancel {
  position: relative;
  top: -22px;
  left: 14px;
  font-size: 2.4rem;
  cursor: pointer;
}

.pooldelete-delete-body {
  display: flex;
  flex-direction: column;
}

.pooldelete-delete-footer {
  display: flex;
  justify-content:flex-end;
}

.pooldelete-btn-delete {
  padding: 10px;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #f4212e;
  color: #ffffff;
  font-size: 1rem;
}

.pooldelete-btn-delete:hover {
  background-color: #f4212e;
  border: none;
  outline: none;
  cursor: pointer;
}

.pooldelete-desc {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
  font-size: 0.9rem;

  border-radius: 10px;
  background-color: #f3f3f3;
  background-color: #fff;
  word-break: break-all;
}

@media (max-width: 850px) {
  .pooldelete_content-delete {
    width: 60%;
    height: auto;
  }
}

@media (max-width: 600px) {
  .pooldelete_content-delete {
    width: 60%;
  }
}

@media all and (device-width: 810px) and (device-height: 1080px) and (orientation:portrait) {
  .pooldelete_content-delete {
    width: 60%;
    margin-bottom:490px;
  }
}

@media all and (device-width: 320px) and (device-height: 568px) and (orientation:portrait) {
  .pooldelete_content-delete {
    width: 60%;
    margin-bottom:90px;
  }
}



@keyframes appear {
  0%{
    opacity: 0;
  }
}
