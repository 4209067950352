* {
  box-sizing: border-box;
}

.Timezone-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.Timezone-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 10px;
  background-color: #ffff;
  width: 34%;
}

.Timezone-container {
  display: flex;
  flex-direction: column;
  height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.Timezone-item {
  padding: 14px;
  cursor: pointer;
  border-radius: 10px;
}

.Timezone-item:hover {
  background-color: #f9f9f9;
  border-radius: 10px;
}

.Timezone-input-wrapper {
  position: relative;
  display: flex;
  justify-content:flex-start;
  align-items:center;
  border-radius: 10px;
  width: 100%;
  border: 2px solid darkgrey;
}

.Timezone-input-wrapper:focus-within {
   border: 2px solid cornflowerblue;
}

.Timezone-input {
  display: flex;
  border-radius: 10px;
  padding: 14px;
  font-size: 1rem;
  width: 100%;
  border: none;
  outline: none;
}

.Timezone-input:focus {
  border: none;
  outline: none;
}

.Timezone-header {
  display: flex;
  justify-content:space-between;
  align-items: center;
  color:#484848;
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
}

.Timezone-cancel {
  font-size: 1.2rem;
  cursor: pointer;
}

.Timezone-clear {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 4px;
  cursor: pointer;
}

.Timezone-clear:hover {
  color:cornflowerblue;
}

.Timezone-cancel:hover {
  color:red;
}

@media (max-width: 780px) {

 .Timezone-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    padding: 10px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
  }

  .Timezone-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 10px;
    width:90%;
    background-color: #ffff;
  }

}
