.timepickerwrapper {
  display: flex;
}

.timeselect {
  padding: 4px;
  border: none;
  background-color: #fff;
  outline: none;
  color: #555555;
  vertical-align: middle;
  cursor: pointer;
}

.temptspace {
  width: 4px;
}

.timepickercontainer {
  display: flex;
  padding: 4px;
  flex-direction: column;
  border:1px solid lightgray;
  outline: none;
  border-radius: 10px;
}

.timepickercontainer-dis {
  display: none;
}

.timepickerhour {
  display: flex;
  padding: 4px;
  font-size: 0.8rem;
  align-items: center;
}

.timepickerhour-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.timepickercontainer:active {
    border:1px solid #bfdeff;
}
