.Pooleditcomment-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Pooleditcomment-container {
  display: block;
  word-wrap: break-word;
  cursor: pointer;
  border-bottom:1px solid #b8b8b9;
  background-color: #ffffff;
  font-size: 1rem;
  outline: none;

  padding: .80rem;
}

.Pooleditcomment-btn {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
}


.edit-empty-btn {
  display: flex;
  width: 4px;
}

.edit-btn-cancel {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 8px;
  border-radius: 6px;
  color:#3390ec;
}

.edit-btn-save-dis {
  border: none;
  outline: none;
  padding: 8px;
  border-radius: 6px;
  color: #b8b8b9;
}


.edit-btn-save-enb {
  border: none;
  cursor: pointer;
  outline: none;
  padding: 8px;
  border-radius: 6px;
  color: #b8b8b9;
  color:#ffffff;
  background-color:#3390ec;
}
