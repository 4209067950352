.poolrequest-modal {
  display: flex;
  justify-content:center;
  align-items: center;
  position: fixed;
  top:0;
  left:0;
  z-index:99;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.poolrequest-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  color:#fff;
  width: 35%;
  height: auto;
  border-radius: 14px;
}

.poolrequest-upperpanel {
  width: 100%;
  color:#404040;
}

.poolrequest-imgwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.poolrequest-img {
  max-width: 8rem;
}

.poolrequest-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-left: 30vw;
  margin-bottom:30px;
  font-size: 1.4rem;
  cursor: pointer;
  color:#404040;
  height: 30px;
  width: 30px;
  border-radius: 50px;
}

.poolrequest-icon:hover {
  background-color: #f2f2f2;
}

.poolrequest-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color:#404040;
  padding: 6px;
}

.poolrequest-topic {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color:#7280f1;
  padding: 2px;
  word-break: break-all;
  border-bottom: 1px solid #e2e7e1;
}

.poolrequest-lowerpanel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 14px;
}

.poolrequest-btnconfirm-wrapper {
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  cursor: pointer;
}

.poolrequest-btnconfirm {
  font-size: 1rem;
  border-radius: 14px;
  color:#fff;
  color:#404040;
  background-color: #f2f2f2;
  border: 2px solid #fff;
  padding-top:8px;
  padding-bottom:8px;
  padding-left:22px;
  padding-right:22px;
  font-weight: bold;
}

.poolrequest-btnconfirm:hover {
  border: 2px solid #bababa;
}


@media (max-width: 780px) {

  .poolrequest-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    color:#fff;
    width: 80%;
    height: auto;
    border-radius: 14px;
  }

  .poolrequest-icon {
     display: flex;
     justify-content: center;
     align-items: center;
     position: absolute;
     margin-left: 70vw;
     margin-bottom:30px;
     font-size: 1.4rem;
     cursor: pointer;
     color:#404040;
     height: 30px;
     width: 30px;
     border-radius: 50px;
 }

}
