.requestdisplaywrapper {
  position: absolute;
  height: 100vh;
  width: 100%;
  top:0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1a1a1a;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 15px;
  line-height: 1.6em;
  padding: 4px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.request-container {
  display: flex;
  justify-content:space-between;
  align-items: center;
  flex-direction: column;
  height: auto;
  padding-bottom:20px;
  padding-top:0px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.request-container-header {
  display: flex;
  justify-content: space-between;
  align-items:center;
  width:100%;
  padding:10;
  border-width:2;
}


.request-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width:auto;
  padding: 10px;
  background-color: #fff;
}

.request-container-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 24px;
  padding: 10px;
  width:400px;
  background-color: #ffff;
}

.request-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 24px;
  width: 100%;
  padding: 10px;
  border: 1px solid lightgray;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 2px 0px;
}

.request-closebtn {
  color:'black';
  padding:4;
  cursor:pointer;
}

.request-closebtn:hover {
  color:cornflowerblue;
  cursor:pointer;
}

.request-persondetails {
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  padding: 4px;
  border: 1px;
  cursor:pointer;
}

.requestbox-name:hover  {
  border-bottom: 1px solid dashed;
}
