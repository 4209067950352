.Opscreen-title {
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  border-width: 1px;
  padding: 4px;
  cursor: pointer;
}

.Opscreen-header {
  display: flex;
  justify-content: space-between;
  align-items:center;
  width: 100%;
  padding: 2px;
  border-bottom:1px solid lightgrey;
}

.Ops-header {
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  width:100%;
  padding:8px;
}

.Opsc {
 border-bottom:1px solid darkgrey;
 padding: 4px;
 border-bottom-style: dashed;
}

.Opsc:hover  {
  border-bottom:1px solid black;
  padding: 4px;
  border-bottom-style: dashed;
}

.sharewrapper-title {
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  width:100%;
  border-width: 1px;
  padding: 4px;
}
