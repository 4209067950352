* {
 box-sizing: border-box;
}

.yourpool-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-left:100px solid #fff;
  border-right: 100px solid #fff;
  background-color: #fff;
  width:100%;
}

.yourpool-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #fff;
}

.yourpool-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 340px;
  background-color: #fff;
  flex-wrap: wrap;
}

.yourpool-delete {
  display:flex;
  justify-content: center;
  align-items: center;
  color:#404040;
  outline: none;
  font-size: 1rem;
  padding: 10px;
  width: 80%;
}

.yourpool-delline {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  cursor: pointer;
}

.yourpool-delline:hover {
  color:#ff0000;
}

.yourpool-topic {
  display: flex;
  justify-content:flex-start;
  align-items:center;
  border-radius: 10px;
  width: 60px;
  padding: 6px;
  font-size: 1rem;
}

.yourpool-desc {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
  font-size: 0.9rem;
  width:200px;
  height:50px;
  border-radius: 10px;
  background-color: #f3f3f3;
  background-color: #fff;
  word-break: break-all;
}



.yourpool-link {
  display: flex;
  padding: 16px;
  flex-direction: column;
  width: 80%;
  cursor: pointer;
  border: 1px solid #ffffff;
  border-radius: 10px;
  text-decoration: none;
  color:#484848;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.yourpool-link:hover {
  border: 1px solid #b8b8b9;
  border-radius: 10px;
}

.yourpool-link:hover #yoid  {
  color:#4582ec;
}

.yourpool-wrapperimg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
}

.yourpool-poolscrollimg {
  max-width: 100%;
  border-radius: 10px;
  width:240px;
  height:180px;
  object-fit: contain;
}

.yourpool-desctable {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.yourpool-topicdetails {
  display: flex;
  padding: 4px;
}


@media (max-width: 870px) {

  .yourpool-wrapper {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding: 10px;
  }

  .yourpool-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width:100%;
    border: 1px solid #fff;
  }

  .yourpool-link {
     display: flex;
     padding: 16px;
     flex-direction: column;
     width: 100%;
     cursor: pointer;
     border: 1px solid #ffffff;
     border-radius: 10px;
     text-decoration: none;
     color:#484848;
   }

}
